import { countriesTranslation } from 'assets/locale/en/core/countriesTranslation';

const englishTranslation = {
  ...countriesTranslation,
  // HEADER //////////////////
  // Nav
  header_nav_dashboard: 'Dashboard',
  header_nav_dashboard_sales: 'Sales',
  header_nav_dashboard_stock: 'Stock',
  header_nav_dashboard_prediction: 'Prediction',
  header_nav_catalog: 'Catalog',
  'header_nav_catalog-download': 'Download catalog',
  header_nav_multimedia: 'Multimedia',
  header_nav_clients: 'Clients',
  'header_sub-nav_view-catalog': 'View Catalog',
  'header_sub-nav_add-product': 'Add Product',
  'header_sub-nav_all-catalogs': 'Catalog Management',
  'header_sub-nav_catalog-correlation': 'Reference correlation',
  'header_sub-nav_catalog-generic': 'Generic H4R',
  'header_sub-nav_multimedia-catalog': 'Multimedia Catalog',
  'header_sub-nav_multimedia-catalog-videos': 'Videos',
  'header_sub-nav_multimedia-catalog-images': 'Images',
  'header_sub-nav_multimedia-catalog-banners': 'Banners',
  'header_sub-nav_clients-list': 'Client List',
  'header_sub-nav_add-client': 'Add Client',
  // Notifications
  'header_notifications_empty-notifications': 'There are no notifications',
  ////////////////////////////

  // SIGNUP PAGE /////////////
  'signup-page_title': 'Sign up in:',
  'signup-page_sub-title': 'Sign up to expand your business',
  'signup-page_button-brand': 'Sign up as a Brand',
  'signup-page_button-multibrand': 'Sign up as a Wholesale',
  'signup-page_button-brand-wh': 'Sign up as a brand or multibrand',
  'signup-page_return-to-login': 'Return to login',
  ////////////////////////////

  // ACCOUNT PAGE /////////////
  'profile-page_nav_profile': 'Profile',
  'profile-page_nav_company': 'Company Information',
  'profile-page_nav_main-contacts': 'Main Contacts',
  'profile-page_nav_commercial-info': 'Commercial Information',
  /////////////////////////////

  // DASHBOARDS //////////////
  'dashboard_empty-dashboard': 'Contact Hub4Retail to enable this service.',
  dashboard_dashboard_error:
    'Error loading the dashboard. If the problem persists, contact:',
  /////////////////////////////

  // HELMET ///////////////////
  signup: 'Sign up',
  home: 'Home',
  dashboard: 'Dashboard',
  multimedia: 'Multimedia',
  'multimedia-videos': 'Multimedia videos',
  'multimedia-images': 'Multimedia images',
  'multimedia-banners': 'Multimedia banners',
  clients: 'Clients',
  profile: 'Profile',
  company: 'Company Information',
  'main-contacts': 'Main Contacts',
  'commercial-info': 'Commercial Information',
  catalog: 'Catalog',
  notifications: 'Notifications',
  'commercial-profile': 'Commercial Profile',
  'all-catalogs': 'All Catalogs',
  'catalog-correlation': 'Reference Correlation',
  'catalog-generic': 'Generic H4R',
  catalog_download: 'Download catalog',
  // Client
  client_resume: 'Summary',
  'client_business-info': 'Business Information',
  client_contacts: 'Contacts',
  'client_sale-points': 'Sale Points',
  'client_comercial-info': 'Commercial Information',
  /////////////////////////////

  // USERS ///////////////////////
  // Departments
  users_department_commercial: 'Commercial',
  users_department_administration: 'Administration',
  users_department_agent: 'Agent',
  users_department_owner: 'Owner',
  'users_department_store-manager': 'Store Manager',
  users_department_logistics: 'Logistics',
  users_department_marketing: 'Marketing',
  users_department_others: 'Others',
  ////////////////////////////////

  // ACCOUNT //////////////////
  account_profile_title: 'My Profile',
  'account-info-panel_title': 'General Information',
  'account-company-info-panel_title': 'Company Data',
  // Profile
  'account-info-panel_profile_name': 'Name',
  'account-info-panel_profile_lastname': 'Last Name',
  'account-info-panel_profile_email': 'Email',
  'account-info-panel_profile_phone-number': 'Phone Number',
  'account-info-panel_profile_department': 'Departament',
  'account-info-panel_profile_security-data': 'Security Data',
  'account-info-panel_profile_change-password': 'Change Password',
  'account-info-panel_profile_pwd_password-label': 'New Password',
  'account-info-panel_profile_pwd_repeat-password-label': 'Repeat the New Password',
  'account-info-panel_profile_pwd_change-button': 'Change Password',
  'account-info-panel_profile_pwd_validation':
    'The password must be at least 8 characters and a maximum of 16, 1 number, 1 uppercase letter, and one lowercase letter.',
  'account-info-panel_profile_pwd_validation-match': 'The passwords do not match',
  'account-info-panel_profile_pwd_change-success': 'Password changed successfully.',
  'account-info-panel_profile_pwd_empty-inputs': 'Fill in the empty fields.',
  // Profile form
  'account-profile_form-input_name': 'Enter your name',
  'account-profile_form-input_name-validation': 'Please enter your name',
  'account-profile_form-input_lastname': 'Enter your last name',
  'account-profile_form-input_lastname-validation': 'Please enter your last name',
  'account-profile_form-input_email': 'Enter your email',
  'account-profile_form-input_email-validation-required': 'Please enter your email',
  'account-profile_form-input_email-validation': 'The email is not valid',
  'account-profile_form-input_department': 'Enter the departament',
  'account-profile_form-input_department-validation': 'Please enter the departament',
  'account-profile_form-input_phone-number': '',
  'account-profile_form-input_phone-number-validation': 'Please enter the phone number',
  // Company
  'account-info-panel_company_title': 'Company Data',
  'account-info-panel_company_cif': 'VAT (Tax Identification)',
  'account-info-panel_company_trade-name': 'Brand Name',
  'account-info-panel_company_business-name': 'Company Name',
  'account-info-panel_company_address': 'Address',
  'account-info-panel_company_postal-code': 'Postal Code',
  'account-info-panel_company_population': 'City',
  'account-info-panel_company_country': 'Country',
  'account-info-panel_company_email': 'Email',
  'account-info-panel_company_phone-number': 'Phone Number',
  'account-info-panel_company_contact_name': 'Main Contact',
  'account-info-panel_company_metas-connected_title': 'Connected Goals',
  'account-info-panel_company_active-seasons_title': 'Active Seasons',
  // Company form
  'account-company_form-input_trade-name': 'Enter the Brand Name',
  'account-company_form-input_trade-name-validation': 'Please enter the brand name',
  'account-company_form-input_business-name': 'Enter the Company Name',
  'account-company_form-input_business-name-validation': 'Please enter the Company Name',
  'account-company_form-input_country': 'Enter the country',
  'account-company_form-input_country-validation': 'Please enter the country',
  'account-company_form-input_population': 'Enter the city',
  'account-company_form-input_population-validation': 'Please enter the city',
  'account-company_form-input_address': 'Enter the address',
  'account-company_form-input_address-validation': 'Please enter your address',
  'account-company_form-input_postal-code': 'Enter the postal code',
  'account-company_form-input_postal-code-validation': 'Please enter the postal code',
  'account-company_form-input_cif': 'Enter the VAT (Tax Identification)',
  'account-company_form-input_cif-validation':
    'Please enter the VAT (Tax Identification)',
  'account-company_form-input_phone-number': 'Phone Number',
  'account-company_form-input_phone-number-validation': 'Please enter the phone number',
  'account-company_form-input_email': 'Enter the company email',
  'account-company_form-input_contact_name_en': 'Enter the main contact of the company',
  'account-company_form-input_contact_name-validation-required_en':
    'Please enter the main contact of the company',
  'account-company_form-input_email-validation-required': 'Please enter your email',
  'account-company_form-input_email-validation': 'The email is not valid',
  'account-company_form-input_instagram': 'Enter the Instagram URL',
  'account-company_form-input_youtube': 'Enter the Youtube URL',
  'account-company_form-input_linkedin': 'Enter the Linkedin URL',
  'account-company_form-input_facebook': 'Enter the Facebook URL',
  'account-company_social-media-title': 'Social Media',
  'account-company_form-input_social-media-label': 'Social Network Name',
  'account-company_form-input_social-media-label-placeholder':
    'Name of the Social Network',
  'account-company_form-input_social-media-label_validation':
    'Please, enter the name of the social network',
  'account-company_form-input_social-media-value': 'Social Network URL',
  'account-company_form-input_social-media-value-placeholder':
    'URL of the Social Network',
  'account-company_form-input_social-media-value_validation':
    'Please, enter the URL of the social network',
  'account-company_form-input_social-media-add-button': 'Add Social Network',
  'account-company_form-input_social-media-accept-button_title': 'Update Social Network',
  'account-company_form-input_social-media-reject-button_title': 'Discard Changes',
  'account-company_form-input_social-media-delete-button_title': 'Delete Social Network',
  'account-company_form-input_social-media-delete-button_popconfirm_title':
    'Delete Social Network',
  'account-company_form-input_social-media-delete-button_popconfirm_description':
    'Are you sure you want to delete this Social Network?',
  'account-company_form-input_social-media-delete-button_popconfirm_ok': 'Delete',
  'account-company_form-input_social-media-delete-button_popconfirm_cancel': 'Cancel',
  // Main Contacts
  'main-contacts_title': 'Main Contacts',
  'main-contacts_add-contact': '+ Add',
  'main-contacts_table_name': 'Name',
  'main-contacts_table_lastname': 'Last Name',
  'main-contacts_table_email': 'Email',
  'main-contacts_table_phone-number': 'Phone Number',
  'main-contacts_table_department': 'Department',
  'main-contacts_table_pop-confirm_title': 'Delete contact',
  'main-contacts_table_pop-confirm_description':
    'Are you sure you want to delete this contact?',
  'main-contacts_table_pop-confirm_btn-accept': 'Delete',
  'main-contacts_table_pop-confirm_cancel-btn': 'Cancel',
  // Main Contacts Form
  'main-contacts_form-label_name': 'Name',
  'main-contacts_form-label_lastname': 'Last Name',
  'main-contacts_form-label_email': 'Email',
  'main-contacts_form-label_phone-number': 'Phone Number',
  'main-contacts_form-label_department': 'Department',
  'main-contacts_form-input_name': 'Enter the name',
  'main-contacts_form-input_lastname': 'Enter the last name',
  'main-contacts_form-input_email': 'Enter the email',
  'main-contacts_form-input_department': 'Enter the department',
  'main-contacts_form_sector_label': 'Sector',
  'main-contacts_form-input_sector': 'Enter the sector',
  'main-contacts_form-input_sector_validation': 'Enter the sector',
  'main-contacts_form-input_phone-number': 'Enter the phone number',
  'main-contacts_form-input-validation_name': 'Please enter the name',
  'main-contacts_form-input-validation_lastname': 'Please enter the last name',
  'main-contacts_form-input-validation_email': 'The email is not valid',
  'main-contacts_form-input-validation-required_email': 'Please enter the email',
  'main-contacts_form-input-validation_department': 'Please enter the department',
  'main-contacts_form-input-validation_phone-number': 'Please enter the phone number',
  // Comercial information
  'comercial-info_title': 'Commercial Information',
  'comercial-info_add-contact': '+ Add Agent',
  'comercial-info_table_agent-name': 'Sales Agent / Distributor',
  'comercial-info_table_division': 'Division',
  'comercial-info_table_buy-group': 'Buying Group',
  'comercial-info_table_genre': 'Genre',
  'comercial-info_table_pop-confirm_title': 'Delete commercial agent',
  'comercial-info_table_pop-confirm_description':
    'Are you sure you want to delete this sales agent?',
  'comercial-info_table_pop-confirm_btn-accept': 'Delete',
  'comercial-info_table_pop-confirm_cancel-btn': 'Cancel',
  // Comercial information Form
  'comercial-info_form-label_name': 'Name',
  'comercial-info_form-label_lastname': 'Last Name',
  'comercial-info_form-label_trade-name': 'Brand Name',
  'comercial-info_form-label_business-name': 'Company Name',
  'comercial-info_form-label_cif': 'VAT (Tax Identification)',
  'comercial-info_form-label_phone-number': 'Phone Number',
  'comercial-info_form-label_main-contact': 'Main Contact',
  'comercial-info_form-label_working-brands': 'Working Brands:',
  'comercial-info_form-input_name': 'Enter the name',
  'comercial-info_form-label_email': 'Email',
  'comercial-info_form-label_sector': 'Sector',
  'comercial-info_form-input_email': 'Enter the email',
  'comercial-info_form-input_sector': 'Enter the sector',
  'comercial-info_form-input_division': 'Enter the division',
  'comercial-info_form-input_yes': 'Yes',
  'comercial-info_form-input_no': 'No',
  'comercial-info_form-input_lastname': 'Enter the last name',
  'comercial-info_form-input_trade-name': 'Enter the company name',
  'comercial-info_form-input_business-name': 'Enter the company name',
  'comercial-info_form-input_cif': 'Enter the VAT (Tax Identification)',
  'comercial-info_form-input_phone-number': 'Enter the phone number',
  'comercial-info_form-input_main-contact': 'Enter the main contact',
  'comercial-info_form-input-validation_name': 'Please enter the name',
  'comercial-info_form-input-validation_email': 'Please, enter the email',
  'comercial-info_form-input-validation_sector': 'Please, enter the sector',
  'comercial-info_form-input-validation_division': 'Please, enter the division',
  'comercial-info_form-input-validation_buy-group': 'Please, enter the buying group',
  'comercial-info_form-input-validation_lastname': 'Please enter the last name',
  'comercial-info_form-input-validation_trade-name': 'Please enter the brand name',
  'comercial-info_form-input-validation_business-name': 'Please enter the company name',
  'comercial-info_form-input-validation_cif': 'Please enter the VAT (Tax Identification)',
  'comercial-info_form-input-validation_phone-number': 'Please enter the phone number',
  'comercial-info_form-input-validation_main-contact': 'Please enter the main contact',
  // Drawers
  'account-profile_drawer_title': 'General Information',
  'account-company_drawer_title': 'Company Data',
  'comercial-info_drawer_title': 'Commercial Information',
  'main-contacts_drawer_title': 'Main Contacts',
  'main-contacts_drawer_create-title': 'Create contact',
  'main-contacts_drawer_update-title': 'Update contact',
  /////////////////////////////

  // MULTIMEDIA ///////////////
  'multimedia_nav-videos': 'Videos',
  'multimedia_nav-images': 'Images',
  'multimedia_sort-btn': 'Sort by',
  'multimedia_filter-btn': 'Filters',
  'multimedia_apply-filters-btn': 'Apply filters',
  'multimedia_search-input-placeholder': 'Search by brand, season, country, date',
  'multimedia_search-results': 'Results',
  'multimedia_sort-last-favorites': 'Favorites',
  multimedia_download: 'Download',
  multimedia_download_started: 'The download has started',
  multimedia_open: 'Open',
  'multimedia_link-copied': 'Link copied to clipboard',
  'multimedia_link-copy-button': 'Copy link',
  'multimedia_delete-popconfirm-title': 'Delete multimedia',
  'multimedia_delete-popconfirm-description':
    'Are you sure you want to delete this file?',
  // Sort by
  'multimedia_sort-last-aggregates': 'Last added',
  'multimedia_sort-best-seller': 'Best seller',
  'multimedia_sort-less-sold': 'Least sold',
  'multimedia_sort-best-pvp_great-minor': 'Price: high to low',
  'multimedia_sort-best-pvp_minor-great': 'Price: low to high',
  'multimedia_sort-best-pvi_great-minor': 'PVI: high to low',
  'multimedia_sort-best-pvi_minor-great': 'PVI: low to high',
  'multimedia_sort-by-color': 'By color A-Z',
  'multimedia_sort-by-reference': 'By reference A-Z',
  // Images
  'multimedia_images_popover-edit': 'Edit',
  'multimedia_images_popover-remove': 'Delete',
  'multimedia_images_popover-yes': 'Yes',
  'multimedia_images_popover-no': 'No',
  // Filter
  'multimedia_filter-remove-filters': 'Delete Filters',
  'multimedia_filter-season': 'Season',
  'multimedia_filter-division': 'Division',
  'multimedia_filter-genders': 'Gender',
  'multimedia_filter-visualization': 'Visualization',
  'multimedia_filter-image-rights': 'Image rights',
  'multimedia_filter-country': 'Country',
  'multimedia_filter-tags': 'Tags',
  'multimedia_filter-favorites': 'Favorites',
  'multimedia_filter-start': 'Playable from',
  'multimedia_filter-end': 'Playable until',
  //Dragger
  'multimedia_upload-images-manually_dragger_msg-error': 'Supported files',
  'multimedia_upload-images-manually_dragger_msg_image-size-limit_error':
    'The image size must be under 3MB',
  'multimedia_upload-images-manually_dragger_msg_video-size-limit_error':
    'The video size must be under 100MB',
  'multimedia_upload-images-manually_dragger_text-1': 'Click or drag and drop an image',
  // Upload image manually
  'multimedia_upload-images-manually_form-item_selection': 'Select',
  'multimedia_upload-images-manually_form-item_title': 'Title',
  'multimedia_upload-images-manually_form-item_title-required':
    'Please enter the image title',
  'multimedia_upload-images-manually_form-item_usage-required':
    'Please enter the usage type',
  'multimedia_upload-images-manually_form-item_name': 'Technical Name',
  'multimedia_upload-images-manually_form-item_name-required':
    'Please enter the image name',
  'multimedia_upload-images-manually_form-item_description': 'Description',
  'multimedia_upload-images-manually_form-item_description-required':
    'Please, enter the description',
  'multimedia_upload-images-manually_form-item_divisions': 'Division',
  'multimedia_upload-images-manually_form-item_season': 'Season',
  'multimedia_upload-images-manually_form-item_language': 'Language',
  'multimedia_upload-images-manually_form-item_genders': 'Gender',
  'multimedia_upload-images-manually_form-item_products': 'Products',
  'multimedia_upload-images-manually_form-item_catalog_id': 'Id catalogue',
  'multimedia_upload-images-manually_form-item_catalog_id_text-1':
    'Field associated with Chronos to define its advertising circuit',
  'multimedia_upload-images-manually_form-item_catalog_id_text-2':
    'Contact Hub4Retail to activate Chronos',
  'multimedia_upload-images-manually_form-item_usage': 'Use of content',
  'multimedia_upload-images-manually_form-item_visualization': 'Visualization',
  'multimedia_upload-images-manually_form-item_tags': 'Tags',
  'multimedia_upload-images-manually_form-item_tags_text-1': 'Create your own tags',
  'multimedia_upload-images-manually_form-item_start': 'Playable from',
  'multimedia_upload-images-manually_form-item_end': 'Playable until',
  'multimedia_upload-images-manually_form-item_copyright':
    'Does this video have image rights?',
  'multimedia_upload-images-manually_form-item_enable_download':
    'Do you allow downloading?',
  'multimedia_upload-images-manually_form-item_countries': 'Country',
  'multimedia_upload-images-manually_form-item_add': 'Add',
  'multimedia_upload-images-manually_form-item_next': 'Next',
  'multimedia_upload-images-manually_form-item_yes': 'Yes',
  'multimedia_upload-images-manually_form-item_no': 'No',
  // Modal manually
  'multimedia_upload-images-manually_modal_title': 'Update image',
  'multimedia_upload-images-manually_modal_step-1': 'Select an image',
  'multimedia_upload-images-manually_modal_step-2': 'Complete the data',
  'multimedia_upload-images-manually_modal_step-2-msg': 'Image title',
  // Upload images CSV
  'multimedia_upload-images_button': 'Upload images',
  'multimedia_upload-images_step-1': 'Select images',
  'multimedia_upload-images_step-1_dragger_text-1': 'Click or drag and drop image files',
  'multimedia_upload-images_step-1_dragger_text-2': 'Take note of the following...',
  'multimedia_upload-images_step-1_dragger_text-3': 'Save the images with the name',
  'multimedia_upload-images_step-1_dragger_text-4': 'reference_color_imagen#',
  'multimedia_upload-images_step-2': 'Upload CSV',
  'multimedia_upload-images_step-2_dragger_text-1': 'Click or drag and drop the CSV file',
  'multimedia_upload-images_step-3': 'Upload finished!',
  'multimedia_upload-images_step-3-text': 'image files uploaded',
  'multimedia_upload-images_modal_title': 'Upload images',
  'multimedia_upload-images_modal_btn-back': 'Back',
  'multimedia_upload-images_modal_btn-next': 'Next',
  'multimedia_upload-images_modal_btn-close': 'Close',
  //Upload
  'multimedia_upload_step-2': 'Complete the details',
  // Upload videos
  'multimedia_upload-videos_button': 'Upload video',
  'multimedia_upload-videos_step-1_dragger_text-1': 'Click or drag and drop video files',
  'multimedia_upload-videos_step-1': 'Select video',
  'multimedia_upload-videos_modal_title': 'Upload video',
  // Upload banners
  'multimedia_upload-banners_button': 'Upload banners',
  'multimedia_upload-banners_modal_title': 'Upload banner',
  'multimedia_upload-banner_step-1': 'Select banner',
  'multimedia_upload-banners_step-1_dragger_text-1': 'Click or drag and drop a banner',
  ////////////////////////////

  // BRAND USERS /////////////////
  'brand-user_title': 'Users',
  'brand-user_description': 'Control how members of your team interact with Hub4Retail.',
  'brand-user_create-user': 'Add user',
  'brand-user_modal_title-create': 'Add user',
  'brand-user_modal_title-edit': 'Edit user',
  'brand-user_modal_input_name': 'Name',
  'brand-user_modal_input_lastname': 'Last name',
  'brand-user_modal_input_email': 'Email',
  'brand-user_modal_input_password': 'Password',
  'brand-user_modal_input_save-btn': 'Save',
  'brand-user_modal_input_cancel-btn': 'Cancel',
  'brand-user_table_name': 'Name',
  'brand-user_table_email': 'Email',
  'brand-user_table_role': 'Role',
  'brand-user_table_last-session': 'Last session',
  'brand-user_table_last-session-never': 'Never',
  'brand-user_delete-popconfirm_title': 'Delete the user',
  'brand-user_delete-popconfirm_description':
    'Are you sure you want to delete this user?',
  'brand-user_delete-popconfirm_confirm': 'Yes',
  'brand-user_delete-popconfirm_cancel': 'No',
  ////////////////////////////////

  // CATALOG //////////////////
  'catalog_add-product': 'Add product',
  catalog_ProductList_results: 'Results',
  'catalog_no-more-results': 'No results found...',
  'catalog_tags_delete-all': 'Delete all',
  'catalog_loading-filters': 'Loading filters',
  // Sort by
  'catalog_sort-by_dateAsc': 'Latest added',
  'catalog_sort-by_dateDesc': 'Oldest',
  'catalog_sort-by_unitsAsc': 'Best-selling',
  'catalog_sort-by_unitsDesc': 'Least sold',
  'catalog_sort-by_nameAsc': 'Name a-z',
  'catalog_sort-by_nameDesc': 'Name z-a',
  'catalog_sort-by_colorAsc': 'Color a-z',
  'catalog_sort-by_colorDesc': 'Color z-a',
  'catalog_sort-by_pviAsc': 'PVI from lowest to highest',
  'catalog_sort-by_pviDesc': 'PVI from highest to lowest',
  'catalog_sort-by_pvprAsc': 'PVPR from lowest to highest',
  'catalog_sort-by_pvprDesc': 'PVPR from highest to lowest',
  'catalog_sort-by_referenceAsc': 'Reference a-z',
  'catalog_sort-by_referenceDesc': 'Reference z-a',
  'catalog_search-input-placeholder': 'Search by reference',
  // Sor by images
  'catalog_sort-by-images_with-images': 'With images',
  'catalog_sort-by-images_without-images': 'Without images',
  'catalog_sort-by-images_all': 'All products',
  'catalog_sort-by-images_download-csv-products-without-images-tooltip':
    'Download CSV of products without images',
  // Add catalog
  'catalog_add-catalog-csv-input-title': 'Upload CSV of products',
  'catalog_add-catalog-csv-s1-text-1': 'Option',
  'catalog_add-catalog-csv-s1-text-2': 'You can download our',
  'catalog_add-catalog-csv-s1-text-3': 'excel template',
  'catalog_add-catalog-csv-s1-text-4': 'or',
  'catalog_add-catalog-csv-s1-text-5': 'Upload your own template',
  'catalog_add-catalog-csv-s2-text-1': 'Upload CSV',
  'catalog_add-catalog-csv-s2-text-2': 'Upload images',
  'catalog_add-catalog-csv-s2-text-3': 'Determine start and end dates of the seasons',
  'catalog_add-catalog-csv_dowload-template-success': 'Template downloaded successfully',
  // Correlation-Generic
  'catalog-correlation_select-all': 'All references',
  'catalog-correlation_select-no-associated': 'Unique references by generic H4R',
  'catalog-correlation_select-no-associated-at-all': 'Unassigned references',
  'catalog-correlation_select-all_tooltip': 'View all available references',
  'catalog-correlation_select-no-associated_tooltip':
    'View references that are not repeated in more than one generic H4R',
  'catalog-correlation_select-no-associated-at-all_tooltip':
    'View references that are not assigned to any generic H4R',
  'catalog-correlation_empty-values': 'No values have been selected to assign',
  'catalog-correlation_not-associated': 'Unassigned',
  'catalog-correlation_season-label': 'Season',
  'catalog-correlation_gender-label': 'Gender',
  'catalog-correlation_color-label': 'Color',
  'catalog-correlation_color-code-label': 'Color code',
  'catalog-correlation_segmentation-label': 'Segmentation',
  'catalog-correlation_division-label': 'Division',
  'catalog-correlation_family-label': 'Family',
  'catalog-correlation_table_column_h4r': 'Generic H4R',
  'catalog-correlation_table_accept-btn': 'Save',
  'catalog-correlation_table_reject-btn': 'Cancel',
  'catalog-correlation_table_required-field': 'Required field',
  'catalog-correlation_table_add-field': 'Add',
  'catalog-correlation_pending-correlations': 'not assigned',
  'catalog-correlation_pendings-correlations': 'not assigned',
  // Upload product images
  'catalog_handle-images_pattern_title': 'Image pattern',
  'catalog_handle-images_pattern_description': 'Select the image name pattern:',
  'catalog_handle-images_pattern_separator': 'Separator:',
  'catalog_handle-images_pattern_label': 'Pattern:',
  'catalog_handle-images_pattern_save': 'Save pattern',
  'catalog_handle-images_pattern_create-message-success': 'Pattern saved successfully',
  'catalog_handle-images_pattern_create-message-error': 'Error saving pattern',
  'catalog_handle-images_upload_title': 'Upload images',
  'catalog_handle-images_upload_upload-image-success': 'Image saved',
  'catalog_handle-images_upload_upload-images-success': 'Images saved',
  'catalog_handle-images_upload_upload-image-without-pattern-success':
    'Image without pattern saved',
  'catalog_handle-images_upload_upload-images-without-pattern-success':
    'Images without pattern saved',
  'catalog_handle-images_upload_upload-images-success-correctly': 'correctly',
  'catalog_handle-images_upload_upload-image-error': 'Error uploading images',
  'catalog_handle-images_upload_upload-image-btn': 'Upload images',
  'catalog_handle-images_upload_selected-images': 'Selected images:',
  'catalog_handle-images_upload_previously-uploaded-images':
    'Previously uploaded images:',
  'catalog_handle-images_upload_uploaded-images': 'Uploaded images:',
  'catalog_handle-images_upload_images-without-pattern': 'Images without pattern:',
  'catalog_handle-images_upload_save-images-confirm':
    'Do you want to save the images without pattern?',
  'catalog_handle-images_not-matched-images_title': 'Images not matching any product',
  'catalog_handle-images_not-matched-images_save-success': 'Images assigned successfully',
  'catalog_handle-images_not-matched-images_save-error': 'Error assigning images',
  'catalog_handle-images_not-matched-images_search-placeholder':
    'Search images by reference',
  'catalog_handle-images_not-matched-images_search-clear-btn': 'Clear search',
  'catalog_handle-images_not-matched-images_assign-product-btn': 'Assign to product',
  'catalog_handle-images_not-matched-images_cancel-product-selection': 'Cancel selection',
  // Catalog download
  'catalog_download_alert_brand-required': 'Please select a brand',
  'catalog_download_alert_get-filters-error': 'Error fetching filters',
  'catalog_download_alert_dowloading-compressed-files': 'Downloading compressed files...',
  'catalog_download_progress-images': 'Images',
  'catalog_download_success-msg': 'Images downloaded successfully',
  'catalog_download_success-msg-imgs-and-csv': 'CSV and images downloaded successfully',
  'catalog_download_csv-msg-without-images': 'CSV downloaded successfully',
  'catalog_download_csv-msg-pending-images':
    'CSV downloaded successfully, but images are pending',
  'catalog_download_csv-msg-pending-images-navigate':
    'CSV downloaded successfully, but images are pending. You can continue browsing while downloading',
  'catalog_download_apply-changes-btn': 'Apply changes',
  'catalog_download_cancel-filter-btn': 'Cancel',
  'catalog_download_cancel-values-selection-btn': 'Clear selection',
  'catalog_download_cancel-values-download-btn': 'Cancel download',
  'catalog_download_clear-filters-btn': 'Clear filters',
  catalog_download_result: 'result',
  catalog_download_results: 'results',
  catalog_download_columns_brand: 'Brand',
  catalog_download_columns_reference: 'Reference',
  catalog_download_columns_ean: 'EAN',
  catalog_download_columns_size: 'Size',
  catalog_download_columns_name: 'Name',
  catalog_download_columns_color: 'Color description',
  catalog_download_columns_color_code: 'Color code',
  catalog_download_columns_pvi: 'PVI',
  catalog_download_columns_pvpr: 'PVPR',
  catalog_download_columns_division: 'Division',
  catalog_download_columns_gender: 'Gender',
  catalog_download_columns_season: 'Season',
  catalog_download_columns_segmentation: 'Segmentation',
  catalog_download_columns_family: 'Family',
  catalog_download_columns_material: 'Material',
  catalog_download_columns_images: 'Images',
  'catalog_download_columns_all-images': 'All products',
  'catalog_download_columns_with-images': 'With images',
  'catalog_download_columns_without-images': 'Without images',
  catalog_download_columns_favorites: 'Favorites',
  'catalog_download_columns_column-search_search': 'Search',
  'catalog_download_columns_column-search_reset': 'Reset',
  'catalog_download_columns_column-search_close': 'Close',
  'catalog_download_list_select-all': 'Select all',
  'catalog_download_list_clear-all': 'Clear selection',
  'catalog_download_list_download-btn': 'Download',
  'catalog_download_list_download-images-btn': 'Download images',
  catalog_download_list_item: 'item',
  catalog_download_list_items: 'items',
  'catalog_download_list_select-order_popover-title': 'Select and sort',
  ///////////////////////////

  // PRODUCT PAGE ///////////
  product_infoBox_codeEAN: 'EAN code',
  product_collection_title: 'All collection',
  product_infoProduct_other_colors: 'Other colors',
  product_infoProduct_currency: '€',
  product_infoProduct_sizes: 'Sizes and prices',
  product_infoProduct_EAN_size: 'Sizes',
  product_infoProduct_EAN_code: 'EAN codes',
  product_collapse_title_key1: 'Sizes',
  product_collapse_title_key2: 'GENERAL INFORMATION',
  product_collapse_title_key3: 'COMPOSITION',
  'product_card-color': 'color',
  'product_mobile_product-details_sizes': 'Sizes and prices',
  'product_mobile_product-details_size': 'Size',
  'product_mobile_product-details_info': 'General Information',
  'product_mobile_product-details_ean-codes': 'EAN Codes',
  'product_mobile_product-details_ean-code': 'EAN Code',
  'product_mobile_product-details_composition': 'Composition',
  'product_related-videos_title': 'Multimedia Catalog',
  'product_related-videos_subtitle': 'Related Videos',
  'product_related-products_title': 'You might also be interested in...',
  'product_card_sold-units': 'Sold units:',
  // Edit product
  'product_edit-product_button': 'Edit product',
  'product_edit-product_modal_title': 'Edit',
  'product_edit-product_modal_season': 'Season',
  'product_edit-product_modal_season_required': 'Please enter the season',
  'product_edit-product_modal_color-name': 'Color name',
  'product_edit-product_modal_color-name_required': 'Please enter the color name',
  'product_edit-product_modal_color-code': 'Color code',
  'product_edit-product_modal_color-code_required': 'Please enter the color code',
  'product_edit-product_modal_gender': 'Gender',
  'product_edit-product_modal_gender_required': 'Please enter the gender',
  'product_edit-product_modal_family': 'Family',
  'product_edit-product_modal_family_required': 'Please enter the family',
  'product_edit-product_modal_division': 'Division',
  'product_edit-product_modal_division_required': 'Please enter the division',
  'product_edit-product_modal_segmentation': 'Segmentation',
  'product_edit-product_modal_segmentation_required': 'Please enter the segmentation',
  'product_edit-product_modal_material': 'Material',
  'product_edit-product_modal_material_required': 'Please enter the material composition',
  'product_edit-product_modal_cancel-button': 'Cancel',
  'product_edit-product_modal_save-button': 'Save',
  ///////////////////////////

  // THANKS PAGE ////////////
  ThanksForRegister__register: 'BRANDS REGISTRATION',
  ThanksForRegister__title: 'Thank you for your interest in Hub4Retail!',
  ThanksForRegister__subtitle:
    'Your request is being processed and we will get back to you shortly.',
  ThanksForRegister__button: 'Go to the website',
  ///////////////////////////

  // REGISTER BRAND /////////
  RegisterBrand__title1: 'Ready to start',
  RegisterBrand__title2: 'taking your business to the',
  RegisterBrand__title3: 'next level?',
  RegisterBrand__subtitle: 'Platform designed to:',
  RegisterBrand__ex1: 'Enhance collaboration and sharing tools.',
  RegisterBrand__ex2: 'Ensure data security at all times.',
  RegisterBrand__ex3: 'Increase profit margins.',
  RegisterBrand__button: 'Back',
  RegisterBrand__register: 'Registration',
  registerBrand__form__name: 'Name',
  registerBrand__form__lastName: 'Last Name',
  registerBrand__form__business: 'Business Name',
  registerBrand__form__company: 'Trade Name',
  registerBrand__form__phone: 'Web',
  registerBrand__form__email: 'Phone',
  registerBrand__form__web: 'Corporate Email',
  registerBrand__form__description: 'Would you like to tell us something more?',
  registerBrand__form__selector: 'Sector',
  'registerBrand__form__say-more': 'Would you like to tell us something more?',
  registerBrand__form__selector__optionDefault: '',
  registerBrand__form__selector__option1: 'Footwear',
  registerBrand__form__selector__option2: 'Accessories',
  registerBrand__form__selector__option3: 'Home-Garden',
  registerBrand__form__selector__option4: 'Beauty',
  registerBrand__form__selector__option5: 'Electronics',
  registerBrand__form__selector__option6: 'Sports',
  registerBrand__form__selector__option7: 'Automotive',
  registerBrand__form__selector__option8: 'Food',
  registerBrand__form__selector__option9: 'Other',
  //////////////////////////

  // CLIENTS ///////////////
  'clients_by-client-btn': 'By client',
  'clients_by-points-sale-btn': 'Per store',
  'clients_add-client-link': '+ Add client',
  'clients_add-search-input': 'Search...',
  'clients_filters-btn': 'Filters',
  // Tables
  'clients_clients-table_businessName': 'Company Name',
  'clients_clients-table_tradeName': 'Brand Name',
  'clients_clients-table_clientCode': 'Client Code',
  'clients_clients-table_city': 'City',
  'clients_clients-table_totalPointsSale': 'Total Stores',
  'clients_clients-table_totalPointsSale_popover-title':
    'There are points of sale with new data',
  'clients_clients-table_totalPointsSale_popover-btn': 'Go to points of sale',
  'clients_clients-table_totalPointsSale_client-popover-title': 'has updated data',
  'clients_clients-table_totalPointsSale_client-popover-content': 'data added or updated',
  'clients_clients-table_totalPointsSale_client-popover-content-2':
    'data added or updated',
  'clients_clients-table_totalPointsSale_client-popover-btn': 'Go to the client',
  'clients_client-table_status': 'Status',
  'clients_client-table_action_see-profile': 'View profile',
  'clients_client-table_action_remove-popconfirm_title': 'Reject association',
  'clients_client-table_action_remove-popconfirm_description':
    'Are you sure you want to reject this association?',
  'clients_client-table_action_remove-popconfirm_on-ok': 'Accept',
  'clients_client-table_action_remove-popconfirm_on-cancel': 'Cancel',
  'clients_client-table_action_reject-popconfirm_title_en': 'Unlink Association',
  'clients_client-table_action_reject-popconfirm_description_en':
    'Are you sure you want to unlink this association?',
  'clients_client-table_action_reject-popconfirm_on-ok_en': 'Accept',
  'clients_client-table_action_reject-popconfirm_on-cancel_en': 'Cancel',
  'client_clients-page_table_disassociate-modal_title_en': 'Unlink Client',
  'client_clients-page_table_disassociate-modal_on-ok_en': 'Unlink',
  'client_clients-page_table_disassociate-modal_on-cancel_en': 'Cancel',
  'client_clients-page_table_disassociate-modal_content_en':
    'Are you sure you want to unlink this client and all its sales points?',
  'clients_sale-point-table_businessName': 'Company Name',
  'clients_sale-point-table_shop-name': 'Store Name',
  'clients_sale-point-table_category': 'Category',
  'clients_sale-point-table_tradeName': 'Brand Name',
  'clients_sale-point-table_pointSaleCode': 'Store Code',
  'clients_sale-point-table_city': 'City',
  'clients_sale-point-table_personae-and-tier': 'Personae & Tier',
  'clients_sale-point-table_share-data': 'Share data',
  'clients_sale-point-table_share-data-popover-share': 'Receiving client sales data.',
  'clients_sale-point-table_share-data-popover-no-share':
    'Not receiving client sales data.',
  'clients_sale-point-table_recive-data': 'Receive data',
  'clients_sale-point-table_recive-data_green_en': 'Receiving client sales data.',
  'clients_sale-point-table_recive-data_orange_en': 'The client is not sharing data.',
  'clients_sale-point-table_recive-data_red_en':
    'No data is being received from the goal.',
  'clients_sale-point-table_connection': 'Connection',
  'clients_sale-point-table_connection-popover-share':
    "Connected to client's stores terminal.",
  'clients_sale-point-table_connection-popover-no-share':
    "Not connected to client's stores terminal.",
  'clients_sale-point-table_active': 'Active',
  'clients_sale-point-table_inactive': 'Inactive',
  'clients_sale-point-table_connected': 'Connected',
  'clients_sale-point-table_not-connected': 'Not connected',
  'clients_sale-point-table_total-clients': 'Total clients',
  'clients_sale-point-table_total-sale-points': 'Total stores',
  // Association
  'clients_association-modal_title': 'Associate client',
  'clients_association-modal_associate-label_associate_txt-1': 'Associate:',
  'clients_association-modal_associate-label_associate_txt-2':
    'the association is created and the data from the own sales point is kept.',
  'clients_association-modal_associate-label_associate-update_txt-1':
    'Associate and update:',
  'clients_association-modal_associate-label_associate-update_txt-2':
    'the association is created and the data from the sales point to be associated is copied.',
  'clients_association-modal_update-label_associate_txt-1': 'Update:',
  'clients_association-modal_update-label_associate_txt-2':
    'the data from the sales point is copied with the own sales point.',
  'clients_association-modal_update-label_associate-update_txt-1':
    'Update and associate:',
  'clients_association-modal_update-label_associate-update_txt-2':
    'the association is created and the data from the sales point to be associated is copied.',
  'clients_pending-association-modal_title': 'Pending Associations',
  'clients_pending-association-modal_no-more': 'Do not show again',
  'clients_pending-association-modal_close': 'Close',
  'clients_association-modal_on-ok': 'Accept',
  'clients_association-modal_on-finish': 'Finish',
  'clients_association-modal_on-next': 'Next',
  'clients_association-modal_on-cancel': 'Cancel',
  'clients_association-modal_ps_pdv-code_en': 'PDV Code',
  'clients_association-modal_ps_associate_en': 'Associate',
  'clients_association-modal_ps_update_en': 'Update',
  'clients_association-modal_ps_empty-ps_en': 'No new sales points',
  'clients_association-table_item_client-code': 'Client Code',
  'clients_association-table_item_cif': 'CIF Number',
  'clients_association-table_item_trade-name': 'Trade Name',
  'clients_association-table_item_business-name': 'Business Name',
  'clients_association-table_item_address': 'Address',
  'clients_association-table_item_postal-code': 'Postal Code',
  'clients_association-table_item_city': 'City',
  'clients_association-table_item_province': 'Province',
  'clients_association-table_item_country': 'Country',
  'clients_association-table_item_contact_en': 'Contact',
  'clients_association-table_item_email': 'Email',
  'clients_association-table_item_phone-number': 'Phone Number',
  'clients_association-steps_step-1': 'General Information',
  'clients_association-steps_step-2': 'Sales Points',
  // Add client form
  'clients_add-client-form-label_trade-name': 'Brand Name',
  'clients_add-client-form-label_business-name': 'Company Name',
  'clients_add-client-form-label_client-code': 'Client code',
  'clients_add-client-form-label_clientCode': 'Client code',
  'clients_add-client-form-label_country': 'Country',
  'clients_add-client-form-label_population': 'City',
  'clients_add-client-form-label_province': 'Province',
  'clients_add-client-form-label_address': 'Address',
  'clients_add-client-form-label_postal-code': 'Postal Code',
  'clients_add-client-form-label_cif': 'VAT (Tax Identification)',
  'clients_add-client-form-label_phone-number': 'Phone Number',
  'clients_add-client-form-label_email': 'Email',
  'clients_add-client-form-input-validation_trade-name': 'Please enter the brand name',
  'clients_add-client-form-input-validation_business-name':
    'Please enter the company name',
  'clients_add-client-form-input-validation_country': 'Please enter the country',
  'clients_add-client-form-input-validation_population': 'Please enter the city',
  'clients_add-client-form-input-validation_address': 'Please enter the address',
  'clients_add-client-form-input-validation_postal-code': 'Please enter the postal code',
  'clients_add-client-form-input-validation_cif':
    'Please enter the VAT (Tax Identification)',
  'clients_add-client-form-input-validation_email-required': 'Please enter the email',
  'clients_add-client-form-input-validation_email': 'Invalid email',
  'clients_add-client-form-input-validation_phone-number':
    'Please enter the phone number',
  // Add client
  'clients_add-client_add-manually': 'Add client manually',
  'clients_add-client_add-by-csv-1': 'Upload CSV',
  'clients_add-client_add-by-csv-2': 'clients',
  'clients_add-client_add-by-excel': 'Download Excel template',
  // Client menu
  'clients_menu-desktop_resume': 'Summary',
  'clients_menu-desktop_business-info': 'Business information',
  'clients_menu-desktop_main-contacts': 'Main contacts',
  'clients_menu-desktop_sale-points': 'Stores',
  'clients_menu-desktop_commercial-info': 'Commercial information',
  // Client resume
  client_resume_title: 'Available Catalog',
  'client_resume_main-info-panel_title': 'General Information',
  'client_resume_main-info-panel_li-info_client-code': 'Client Code:',
  'client_resume_main-info-panel_li-info_trade-name': 'Brand Name:',
  'client_resume_main-info-panel_li-info_web-page': 'Website:',
  'client_resume_main-info-panel_li-info_points-of-sale': 'Points of Sale:',
  'client_resume_main-info-panel_li-info_email': 'Email:',
  'client_resume_main-info-panel_li-info_category': 'Category:',
  'client_resume_main-info-panel_li-info_start-date': 'Registration Date',
  'client_resume_main-info-panel_li-info_start-season': 'Starting Season:',
  'client_resume_main-info-panel_li-info_phone-number': 'Phone:',
  'client_resume_main-info-panel_li-info_main-contact': 'Main Contact:',
  'client_resume_main-info-panel_li-info_contact-email': 'Contact Email:',
  // Business Info
  'client_business-info_edit-button': 'Edit',
  'client_business-info_name': 'Brand Name',
  'client_business-info_legal_name': 'Company Name',
  'client_business-info_cif': 'VAT (Tax Identification)',
  'client_business-info_address': 'Address',
  'client_business-info_postal_code': 'Postal Code',
  'client_business-info_city': 'City',
  'client_business-info_country': 'Country',
  'client_business-info_province': 'Province',
  'client_business-info_email': 'Email',
  'client_business-info_contact_name': 'Main Contact',
  'client_business-info_phoneNumber': 'Phone Number',
  'client_business-info_clientCode': 'Client Code',
  'client_business-info_web': 'Website',
  'client_business-info_floor': 'Floor',
  'client_business-info_door': 'Door',
  'client_business-info_upgrade_all-popover_title': 'Update new data',
  'client_business-info_upgrade_popover_update': 'Update',
  'client_business-info_upgrade_popover_update-all': 'Update all',
  'client_business-info_upgrade_update-field': 'field to update',
  'client_business-info_upgrade_update-fields': 'fields to update',
  'client_business-info_upgrade_client-updates': 'has changed',
  'client_business-info_upgrade_new-value': 'New',
  'client_business-info_upgrade_new-actual': 'Actual',
  // Client Contacts
  'client_business-client-contacts_title': 'Main Contacts',
  'client_business-client-contacts_add-contact': '+ Add Contact',
  'client_business-client-contacts_modal_title': 'Remove Contact',
  'client_business-client-contacts_modal_text':
    'Do you want to remove the contact from the list? This action does not delete it from your contacts.',
  'client_business-client-contacts_modal_ok-text': 'Remove',
  'client_business-client-contacts_modal_cancel-text': 'Cancel',
  //////////////////////////

  // CLIENT ///////////////
  // Resume page
  'client_resume-page_history': 'History',
  'client_resume-page_contacts': 'Contacts',
  'client_resume-page_accept-association': 'Associate',
  'client_resume-page_reject-association': 'Reject',
  'client_resume-page_accept-association_btn-title': 'Accept Association',
  'client_resume-page_reject-association_btn-title': 'Reject Association',
  'client_resume-page_client-brand-works_title': 'Brands Worked With',
  'client-page_history-points-of-sale_associated': 'Associated',
  'client-page_history-points-of-sale_not-associated': 'Not Associated',
  'client-page_history-active-contacts_name': 'Name',
  'client-page_history-active-contacts_email': 'Email',
  'client-page_history-active-contacts_phone-number': 'Phone Number',
  'client-page_history-active-contacts_department': 'Department',
  // Commercial info page
  'client_commercial-info_title': 'Commercial Information',
  'client_commercial-info_add-agent': '+ Add Agent',
  'client_commercial-info_table_agent-name': 'Sales Agent / Distributor',
  'client_commercial-info_table_division': 'Division',
  'client_commercial-info_table_buy-group': 'Buying Group',
  'client_commercial-info_table_buy-group-true': 'Yes',
  'client_commercial-info_table_buy-group-false': 'No',
  // Sale points page
  'client_sale-points-page_title': 'Stores',
  'client_sale-points-page_add-sale-point': '+ Add',
  'client_sale-points-page_associate-sale-point': 'Associate point of sale',
  'client_sale-points-page_table_client-code': 'Code',
  'client_sale-points-page_table_address': 'Address',
  'client_sale-points-page_table_city': 'City',
  'client_sale-points-page_table_division': 'Division',
  'client_sale-points-page_table_personae': 'Personae',
  'client_sale-points-page_table_tier': 'Tier',
  'client_sale-points-page_table_pdv': 'Store Photos',
  'client_sale-points-page_table_name': 'Name',
  'client_sale-points-page_table_area': 'Commercial area (M2)',
  'client_sale-points-page_table_category': 'Category',
  'client_sale-points-page_table_brands': 'Brands',
  'client_sale-points-page_table_disassociate-modal_title_en': 'Unlink Sales Point',
  'client_sale-points-page_table_disassociate-modal_on-ok_en': 'Unlink',
  'client_sale-points-page_table_disassociate-modal_on-cancel_en': 'Cancel',
  'client_sale-points-page_table_disassociate-modal_content_en':
    'Are you sure you want to unlink this sales point?',
  'client_sale-points-page_table_associate-modal_title_en': 'Link Sales Points',
  'client_sale-points-page_table_associate-modal_on-ok_en': 'Update',
  'client_sale-points-page_table_associate-modal_on-cancel_en': 'Cancel',
  'client_sale-points-page_table_status_associated': 'Associated',
  'client_sale-points-page_table_status_matching': 'Pending',
  'points-of-sale_drawer_exchange_label': 'Currency Type',
  'points-of-sale_drawer_add-edit-sale-point_exchange_input-validation':
    'Please, enter the currency type',
  'client_sale-points-page_table_status_not-associated': 'Not Associated',
  'client_sale-points-page_upgrade_online': 'Physical store',
  'client_sale-points-page_upgrade_category_id': 'Category',
  'client_sale-points-page_upgrade_name': 'Store name',
  'client_sale-points-page_upgrade_exchange_id': 'Currency type',
  'client_sale-points-page_upgrade_area': 'Commercial area',
  'client_sale-points-page_upgrade_address': 'Address',
  'client_sale-points-page_upgrade_floor': 'Floor',
  'client_sale-ppoints-page_upgrade_door': 'Door',
  'client_sale-points-page_upgrade_city': 'City',
  'client_sale-points-page_upgrade_province': 'Province',
  'client_sale-points-page_upgrade_postal_code': 'Postal code',
  'client_sale-points-page_upgrade_country_id': 'Country',
  'client_sale-points-page_upgrade_lat': 'Latitude',
  'client_sale-points-page_upgrade_lng': 'Longitude',
  'client_sale-points-page_upgrade_tel_prefix': 'Telephone prefix',
  'client_sale-points-page_upgrade_tel_sufix': 'Phone number',
  'client_sale-points-page_upgrade_web': 'Web',
  'client_sale-points-page_upgrade_popconfirm-title': 'Update sales point data',
  'client_sale-points-page_upgrade_popconfirm-description':
    'Are you sure you want to update the data of all sales points?',
  'client_sale-points-page_upgrade_upgrade-btn': 'Update sales points',
  // Point of sale info modal
  'points-of-sale_point-of-sale-info_close-btn': 'Close',
  // Info
  'points-of-sale_point-of-sale-info_info_title': 'Information',
  'points-of-sale_point-of-sale-info_info_code': 'Code',
  'points-of-sale_point-of-sale-info_info_store-type': 'Store Type',
  'points-of-sale_point-of-sale-info_info_store-type_online': 'Online',
  'points-of-sale_point-of-sale-info_info_store-type_physic': 'Physical',
  'points-of-sale_point-of-sale-info_info_category': 'Category',
  'points-of-sale_point-of-sale-info_info_web': 'Web Page',
  'points-of-sale_point-of-sale-info_info_exchange-type': 'Currency Type',
  'points-of-sale_point-of-sale-info_info_start-season': 'Start Season',
  'points-of-sale_point-of-sale-info_info_division': 'Division',
  'points-of-sale_point-of-sale-info_info_segmentation': 'Personae',
  'points-of-sale_point-of-sale-info_info_tier': 'Tier',
  // Connection
  'points-of-sale_point-of-sale-info_connection_title': 'Connection',
  'points-of-sale_point-of-sale-info_connection_no-associated':
    'No association with point of sale',
  'points-of-sale_point-of-sale-info_connection_meta-connection': 'Meta Connection',
  'points-of-sale_point-of-sale-info_connection_meta-connection_true': 'Connected',
  'points-of-sale_point-of-sale-info_connection_meta-connection_false': 'Not Connected',
  'points-of-sale_point-of-sale-info_connection_meta-receiving':
    'Receiving data from Meta',
  'points-of-sale_point-of-sale-info_connection_meta-receiving_true': 'Receiving',
  'points-of-sale_point-of-sale-info_connection_meta-receiving_false':
    '24hrs without receiving',
  'points-of-sale_point-of-sale-info_connection_data-share': 'Client shares data',
  'points-of-sale_point-of-sale-info_connection_data-share_true': 'Sharing',
  'points-of-sale_point-of-sale-info_connection_data-share_false': 'Not sharing',
  // Contact
  'points-of-sale_point-of-sale-info_contact_title': 'Contact',
  'points-of-sale_point-of-sale-info_contact_contact-name': 'Contact Person',
  'points-of-sale_point-of-sale-info_contact_phone-number': 'Contact Phone',
  'points-of-sale_point-of-sale-info_contact_email': 'Email',
  'points-of-sale_point-of-sale-info_contact_address': 'Address',
  // Brand works
  'points-of-sale_point-of-sale-info_contact_brand-works_title_0': 'brands at',
  'points-of-sale_point-of-sale-info_contact_brand-works_title_1': 'brand at',
  'points-of-sale_point-of-sale-info_contact_brand-works_empty':
    'No brands at the point of sale yet',
  // Drawers
  // Sale points
  'client_drawer_add-edit-sale-point_title': 'Point of sale data',
  'client_drawer_add-edit-sale-point_name': 'Nombre',
  'client_drawer_add-edit-sale-point_point-of-sale-type_en': 'Type of Sales Point',
  'client_drawer_add-edit-sale-point_point-of-sale-type_physical_en': 'Physical Store',
  'client_drawer_add-edit-sale-point_point-of-sale-type_online_en': 'Online Store',
  'client_drawer_add-edit-sale-point_category_en': 'Category',
  'client_drawer_add-edit-sale-point_client-code': 'Code',
  'client_drawer_add-edit-sale-point_client-area': 'Commercial area (M2)',
  'client_drawer_add-edit-sale-point_address': 'Address',
  'client_drawer_add-edit-sale-point_postal-code': 'Postal Code',
  'client_drawer_add-edit-sale-point_city': 'City',
  'client_drawer_add-edit-sale-point_country': 'Country / Region',
  'client_drawer_add-edit-sale-point_province': 'State / Province',
  'client_drawer_add-edit-sale-point_name_input-validation':
    'Por favor, introduce el nombre del punto de venta',
  'client_drawer_add-edit-sale-point_category_input-validation_en':
    'Please enter the sales point category',
  'client_drawer_add-edit-sale-point_client-code_input-validation':
    'Please enter the client code',
  'client_drawer_add-edit-sale-point_address_input-validation':
    'Please enter the address',
  'client_drawer_add-edit-sale-point_postal-code_input-validation':
    'Please enter the postal code',
  'client_drawer_add-edit-sale-point_city_input-validation': 'Please enter the city',
  'client_drawer_add-edit-sale-point_country_input-validation':
    'Please enter the Country / Region',
  'client_drawer_add-edit-sale-point_province_input-validation':
    'Please enter the State / Province',
  'client_drawer_add-edit-sale-point_division': 'Division',
  'client_drawer_add-edit-sale-point_personae': 'Personae',
  'client_drawer_add-edit-sale-point_tier': 'Tier',
  'client_drawer_add-edit-sale-point_share-sale-point-title': 'stores Clasification',
  'client_drawer_add-edit-sale-point_share-sale-point-data':
    'Do you want to share data with this store?',
  'client_drawer_add-edit-sale-point_share-sale-point-data-true': 'Yes',
  'client_drawer_add-edit-sale-point_share-sale-point-data-false': 'No',
  // Commercial info
  'client_drawer_client-commercial-info_title': 'Assign Agent',
  'client_drawer_client-commercial-info_select': 'Agents',
  'client_drawer_client-commercial-info_select-placeholder': 'Select agents',
  'client_drawer_client-commercial-info_add-agent': '+ Add Agent',
  // Client contacts
  'client_drawer_client-contacts_title': 'Main Contacts',
  // Edit client
  'client_drawer_edit-client_title': 'Company Data',
  //////////////////////////

  // ACTIONS ////////////////
  // Clients
  'clients_actions_add-client-success': 'Client created successfully',
  'clients_actions_update-client-success': 'Client updated successfully',
  'clients_actions_update-client-error': 'Error updating client',
  'clients_actions_client-exists': 'Client already exists',
  'clients_actions_add-point-of-sale-success': 'Sale point created successfully',
  'clients_actions_add-point-of-sale-error': 'Error creating sale point',
  'clients_actions_update-point-of-sale-success': 'Sale point updated successfully',
  'clients_actions_upgrade-point-of-sale-success': 'Point of sale updated successfully',
  'clients_actions_upgrade-points-of-sale-success': 'Points of sale updated successfully',
  'clients_actions_update-point-of-sale-error': 'Error updating sale point',
  'clients_actions_share-data-point-of-sale-success': 'Sharing data with sale point',
  'clients_actions_dont-share-data-point-of-sale-success':
    'You have stopped sharing data with sale point',
  'clients_actions_association-success_en': 'Client successfully associated',
  'clients_actions_association-error_en': 'Error in associating the client',
  'clients_actions_reject-association-success_en': 'Successfully unlinked',
  'clients_actions_reject-association-error_en': 'Error in unlinking the client',
  'clients_actions_accept-ps-association-success_en': 'Sales point successfully linked',
  'clients_actions_accept-ps-association-error_en': 'Error in linking the sales point',
  // Commercial Profile
  'commercial-profile_actions_init-bio_error': 'Error fetching bio',
  'commercial-profile_actions_update-bio_success': 'Bio updated successfully',
  'commercial-profile_actions_update-bio_error': 'Error updating bio',
  // Catalog Correlation
  'catalog-correlation_assign-generics_success': 'Generics assigned successfully',
  'catalog-correlation_assign-generics_error': 'Error assigning generics',
  // Catalog CSV upload
  'catalog-csv-upload_publish-upload-success-1': 'Catalog published successfully',
  'catalog-csv-upload_publish-upload-success-2': 'Catalog hidden successfully',
  'catalog-csv-upload_publish-upload-error-1': 'Error publishing the catalog',
  'catalog-csv-upload_publish-upload-error-2': 'Error hiding the catalog',
  'catalog-csv-upload_reject-upload-success': ' The catalog is being deleted; it will be reflected on the platform in a few minutes.',
  'catalog-csv-upload_reject-upload-error': 'Error deleting the catalog',
  'catalog-csv-upload_set-start-season-date-success':
    'Start season date updated successfully',
  'catalog-csv-upload_set-start-season-date-error':
    'Error updating the start season date',
  'catalog-csv-upload_set-end-season-date-success':
    'End season date updated successfully',
  'catalog-csv-upload_set-end-season-date-error': 'Error updating the end season date',
  'catalog-csv-upload_enable-season-success-1': 'Season enabled successfully',
  'catalog-csv-upload_enable-season-success-2': 'Season disabled successfully',
  'catalog-csv-upload_enable-season-error-1': 'Error enabling the season',
  'catalog-csv-upload_enable-season-error-2': 'Error disabling the season',
  'catalog-csv-upload_get-mappings-error-1': 'Error fetching mapping keys',
  'catalog-csv-upload_get-mappings-error-2': 'Server error fetching mapping keys',
  'catalog-csv-upload_delete-mapping-success': 'Mapping deleted successfully',
  'catalog-csv-upload_delete-mapping-error': 'Error deleting the mapping',
  'catalog-csv-upload_create-mapping-success': 'Mapping created successfully',
  'catalog-csv-upload_create-mapping-error': 'Error creating the mapping',
  // Commercial profile
  'commercial-proile_actions_set-banner-action-success': 'Banner updated successfully',
  'commercial-proile_actions_set-banner-action-error': 'Error updating the banner',
  'commercial-proile_actions_set-banner-action-server-error':
    'Server error updating the banner',
  'commercial-proile_actions_set-products-action-success':
    'Products updated successfully',
  'commercial-proile_actions_set-products-action-error': 'Error updating the products',
  'commercial-proile_actions_set-products-action-server-error':
    'Server error updating the products',
  'commercial-proile_actions_remove-product-action-error': 'Error deleting the product',
  'commercial-proile_actions_remove-product-action-server-error':
    'Server error deleting the product',
  'commercial-proile_actions_set-videos-action-success': 'Videos updated successfully',
  'commercial-proile_actions_set-videos-action-error': 'Error updating the videos',
  'commercial-proile_actions_set-videos-action-server-error':
    'Server error updating the videos',
  'commercial-proile_actions_remove-video-action-error': 'Error deleting the video',
  'commercial-proile_actions_remove-video-action-server-error':
    'Server error deleting the video',
  ///////////////////////////

  // SETTINGS PAGE ////////
  'settings-page_nav_chronos': 'Chronos',
  'settings-page_nav_devices': 'Devices',
  'settings-page_nav_touch-screen': 'Touch Screens',
  'settings-page_nav_users': 'Users',
  'settings-page_nav_system-settings': 'System Settings',
  'settings-page_nav_commercial-profile': 'Commercial Profile Brand',
  'settings-page_nav_billing': 'Billing',
  'settings-page_nav_support': 'Support',
  // Users page
  'settings-page_users-page_create-user-btn': 'Create User',
  /////////////////////////

  // COMMERCIAL PROFILE ////////////
  'commercial-profile_title': 'Commercial Profile',
  'commercial-profile_btn-edit': 'Edit',
  'commercial-profile_save-btn': 'Save',
  'commercial-profile_banner_title': 'Upload Banner',
  'commercial-profile_banner_p-1':
    'The banner will appear on the commercial profile of your brand to promote it and connect with multi-brand stores.',
  'commercial-profile_banner_p-2': 'Upload a banner of 1800x200 px.',
  'commercial-profile_banner_p-3':
    'Ensure it is of good quality and appealing to attract multi-brand stores.',
  'commercial-profile_about-us_title': 'About Us',
  'commercial-profile_about-us_p-1':
    'Tell a little about your brand to attract multi-brand stores. Up to a maximum of 100 words.',
  'commercial-profile_about-us_p-2':
    'The description will appear on the commercial profile of your brand to promote it and connect with multi-brand stores.',
  'commercial-profile_our-products_title': 'Our Products',
  'commercial-profile_our-products_p': 'Select 5-10 products from your latest season.',
  'commercial-profile_our-videos_title': 'Some of our videos...',
  'commercial-profile_our-videos_p': 'Select 4 products from your latest season.',
  'commercial-profile_banner_modal_title': 'Select Banner',
  'commercial-profile_modal_onOk': 'Accept',
  'commercial-profile_modal_onCancel': 'Cancel',
  'commercial-profile_banner-title': 'Select a banner for your commercial profile',
  'commercial-profile_modal-content_banners-empty-msg': 'There are no banners yet.',
  'commercial-profile_modal-content_navigate-banners-btn': 'Go to multimedia banners',
  'commercial-profile_modal-content_videos-empty-msg': 'There are no videos yet.',
  'commercial-profile_modal-content_navigate-videos-btn': 'Go to media videos',
  'commercial-profile_our-products_modal_title': 'Select products',
  'commercial-profile_our-products_modal_max-items-msg':
    'You can only select 10 products.',
  'commercial-profile_our-products_modal_see-more-btn': 'See more',
  'commercial-profile_our-videos_modal_title': 'Select videos',
  'commercial-profile_our-videos_modal_max-items-msg': 'You can only select 4 videos.',
  'commercial-profile_search-input-placeholder_en': 'Search by name or code',
  //////////////////////////////////

  // NOTIFICATIONS /////////////////
  // Notifications Page
  'notifications-page_notifications-title': 'Notifications',
  'notifications-page_alerts-title': 'Alerts',
  'notifications_btn-name_catalog': 'View Catalog',
  'notifications_btn-name_profile': 'View Profile',
  'notifications_list_show-all': 'All',
  'notifications_list_show-unreaded': 'Unread',
  'notifications_list_per-page-select': 'per page',
  // Popover
  notifications_popover_title: 'NOTIFICATIONS',
  'notifications_popover_empty-notifications': 'No notifications',
  'notifications_popover_btn-name_catalog': 'View Catalog',
  'notifications_popover_btn-name_profile': 'View Profile',
  'notifications_popover_item_mark-as-readed_msg': 'Marked as read',
  'notifications_popover_item_mark-as-unreaded_msg': 'Marked as unread',
  'notifications_popover_item_mark-as-readed': 'Mark as read',
  'notifications_popover_item_mark-as-unreaded': 'Mark as unread',
  'notifications_popover_all-notifications-btn': 'All notifications',
  notifications_popover_accept: 'Accept',
  notifications_popover_reject: 'Reject',
  notifications_popover_pending_en: 'Pending',
  alerts_popover_title: 'ALERTS',
  'alerts_popover_empty-notifications': 'No alerts',
  'alerts_popover_all-notifications-btn': 'All alerts',
  ///////////////////////////////////

  // WHOLESALE FEED /////////////////
  'wholesale-feed_info_title': 'General Information',
  'wholesale-feed_info_city': 'City',
  'wholesale-feed_info_province': 'Province',
  'wholesale-feed_main-contact_title': 'Main Contact',
  'wholesale-feed_main-contact_name': 'Name',
  'wholesale-feed_main-contact_email': 'Email',
  'wholesale-feed_main-contact_phone-number': 'Phone Number',
  'wholesale-feed_main-contact_sector': 'Sector',
  'wholesale-feed_points-of-sale_title': 'Points of Sale',
  'wholesale-feed_working-brands_title': 'Brands Worked With',
  ///////////////////////////////////

  // MESSAGE PAGES //////////
  // Error
  'errors-page_404_subtitle': 'Sorry, the page you are looking for does not exist.',
  'errors-page_404_btn-go-back': 'Go back',
  // Coming soon
  'coming-soon-page_title': 'Coming Soon!',
  'coming-soon-page_go-back': 'Go Back',
  ///////////////////////////

  // BREADCRUMBS ///////////////
  breadcrumbs_multimedia: 'Multimedia',
  'breadcrumbs_multimedia-videos': 'Videos',
  'breadcrumbs_multimedia-images': 'Images',
  catalog_breadcrumb_item1: 'Catalogue',
  catalog_breadcrumb_item2: 'See catalogue',
  'breadcrumbs_multimedia-banners': 'Banners',
  'catalog-uploads_breadcrumb': 'Catalog Uploads',
  'catalog-uploads_handle-images_breadcrumb': 'Image management',
  'breadcrumb_catalog-download': 'Download catalog',
  'catalog-uploads-preview_breadcrumb': 'Preview',
  'add-catalog_breadcrumb': 'Add Catalog',
  catalog_correlation: 'Reference correlation',
  catalog_generic: 'Generic H4R',
  // Clients
  breadcrumbs_clients: 'Clients',
  'breadcrumbs_clients-list': 'Clients list',
  'breadcrumbs_add-client': 'Add client',
  breadcrumbs_resume: 'Summary',
  'breadcrumbs_business-info': 'Business Information',
  'breadcrumbs_main-contacts': 'Main contacts',
  'breadcrumbs_sale-points': 'Stores',
  'breadcrumbs_commercial-info': 'Commercial Information',
  // Account
  breadcrumbs_account: 'Account',
  breadcrumbs_account_profile: 'Profile',
  breadcrumbs_account_company: 'Company Information',
  breadcrumbs_account_contacts: 'Key Contacts',
  'breadcrumbs_account_commercial-info': 'Commercial Information',
  // Settings
  breadcrumbs_settings: 'Settings',
  'breadcrumbs_commercial-profile': 'Commercial Profile',
  //////////////////////////////

  // MODALS ////////////////////
  'modal_email-verified_title': 'Check your email',
  'modal_email-verified_content': 'To proceed with the process, please check your email',
  'modal_email-verified_content-1':
    'Verify the address from the received email link and then click',
  'modal_email-verified_content_btn_resend': 'Resend Verification',
  'modal_email-verified_content_btn_reload': 'Reload',
  'modal_email-verified_content_notif_resend_title': 'Resend Notification',
  'modal_email-verified_content_notif_resend_content-1':
    'The verification email has been sent to this address',
  'modal_email-verified_content_notif_resend_content-2': 'Check your email to continue.',
  //////////////////////////////

  // POLICY PRIVACY //////////
  'policy-privacy-modal_title': 'Terms of Service',
  'policy-privacy-modal_btn-accept': 'Accept',
  'policy-privacy-modal_cancel-btn': 'Cancel',
  'policy-privacy-modal_checkbox': 'I accept the Terms of Service',
  'policy-privacy-modal_checkbox-message': 'Accept the privacy policy and terms.',
  ////////////////////////////

  // SERVER DOWN MODAL /////
  'server-down-modal_title': 'Server Error',
  'server-down-modal_content':
    'We are experiencing issues with our services. Please try again later or contact technical support.',
  //////////////////////////

  // NOTIFICATIONS /////////
  'notification-error_message': 'Error Code',
  'notification-error_description':
    'Please try again later. If the problem persists, contact Hub4Retail and provide the error code.',
  //////////////////////////

  // GOOGLE ADDRESS FORM //////
  'google-address-form_address': 'Address',
  'google-address-form_floor': 'Floor',
  'google-address-form_door': 'Door',
  'google-address-form_postal-code': 'Postal Code',
  'google-address-form_city': 'City',
  'google-address-form_province': 'Province',
  'google-address-form_country': 'Country',
  'google-address-form_select-country': 'Select a country',
  /////////////////////////////

  // CSV MAPPING FIELDS //////
  'csv-mapping-feld__product_description': 'Product description',
  'csv-mapping-feld__reference': 'Reference',
  'csv-mapping-feld__color': 'Color',
  'csv-mapping-feld__season': 'Season',
  'csv-mapping-feld__ean': 'EAN',
  'csv-mapping-feld__gender': 'Gender',
  'csv-mapping-feld__material': 'Composition',
  'csv-mapping-feld__segmentation': 'Segmentation',
  'csv-mapping-feld__family': 'Family',
  'csv-mapping-feld__division': 'Division',
  'csv-mapping-feld__tier': 'Catalog Segmentation (Tier)',
  'csv-mapping-feld__color_description': 'Color Description',
  'csv-mapping-feld__pvi': 'WHP (Wholesale Price)',
  'csv-mapping-feld__pvpr': 'SRP (Suggested Retail Price)',
  'csv-mapping-feld__size': 'Size',
  'csv-mapping-feld__tags': 'Labels',
  /////////////////////////////

  // LANGUAGES /////////////////
  'spanish-lang': 'Spanish',
  'english-lang': 'English',
  'french-lang': 'French',
  'italian-lang': 'Italian',
  ////////////////////////////

  // SHARED //////////////////
  settings: 'Settings',
  logout: 'Logout',
  'admin-panel_link': 'Admin Panel',
  'see-more': 'See more',
  'see-less': 'See less',
  'see-all': 'See all',
  'save-btn': 'Save',
  'edit-btn-label': 'Edit',
  'delete-btn-label': 'Delete',
  'cancel-btn': 'Cancel',
  'see-more-content-button': 'See more',
  'never-label': 'Never',
  'clean-form': 'Clear form',
  ////////////////////////////
};

export default englishTranslation;

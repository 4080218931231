import { countriesTranslation } from 'assets/locale/es/core/countriesTranslation';

const spanishTranslation = {
  ...countriesTranslation,
  // HEADER //////////////////
  // Nav
  header_nav_dashboard: 'Dashboard',
  header_nav_dashboard_sales: 'Ventas',
  header_nav_dashboard_stock: 'Stock',
  header_nav_dashboard_prediction: 'Predicción',
  header_nav_catalog: 'Catálogo',
  'header_nav_catalog-download': 'Descargar catálogo',
  header_nav_multimedia: 'Multimedia',
  header_nav_clients: 'Clientes',
  'header_sub-nav_view-catalog': 'Ver catálogo',
  'header_sub-nav_add-product': 'Añadir producto',
  'header_sub-nav_all-catalogs': 'Gestión de catálogos',
  'header_sub-nav_catalog-correlation': 'Correlación de referencias',
  'header_sub-nav_catalog-generic': 'Genéricos H4R',
  'header_sub-nav_multimedia-catalog': 'Catálogo multimedia',
  'header_sub-nav_multimedia-catalog-videos': 'Vídeos',
  'header_sub-nav_multimedia-catalog-images': 'Imágenes',
  'header_sub-nav_multimedia-catalog-banners': 'Banners',
  'header_sub-nav_clients-list': 'Lista de clientes',
  'header_sub-nav_add-client': 'Añadir cliente',
  // Notifications
  'header_notifications_empty-notifications': 'No hay notificaciones',
  ////////////////////////////

  // SIGNUP PAGE /////////////
  'signup-page_title': 'Regístrate en:',
  'signup-page_sub-title': 'Regístrate para hacer crecer tu negocio',
  'signup-page_button-brand': 'Registrarme como marca',
  'signup-page_button-multibrand': 'Registrarme como multimarca',
  'signup-page_button-brand-wh': 'Registrarme como marca o multimarca',
  'signup-page_return-to-login': 'Volver a inicio de sesión',
  ////////////////////////////

  // ACCOUNT PAGE /////////////
  'profile-page_nav_profile': 'Perfil',
  'profile-page_nav_company': 'Datos de empresa',
  'profile-page_nav_main-contacts': 'Contactos principales',
  'profile-page_nav_commercial-info': 'Información comercial',
  /////////////////////////////

  /// DASHBOARDS //////////////
  'dashboard_empty-dashboard': 'Contacta con Hub4Retail para habilitar este servicio.',
  dashboard_dashboard_error:
    'Error al cargar el dashboard. Si el problema persiste, contactar con:',
  /////////////////////////////

  // HELMET ///////////////////
  signup: 'Registrarse',
  home: 'Inicio',
  dashboard: 'Dashboard',
  multimedia: 'Multimedia',
  'multimedia-videos': 'Multimedia videos',
  'multimedia-images': 'Multimedia imágenes',
  'multimedia-banners': 'Multimedia banners',
  clients: 'Clientes',
  profile: 'Perfil',
  company: 'Datos de empresa',
  'main-contacts': 'Contactos principales',
  'commercial-info': 'Información comercial',
  catalog: 'Catálogo',
  notifications: 'Notificaciones',
  'commercial-profile': 'Perfil Comercial',
  'all-catalogs': 'Todos los catálogos',
  'catalog-correlation': 'Correlación de referencias',
  'catalog-generic': 'Genéricos H4R',
  catalog_download: 'Descargar catálogo',
  // Client
  client_resume: 'Resumen',
  'client_business-info': 'Datos de la empresa',
  client_contacts: 'Contactos',
  'client_sale-points': 'Puntos de venta',
  'client_comercial-info': 'Información comercial',
    //***************************************************************** */
  //***************************************************************** */
  helmet_meta_edit_connection: 'Meta - Editar conexión',
  helmet_meta_edit_map: 'Meta - Editar mapa',
  //***************************************************************** */
  //***************************************************************** */
  /////////////////////////////

  // USERS ///////////////////////
  // Departments
  users_department_commercial: 'Comercial',
  users_department_administration: 'Administración',
  users_department_agent: 'Agente',
  users_department_owner: 'Propietario',
  'users_department_store-manager': 'Encargado de tienda',
  users_department_logistics: 'Logística',
  users_department_marketing: 'Marketing',
  users_department_others: 'Otros',
  ////////////////////////////////

  // ACCOUNT //////////////////
  account_profile_title: 'Mi Perfil',
  'account-info-panel_title': 'Información General',
  'account-company-info-panel_title': 'Datos de la empresa',
  // Profile
  'account-info-panel_profile_name': 'Nombre',
  'account-info-panel_profile_lastname': 'Apellido',
  'account-info-panel_profile_email': 'Email',
  'account-info-panel_profile_phone-number': 'Teléfono',
  'account-info-panel_profile_department': 'Departamento',
  'account-info-panel_profile_security-data': 'Datos de Seguridad',
  'account-info-panel_profile_change-password': 'Cambiar Contraseña',
  'account-info-panel_profile_pwd_password-label': 'Contraseña nueva',
  'account-info-panel_profile_pwd_repeat-password-label': 'Repite la contraseña nueva',
  'account-info-panel_profile_pwd_change-button': 'Cambiar contraseña',
  'account-info-panel_profile_pwd_validation':
    'La contraseña debe tener 8 caracteres de mínimo y 16 como máximo, 1 número, 1 letra mayúscula y una minúscula.',
  'account-info-panel_profile_pwd_validation-match': 'Las contraseñas no coinciden',
  'account-info-panel_profile_pwd_change-success': 'Contraseña cambiada correctamente.',
  'account-info-panel_profile_pwd_empty-inputs': 'Rellena los campos vacíos.',
  // Profile form
  'account-profile_form-input_name': 'Introduce tu nombre',
  'account-profile_form-input_name-validation': 'Por favor, introduce tu nombre',
  'account-profile_form-input_lastname': 'Introduce tu apellido',
  'account-profile_form-input_lastname-validation': 'Por favor, introduce tu apellido',
  'account-profile_form-input_email': 'Introduce tu email',
  'account-profile_form-input_email-validation-required': 'Por favor, introduce tu email',
  'account-profile_form-input_email-validation': 'El email no es válido',
  'account-profile_form-input_department': 'Introduce el departamento',
  'account-profile_form-input_department-validation':
    'Por favor, introduce el departamento',
  'account-profile_form-input_phone-number': '',
  'account-profile_form-input_phone-number-validation':
    'Por favor, introduce el número de teléfono',
  // Company
  'account-info-panel_company_title': 'Datos de la empresa',
  'account-info-panel_company_cif': 'Nº CIF',
  'account-info-panel_company_trade-name': 'Nombre Comercial',
  'account-info-panel_company_business-name': 'Razón Social',
  'account-info-panel_company_address': 'Dirección',
  'account-info-panel_company_postal-code': 'Código Postal',
  'account-info-panel_company_population': 'Población',
  'account-info-panel_company_country': 'País',
  'account-info-panel_company_email': 'Correo electrónico',
  'account-info-panel_company_phone-number': 'Teléfono',
  'account-info-panel_company_contact_name': 'Contacto Principal',
  'account-info-panel_company_metas-connected_title': 'Metas conectados',
  'account-info-panel_company_active-seasons_title': 'Temporadas activas',
  // Company form
  'account-company_form-input_trade-name': 'Introduce el nombre comercial',
  'account-company_form-input_trade-name-validation':
    'Por favor, introduce el nombre comercial',
  'account-company_form-input_business-name': 'Introduce la razón social',
  'account-company_form-input_business-name-validation':
    'Por favor, introduce la razón social',
  'account-company_form-input_country': 'Introduce el país',
  'account-company_form-input_country-validation': 'Por favor, introduce el país',
  'account-company_form-input_population': 'Introduce la población',
  'account-company_form-input_population-validation': 'Por favor, introduce la población',
  'account-company_form-input_address': 'Introduce la dirección',
  'account-company_form-input_address-validation': 'Por favor, introduce tu dirección',
  'account-company_form-input_postal-code': 'Introduce el código postal',
  'account-company_form-input_postal-code-validation':
    'Por favor, introduce el código postal',
  'account-company_form-input_cif': 'Introduce el CIF',
  'account-company_form-input_cif-validation': 'Por favor, introduce el CIF',
  'account-company_form-input_phone-number': 'Número de Teléfono',
  'account-company_form-input_phone-number-validation':
    'Por favor, introduce el número de teléfono',
  'account-company_form-input_email': 'Introduce el email de empresa',
  'account-company_form-input_contact_name':
    'Introduce el contacto principal de la empresa',
  'account-company_form-input_contact_name-validation-required':
    'Por favor, introduce el contacto principal de la empresa',
  'account-company_form-input_email-validation-required': 'Por favor, introduce tu email',
  'account-company_form-input_email-validation': 'El email no es válido',
  'account-company_form-input_instagram': 'Introduce la url de Instagram',
  'account-company_form-input_youtube': 'Introduce la url de Youtube',
  'account-company_form-input_linkedin': 'Introduce la url de Linkedin',
  'account-company_form-input_facebook': 'Introduce la url de Facebook',
  'account-company_social-media-title': 'Redes Sociales',
  'account-company_form-input_social-media-label': 'Nombre Red Social',
  'account-company_form-input_social-media-label-placeholder': 'Nombre de la Red Social',
  'account-company_form-input_social-media-label_validation':
    'Por favor, introduce el nombre de la red social',
  'account-company_form-input_social-media-value': 'URL Red Social',
  'account-company_form-input_social-media-value-placeholder': 'URL de la Red Social',
  'account-company_form-input_social-media-value_validation':
    'Por favor, introduce la URL de la red social',
  'account-company_form-input_social-media-add-button': 'Agregar Red Social',
  'account-company_form-input_social-media-accept-button_title': 'Actualizar Red Social',
  'account-company_form-input_social-media-reject-button_title': 'Descartar cambios',
  'account-company_form-input_social-media-delete-button_title': 'Eliminar Red Social',
  'account-company_form-input_social-media-delete-button_popconfirm_title':
    'Eliminar Red Social',
  'account-company_form-input_social-media-delete-button_popconfirm_description':
    '¿Seguro que quiere eliminar esta Red Social?',
  'account-company_form-input_social-media-delete-button_popconfirm_ok': 'Eliminar',
  'account-company_form-input_social-media-delete-button_popconfirm_cancel': 'Cancelar',
  // Main Contacts
  'main-contacts_title': 'Contactos Principales',
  'main-contacts_add-contact': '+ Agregar',
  'main-contacts_table_name': 'Nombre',
  'main-contacts_table_lastname': 'Apellido',
  'main-contacts_table_email': 'Correo electrónico',
  'main-contacts_table_phone-number': 'Teléfono',
  'main-contacts_table_department': 'Departamento',
  'main-contacts_table_pop-confirm_title': 'Eliminar contacto',
  'main-contacts_table_pop-confirm_description':
    '¿Seguro que quieres eliminar este contacto?',
  'main-contacts_table_pop-confirm_btn-accept': 'Borrar',
  'main-contacts_table_pop-confirm_cancel-btn': 'Cancelar',
  // Main Contacts Form
  'main-contacts_form-label_name': 'Nombre',
  'main-contacts_form-label_lastname': 'Apellido',
  'main-contacts_form-label_email': 'Correo electrónico',
  'main-contacts_form-label_phone-number': 'Teléfono',
  'main-contacts_form-label_department': 'Departamento',
  'main-contacts_form-input_name': 'Introduce el nombre',
  'main-contacts_form-input_lastname': 'Introduce el apellido ',
  'main-contacts_form-input_email': 'Introduce el email',
  'main-contacts_form-input_department': 'Introduce el departamento',
  'main-contacts_form_sector_label': 'Sector',
  'main-contacts_form-input_sector': 'Introduce el sector',
  'main-contacts_form-input_sector_validation': 'Introduce el sector',
  'main-contacts_form-input_phone-number': 'Introduce el número de teléfono',
  'main-contacts_form-input-validation_name': 'Por favor, introduce el nombre',
  'main-contacts_form-input-validation_lastname': 'Por favor, introduce el apellido ',
  'main-contacts_form-input-validation_email': 'El email no es válido',
  'main-contacts_form-input-validation-required_email': 'Por favor, introduce el email',
  'main-contacts_form-input-validation_department':
    'Por favor, introduce el departamento',
  'main-contacts_form-input-validation_phone-number':
    'Por favor, introduce el número de teléfono',
  // Comercial information
  'comercial-info_title': 'Información Comercial',
  'comercial-info_add-contact': '+ Agregar agente',
  'comercial-info_table_agent-name': 'Agente Comercial / Distribuidor',
  'comercial-info_table_division': 'División',
  'comercial-info_table_buy-group': 'Grupo de compra',
  'comercial-info_table_genre': 'Género',
  'comercial-info_table_pop-confirm_title': 'Eliminar agente comercial',
  'comercial-info_table_pop-confirm_description':
    '¿Seguro que quieres eliminar este agente comercial?',
  'comercial-info_table_pop-confirm_btn-accept': 'Borrar',
  'comercial-info_table_pop-confirm_cancel-btn': 'Cancelar',
  // Comercial information Form
  'comercial-info_form-label_name': 'Nombre',
  'comercial-info_form-label_lastname': 'Apellido',
  'comercial-info_form-label_trade-name': 'Nombre de la empresa',
  'comercial-info_form-label_business-name': 'Razón Social',
  'comercial-info_form-label_cif': 'CIF',
  'comercial-info_form-label_phone-number': 'Teléfono',
  'comercial-info_form-label_main-contact': 'Contacto principal',
  'comercial-info_form-label_working-brands': 'Marcas con las que trabaja:',
  'comercial-info_form-input_name': 'Introduce el nombre',
  'comercial-info_form-label_email': 'Email',
  'comercial-info_form-label_sector': 'Sector',
  'comercial-info_form-input_email': 'Introduce el email',
  'comercial-info_form-input_sector': 'Introduce el sector',
  'comercial-info_form-input_division': 'Introduce la división',
  'comercial-info_form-input_yes': 'Sí',
  'comercial-info_form-input_no': 'No',
  'comercial-info_form-input_lastname': 'Introduce el apellido',
  'comercial-info_form-input_trade-name': 'Introduce el nombre de la empresa',
  'comercial-info_form-input_business-name': 'Introduce el razón social',
  'comercial-info_form-input_cif': 'Introduce el CIF',
  'comercial-info_form-input_phone-number': 'Introduce el teléfono',
  'comercial-info_form-input_main-contact': 'Introduce el contacto principal',
  'comercial-info_form-input-validation_name': 'Por favor, introduce el nombre',
  'comercial-info_form-input-validation_email': 'Por favor, introduce el email',
  'comercial-info_form-input-validation_sector': 'Por favor, introduce el sector',
  'comercial-info_form-input-validation_division': 'Por favor, introduce la división',
  'comercial-info_form-input-validation_buy-group':
    'Por favor, introduce el grupo de compra',
  'comercial-info_form-input-validation_lastname': 'Por favor, introduce el apellido',
  'comercial-info_form-input-validation_trade-name':
    'Por favor, introduce el nombre de la empresa',
  'comercial-info_form-input-validation_business-name':
    'Por favor, introduce la razón social',
  'comercial-info_form-input-validation_cif': 'Por favor, introduce el CIF',
  'comercial-info_form-input-validation_phone-number': 'Por favor, introduce el teléfono',
  'comercial-info_form-input-validation_main-contact':
    'Por favor, introduce el contacto principal',
  // Drawers
  'account-profile_drawer_title': 'Información General',
  'account-company_drawer_title': 'Datos de la Empresa',
  'comercial-info_drawer_title': 'Información Comercial',
  'main-contacts_drawer_title': 'Contactos Principales',
  'main-contacts_drawer_create-title': 'Crear contacto',
  'main-contacts_drawer_update-title': 'Actualizar contacto',
  /////////////////////////////

  // MULTIMEDIA ///////////////
  'multimedia_nav-videos': 'Videos',
  'multimedia_nav-images': 'Imágenes',
  'multimedia_sort-btn': 'Ordenar por',
  'multimedia_filter-btn': 'Filtros',
  'multimedia_apply-filters-btn': 'Aplicar filtros',
  'multimedia_search-input-placeholder':
    'Buscar por marca, género, temporada, país, fecha',
  'multimedia_search-results': 'Resultados',
  multimedia_download: 'Descargar',
  multimedia_download_started: 'La descarga ha comenzado',
  multimedia_open: 'Abrir',
  'multimedia_link-copied': 'Enlace copiado al portapapeles',
  'multimedia_link-copy-button': 'Copiar enlace',
  'multimedia_delete-popconfirm-title': 'Eliminar multimedia',
  'multimedia_delete-popconfirm-description':
    '¿Seguro que quieres eliminar este archivo?',
  // Sort by
  'multimedia_sort-last-favorites': 'Favoritos',
  'multimedia_sort-last-aggregates': 'Últimos agregados',
  'multimedia_sort-best-seller': 'Más vendido',
  'multimedia_sort-less-sold': 'Menos vendido',
  'multimedia_sort-best-pvp_great-minor': 'PVP: de mayor a menor',
  'multimedia_sort-best-pvp_minor-great': 'PVP: de menor a mayor',
  'multimedia_sort-best-pvi_great-minor': 'PVI: de mayor a menor',
  'multimedia_sort-best-pvi_minor-great': 'PVI: de menor a mayor',
  'multimedia_sort-by-color': 'Por color a-z',
  'multimedia_sort-by-reference': 'Por referencia a-z',
  // Images
  'multimedia_images_popover-edit': 'Editar',
  'multimedia_images_popover-remove': 'Eliminar',
  'multimedia_images_popover-yes': 'Sí',
  'multimedia_images_popover-no': 'No',
  // Filter
  'multimedia_filter-remove-filters': 'Borrar filtros',
  'multimedia_filter-season': 'Temporada',
  'multimedia_filter-division': 'División',
  'multimedia_filter-genders': 'Género',
  'multimedia_filter-visualization': 'Visualización',
  'multimedia_filter-image-rights': 'Derechos de imagen',
  'multimedia_filter-country': 'País',
  'multimedia_filter-tags': 'Tags',
  'multimedia_filter-favorites': 'Mis favoritos',
  'multimedia_filter-start': 'Emitible desde',
  'multimedia_filter-end': 'Emitible hasta',
  //Dragger
  'multimedia_upload-images-manually_dragger_msg-error': 'Archivos admitidos',
  'multimedia_upload-images-manually_dragger_msg_image-size-limit_error':
    'El tamaño de la imagen debe ser inferior a 3MB',
  'multimedia_upload-images-manually_dragger_msg_video-size-limit_error':
    'El tamaño del video debe ser inferior a 100MB',
  'multimedia_upload-images-manually_dragger_text-1':
    'Haz click o suelta y arrastra una imagen',
  // Upload image manually
  'multimedia_upload-images-manually_form-item_selection': 'Selecciona',
  'multimedia_upload-images-manually_form-item_title': 'Título',
  'multimedia_upload-images-manually_form-item_title-required':
    'Por favor, introduce el título de la imagen',
  'multimedia_upload-images-manually_form-item_usage-required':
    'Por favor, introduce el tipo de uso',
  'multimedia_upload-images-manually_form-item_name': 'Nombre Técnico',
  'multimedia_upload-images-manually_form-item_name-required':
    'Por favor, introduce el nombre de la imagen',
  'multimedia_upload-images-manually_form-item_description': 'Descripción',
  'multimedia_upload-images-manually_form-item_description-required':
    'Por favor, introduce la descripción',
  'multimedia_upload-images-manually_form-item_divisions': 'Divisiones',
  'multimedia_upload-images-manually_form-item_season': 'Temporada',
  'multimedia_upload-images-manually_form-item_language': 'Idioma',
  'multimedia_upload-images-manually_form-item_genders': 'Géneros',
  'multimedia_upload-images-manually_form-item_products': 'Productos',
  'multimedia_upload-images-manually_form-item_catalog_id': 'Id catálogo',
  'multimedia_upload-images-manually_form-item_catalog_id_text-1':
    'Campo asociado a chronos para definir su circuito publicitario',
  'multimedia_upload-images-manually_form-item_catalog_id_text-2':
    'Contacta a Hub4Retail para activar Chronos',
  'multimedia_upload-images-manually_form-item_usage': 'Uso del contenido',
  'multimedia_upload-images-manually_form-item_visualization': 'Visualización',
  'multimedia_upload-images-manually_form-item_tags': 'Etiquetas',
  'multimedia_upload-images-manually_form-item_tags_text-1': 'Crea tus propias etiquetas',
  'multimedia_upload-images-manually_form-item_start': 'Emitible desde',
  'multimedia_upload-images-manually_form-item_end': 'Emitible hasta',
  'multimedia_upload-images-manually_form-item_copyright':
    '¿Este video está sujeto a derechos de imagen?',
  'multimedia_upload-images-manually_form-item_enable_download': '¿Permites la descarga?',
  'multimedia_upload-images-manually_form-item_countries': 'Países',
  'multimedia_upload-images-manually_form-item_add': 'Añadir',
  'multimedia_upload-images-manually_form-item_next': 'Siguiente',
  'multimedia_upload-images-manually_form-item_yes': 'Sí',
  'multimedia_upload-images-manually_form-item_no': 'No',
  // Modal manually
  'multimedia_upload-images-manually_modal_title': 'Actualizar imagen',
  'multimedia_upload-images-manually_modal_step-1': 'Selecciona una imagen',
  'multimedia_upload-images-manually_modal_step-2': 'Completa los datos',
  'multimedia_upload-images-manually_modal_step-2-msg': 'Título de la imagen',
  //******************************************************* */
  //******************************************************* */
  'multimedia_upload-images-manually_modal_step-3-msg': 'Multimedia subido correctamente',
  //******************************************************* */
  //******************************************************* */
  // Upload images CSV
  'multimedia_upload-images_button': 'Subir imágenes',
  'multimedia_upload-images_step-1': 'Seleccionar imagen',
  'multimedia_upload-images_step-1_dragger_text-1':
    'Haz click o suelta y arrastra archivos de imagen',
  'multimedia_upload-images_step-1_dragger_text-2': 'Ten en cuenta lo siguiente...',
  'multimedia_upload-images_step-1_dragger_text-3': 'Guardar las imágenes con el nombre',
  'multimedia_upload-images_step-1_dragger_text-4': 'referencia_color_nºimagen',
  'multimedia_upload-images_step-2': 'Subir CSV',
  'multimedia_upload-images_step-2_dragger_text-1':
    'Haz click o suelta y arrastra el archivo CSV',
  'multimedia_upload-images_step-3': '¡Carga finalizada!',
  'multimedia_upload-images_step-3-text': 'archivos de imagen subidos',
  'multimedia_upload-images_modal_title': 'Subir imagen',
  'multimedia_upload-images_modal_btn-back': 'Atrás',
  'multimedia_upload-images_modal_btn-next': 'Siguiente',
  'multimedia_upload-images_modal_btn-close': 'Cerrar',
  //Upload
  'multimedia_upload_step-2': 'Completa los datos',
  // Upload videos
  'multimedia_upload-videos_button': 'Subir vídeo',
  'multimedia_upload-videos_step-1_dragger_text-1':
    'Haz click o suelta y arrastra archivos de video',
  'multimedia_upload-videos_step-1': 'Seleccionar vídeo',
  'multimedia_upload-videos_modal_title': 'Subir vídeo',
  // Upload banners
  'multimedia_upload-banners_button': 'Subir banners',
  'multimedia_upload-banners_modal_title': 'Subir banner',
  'multimedia_upload-banner_step-1': 'Seleccionar banner',
  'multimedia_upload-banners_step-1_dragger_text-1':
    'Haz click o suelta y arrastra un banner',
  ////////////////////////////

  // BRAND USERS /////////////////
  'brand-user_title': 'Usuarios',
  'brand-user_description':
    'Controla cómo interactúan los miembros de tu equipo con Hub4Retail.',
  'brand-user_create-user': 'Añadir usuario',
  'brand-user_modal_title-create': 'Añadir usuario',
  'brand-user_modal_title-edit': 'Editar usuario',
  'brand-user_modal_input_name': 'Nombre',
  'brand-user_modal_input_lastname': 'Apellido',
  'brand-user_modal_input_email': 'Email',
  'brand-user_modal_input_password': 'Contraseña',
  'brand-user_modal_input_save-btn': 'Guardar',
  'brand-user_modal_input_cancel-btn': 'Cancelar',
  'brand-user_table_name': 'Nombre',
  'brand-user_table_email': 'Email',
  'brand-user_table_role': 'Rol',
  'brand-user_table_last-session': 'Última sesión',
  'brand-user_table_last-session-never': 'Nunca',
  'brand-user_delete-popconfirm_title': 'Eliminar el usuario',
  'brand-user_delete-popconfirm_description':
    '¿Seguro que quieres eliminar este usuario?',
  'brand-user_delete-popconfirm_confirm': 'Sí',
  'brand-user_delete-popconfirm_cancel': 'No',
  //*********************************************************************** */
  //*********************************************************************** */
  'brand-user_delete-popconfirm_icon-tooltip': 'Eliminar conexión',
  //*********************************************************************** */
  //*********************************************************************** */
  ////////////////////////////////

  // CATALOG //////////////////
  'catalog_add-product': 'Agregar producto',
  catalog_ProductList_results: 'Resultados',
  'catalog_no-more-results': 'No se encontraron resultados...',
  'catalog_tags_delete-all': 'Borrar todo',
  'catalog_loading-filters': 'Cargando filtros',
  // Sort by
  'catalog_sort-by_dateAsc': 'Últimos agregados',
  'catalog_sort-by_dateDesc': 'Más antiguos',
  'catalog_sort-by_unitsAsc': 'Más vendido',
  'catalog_sort-by_unitsDesc': 'Menos vendido',
  'catalog_sort-by_nameAsc': 'Nombre a-z',
  'catalog_sort-by_nameDesc': 'Nombre z-a',
  'catalog_sort-by_colorAsc': 'Color a-z',
  'catalog_sort-by_colorDesc': 'Color z-a',
  'catalog_sort-by_pviAsc': 'PVI de menor a mayor',
  'catalog_sort-by_pviDesc': 'PVI de mayor a menor',
  'catalog_sort-by_pvprAsc': 'PVPR de menor a mayor',
  'catalog_sort-by_pvprDesc': 'PVPR de mayor a menor',
  'catalog_sort-by_referenceAsc': 'Referencia a-z',
  'catalog_sort-by_referenceDesc': 'Referencia z-a',
  'catalog_search-input-placeholder': 'Buscar por referencia',
  // Sor by images
  'catalog_sort-by-images_with-images': 'Con imágenes',
  'catalog_sort-by-images_without-images': 'Sin imágenes',
  'catalog_sort-by-images_all': 'Todos los productos',
  'catalog_sort-by-images_download-csv-products-without-images-tooltip':
    'Descargar CSV de productos sin imágenes',
  // Add catalog
  'catalog_add-catalog-csv-input-title': 'Subir CSV productos',
  'catalog_add-catalog-csv-s1-text-1': 'Opcion',
  'catalog_add-catalog-csv-s1-text-2': 'Puedes descargar nuestra',
  'catalog_add-catalog-csv-s1-text-3': 'plantilla excel',
  'catalog_add-catalog-csv-s1-text-4': 'o',
  'catalog_add-catalog-csv-s1-text-5': 'Sube tu propia plantilla',
  'catalog_add-catalog-csv-s2-text-1': 'Subir CSV',
  'catalog_add-catalog-csv-s2-text-2': 'Subir imágenes',
  'catalog_add-catalog-csv-s2-text-3':
    'Determinar fecha de inicio y de fin de las temporadas',
  'catalog_add-catalog-csv_dowload-template-success':
    'Plantilla descargada correctamente',
  // Correlation-Generic
  'catalog-correlation_select-all': 'Todas las referencias',
  'catalog-correlation_select-no-associated': 'Referencias únicas por genérico H4R',
  'catalog-correlation_select-no-associated-at-all': 'Referencias no asignadas',
  'catalog-correlation_select-all_tooltip': 'Ver todas las referencias disponibles',
  'catalog-correlation_select-no-associated_tooltip':
    'Ver referencias que no se repiten en más de un genérico H4R',
  'catalog-correlation_select-no-associated-at-all_tooltip':
    ' Ver referencias que no están asignadas a ningún genérico H4R',
  'catalog-correlation_empty-values': 'No se han seleccionado valores para asignar',
  'catalog-correlation_not-associated': 'Sin asociar',
  'catalog-correlation_season-label': 'Temporada',
  'catalog-correlation_gender-label': 'Género',
  'catalog-correlation_color-label': 'Color',
  'catalog-correlation_color-code-label': 'Código color',
  'catalog-correlation_segmentation-label': 'Segmentación',
  'catalog-correlation_division-label': 'División',
  'catalog-correlation_family-label': 'Familia',
  'catalog-correlation_table_column_h4r': 'Genéricos H4R',
  'catalog-correlation_table_accept-btn': 'Guardar',
  'catalog-correlation_table_reject-btn': 'Cancelar',
  'catalog-correlation_table_required-field': 'Campo requerido',
  'catalog-correlation_table_add-field': 'Agregar',
  'catalog-correlation_pending-correlations': 'no asignada',
  'catalog-correlation_pendings-correlations': 'no asignadas',
  // Upload product images
  'catalog_handle-images_pattern_title': 'Patrón de imágenes',
  'catalog_handle-images_pattern_description':
    'Selecciona el patrón de nombre de las imágenes:',
  'catalog_handle-images_pattern_separator': 'Separador:',
  'catalog_handle-images_pattern_label': 'Patrón:',
  'catalog_handle-images_pattern_save': 'Guardar patrón',
  'catalog_handle-images_pattern_create-message-success': 'Patrón guardado correctamente',
  'catalog_handle-images_pattern_create-message-error': 'Error al guardar el patrón',
  'catalog_handle-images_upload_title': 'Subir imágenes',
  'catalog_handle-images_upload_upload-image-success': 'imagen guardada',
  'catalog_handle-images_upload_upload-images-success': 'imágenes guardadas',
  'catalog_handle-images_upload_upload-image-without-pattern-success':
    'imagen sin patrón guardada',
  'catalog_handle-images_upload_upload-images-without-pattern-success':
    'imágenes sin patrón guardadas',
  'catalog_handle-images_upload_upload-images-success-correctly': 'correctamente',
  'catalog_handle-images_upload_upload-image-error': 'Error al subir las imágenes',
  'catalog_handle-images_upload_upload-image-btn': 'Subir imágenes',
  'catalog_handle-images_upload_selected-images': 'Imágenes seleccionadas:',
  'catalog_handle-images_upload_previously-uploaded-images':
    'Imágenes anteriormente subidas:',
  'catalog_handle-images_upload_uploaded-images': 'Imágenes subidas:',
  'catalog_handle-images_upload_images-without-pattern': 'Imágenes sin patrón:',
  'catalog_handle-images_upload_save-images-confirm':
    '¿Quieres guardar las imágenes sin patrón?',
  'catalog_handle-images_not-matched-images_title':
    'Imágenes que no coinciden con ningún producto',
  'catalog_handle-images_not-matched-images_save-success':
    'Imágenes asignadas correctamente',
  'catalog_handle-images_not-matched-images_save-error': 'Error al asignar imágenes',
  'catalog_handle-images_not-matched-images_search-placeholder':
    'Buscar imágenes por referencia',
  'catalog_handle-images_not-matched-images_search-clear-btn': 'Limpiar busqueda',
  'catalog_handle-images_not-matched-images_assign-product-btn': 'Asignar a producto',
  'catalog_handle-images_not-matched-images_cancel-product-selection':
    'Cancelar selección',
  // Catalog download
  'catalog_download_alert_brand-required': 'Por favor, selecciona una marca',
  'catalog_download_alert_get-filters-error': 'Error al obtener los filtros',
  'catalog_download_alert_dowloading-compressed-files':
    'Descargando archivos comprimidos...',
  'catalog_download_progress-images': 'Imágenes',
  'catalog_download_success-msg': 'Imágenes descargadas correctamente',
  'catalog_download_success-msg-imgs-and-csv': 'CSV e imágenes descargadas correctamente',
  'catalog_download_csv-msg-without-images': 'CSV descargado correctamente',
  'catalog_download_csv-msg-pending-images':
    'CSV descargado correctamente, pero hay imágenes pendientes',
  'catalog_download_csv-msg-pending-images-navigate':
    'CSV descargado correctamente, pero hay imágenes pendientes. Puedes seguir navegando mientras se descarga',
  'catalog_download_apply-changes-btn': 'Aplicar cambios',
  'catalog_download_cancel-filter-btn': 'Cancelar',
  'catalog_download_cancel-values-selection-btn': 'Limpiar selección',
  'catalog_download_cancel-values-download-btn': 'Cancelar descarga',
  'catalog_download_clear-filters-btn': 'Limpiar filtros',
  catalog_download_result: 'resultado',
  catalog_download_results: 'resultados',
  catalog_download_columns_brand: 'Marca',
  catalog_download_columns_reference: 'Referencia',
  catalog_download_columns_ean: 'EAN',
  catalog_download_columns_size: 'Talla',
  catalog_download_columns_name: 'Nombre',
  catalog_download_columns_color: 'Descripción color',
  catalog_download_columns_color_code: 'Código color',
  catalog_download_columns_pvi: 'PVI',
  catalog_download_columns_pvpr: 'PVPR',
  catalog_download_columns_division: 'División',
  catalog_download_columns_gender: 'Género',
  catalog_download_columns_season: 'Temporada',
  catalog_download_columns_segmentation: 'Segmentación',
  catalog_download_columns_family: 'Familia',
  catalog_download_columns_material: 'Composición',
  catalog_download_columns_images: 'Imágenes',
  'catalog_download_columns_all-images': 'Todos los productos',
  'catalog_download_columns_with-images': 'Con imágenes',
  'catalog_download_columns_without-images': 'Sin imágenes',
  catalog_download_columns_favorites: 'Favoritos',
  'catalog_download_columns_column-search_search': 'Buscar',
  'catalog_download_columns_column-search_reset': 'Reiniciar',
  'catalog_download_columns_column-search_close': 'cerrar',
  'catalog_download_list_select-all': 'Seleccionar todo',
  'catalog_download_list_clear-all': 'Limpiar selección',
  'catalog_download_list_download-btn': 'Descargar',
  'catalog_download_list_download-images-btn': 'Descargar imágenes',
  catalog_download_list_item: 'item',
  catalog_download_list_items: 'items',
  'catalog_download_list_select-order_popover-title': 'Selecciona y ordena',
  ////////////////////////////

  // PRODUCT /////////////////
  product_infoBox_codeEAN: 'Códigos',
  product_collection_title: 'Toda la colection',
  product_infoProduct_other_colors: 'Otros colores',
  product_infoProduct_currency: '€',
  product_infoProduct_sizes: 'Tallas y precios',
  product_infoProduct_EAN_size: 'Talla',
  product_infoProduct_EAN_code: 'Codigo EAN',
  product_collapse_title_key1: 'Tallas',
  product_collapse_title_key2: 'INFORMACION GENERAL',
  product_collapse_title_key3: 'COMPOSICIÓN',
  'product_card-color': 'color',
  'product_mobile_product-details_sizes': 'Tallas y precios',
  'product_mobile_product-details_size': 'Talla',
  'product_mobile_product-details_info': 'Información General',
  'product_mobile_product-details_ean-codes': 'Códigos EAN',
  'product_mobile_product-details_ean-code': 'Código EAN',
  'product_mobile_product-details_composition': 'Composición',
  'product_related-videos_title': 'Catálogo Multimedia',
  'product_related-videos_subtitle': 'Videos relacionados',
  'product_related-products_title': 'También puede interesarte...',
  'product_card_sold-units': 'Unidades vendidas:',
  // Edit product
  'product_edit-product_button': 'Editar producto',
  'product_edit-product_modal_title': 'Editar',
  'product_edit-product_modal_season': 'Temporada',
  'product_edit-product_modal_season_required': 'Por favor, introduce la temporada',
  'product_edit-product_modal_color-name': 'Nombre del color',
  'product_edit-product_modal_color-name_required':
    'Por favor, introduce el nombre del color',
  'product_edit-product_modal_color-code': 'Código del color',
  'product_edit-product_modal_color-code_required':
    'Por favor, introduce el código del color',
  'product_edit-product_modal_gender': 'Género',
  'product_edit-product_modal_gender_required': 'Por favor, introduce el género',
  'product_edit-product_modal_family': 'Familia',
  'product_edit-product_modal_family_required': 'Por favor, introduce la familia',
  'product_edit-product_modal_division': 'División',
  'product_edit-product_modal_division_required': 'Por favor, introduce la división',
  'product_edit-product_modal_segmentation': 'Segmentación',
  'product_edit-product_modal_segmentation_required':
    'Por favor, introduce la segmentación',
  'product_edit-product_modal_material': 'Composición',
  'product_edit-product_modal_material_required': 'Por favor, introduce la composición',
  'product_edit-product_modal_cancel-button': 'Cancelar',
  'product_edit-product_modal_save-button': 'Guardar',
  ////////////////////////////

  /// THANKS PAGE //////////////
  ThanksForRegister__register: 'REGISTRO MARCAS',
  ThanksForRegister__title: '¡Gracias por tu interés en Hub4Retail!',
  ThanksForRegister__subtitle:
    'Su requerimiento está siendo procesado y nos pondremos a la brevedad.',
  ThanksForRegister__button: 'Ir a la web',
  ////////////////////////////

  /// REGISTER BRAND PAGE ///////////
  RegisterBrand__title1: '¿Listo para comenzar a',
  RegisterBrand__title2: 'llevar tu negocio al',
  RegisterBrand__title3: 'próximo nivel?',
  RegisterBrand__subtitle: 'Plataforma diseñada para:',
  RegisterBrand__ex1: 'Mejorar las herramientas de colaboración y uso compartido.',
  RegisterBrand__ex2: 'Garantizar la seguridad de los datos en todo momento.',
  RegisterBrand__ex3: 'Aumentar los márgenes de ingresos.',
  RegisterBrand__button: 'Volver',
  RegisterBrand__register: 'Registro',
  registerBrand__form__name: 'Nombre',
  registerBrand__form__lastName: 'Apellido',
  registerBrand__form__business: 'Razón social',
  registerBrand__form__company: 'Nombre Comercial',
  registerBrand__form__phone: 'Web',
  registerBrand__form__email: 'Teléfono',
  registerBrand__form__web: 'Email Corporativo',
  registerBrand__form__description: '¿Desea decirnos algo más?',
  registerBrand__form__selector: 'Sector',
  'registerBrand__form__say-more': '¿Desea decirnos algo más?',
  registerBrand__form__selector__optionDefault: '',
  registerBrand__form__selector__option1: 'Calzado',
  registerBrand__form__selector__option2: 'Accesorios',
  registerBrand__form__selector__option3: 'Hogar-Jardín',
  registerBrand__form__selector__option4: 'Belleza',
  registerBrand__form__selector__option5: 'Electrónico',
  registerBrand__form__selector__option6: 'Deporte',
  registerBrand__form__selector__option7: 'Automotriz',
  registerBrand__form__selector__option8: 'Alimenticio',
  registerBrand__form__selector__option9: 'Otro',
  ////////////////////////////

  // CLIENTS ///////////////
  'clients_by-client-btn': 'Por cliente',
  'clients_by-points-sale-btn': 'Por punto de venta',
  'clients_add-client-link': '+ Agregar cliente',
  'clients_add-search-input': 'Buscar...',
  'clients_filters-btn': 'Filtros',
  // Tables
  'clients_clients-table_businessName': 'Razón Social',
  'clients_clients-table_tradeName': 'Nombre Comercial',
  'clients_clients-table_clientCode': 'Código de Cliente',
  'clients_clients-table_city': 'Ciudad',
  'clients_clients-table_totalPointsSale': 'Total Puntos de Venta',
  'clients_clients-table_totalPointsSale_popover-title':
    'Hay puntos de venta con datos nuevos',
  'clients_clients-table_totalPointsSale_popover-btn': 'Ir a puntos de venta',
  'clients_clients-table_totalPointsSale_client-popover-title': 'ha actualizado datos',
  'clients_clients-table_totalPointsSale_client-popover-content':
    'dato agregado o actualizado',
  'clients_clients-table_totalPointsSale_client-popover-content-2':
    'datos agregados o actualizados',
  'clients_clients-table_totalPointsSale_client-popover-btn': 'Ir al cliente',
  'clients_client-table_status': 'Status',
  'clients_client-table_action_see-profile': 'Ver perfil',
  'clients_client-table_action_remove-popconfirm_title': 'Rechazar asociación',
  'clients_client-table_action_remove-popconfirm_description':
    '¿Estás seguro que quieres rechazar esta asociación?',
  'clients_client-table_action_remove-popconfirm_on-ok': 'Aceptar',
  'clients_client-table_action_remove-popconfirm_on-cancel': 'Cancelar',
  'clients_client-table_action_reject-popconfirm_title': 'Desvincular asociación',
  'clients_client-table_action_reject-popconfirm_description':
    '¿Estás seguro que quieres desvincular esta asociación?',
  'clients_client-table_action_reject-popconfirm_on-ok': 'Aceptar',
  'clients_client-table_action_reject-popconfirm_on-cancel': 'Cancelar',
  //^************************************************************************
  //^************************************************************************
  'clients_client-table_action_delete-popconfirm_title': 'Eliminar punto de venta',
  'clients_client-table_action_delete-popconfirm_description':
    '¿Estás seguro que quieres eliminar este punto de venta?',
  'clients_client-table_action_delete-popconfirm_icon-tooltip': 'Eliminar punto de venta',
  'clients_client-table_action_dissasociate-popconfirm_on-ok': 'Desvincular',
  //^************************************************************************
  //^************************************************************************
  'client_clients-page_table_disassociate-modal_title': 'Desvincular cliente',
  'client_clients-page_table_disassociate-modal_on-ok': 'Desvincular',
  'client_clients-page_table_disassociate-modal_on-cancel': 'Cancelar',
  'client_clients-page_table_disassociate-modal_content':
    '¿Está seguro que quiere desvincular este cliente y todos sus puntos de venta?',
  'clients_sale-point-table_businessName': 'Razón Social',
  'clients_sale-point-table_shop-name': 'Nombre de la tienda',
  'clients_sale-point-table_category': 'Categoría',
  'clients_sale-point-table_tradeName': 'Nombre comercial',
  'clients_sale-point-table_pointSaleCode': 'Código PDV',
  'clients_sale-point-table_city': 'Ciudad',
  'clients_sale-point-table_personae-and-tier': 'Personae & Tier',
  'clients_sale-point-table_share-data': 'Comparto datos',
  'clients_sale-point-table_share-data-popover-share':
    'Recibiendo datos de venta del cliente.',
  'clients_sale-point-table_share-data-popover-no-share':
    'No recibiendo datos de venta del cliente.',
  'clients_sale-point-table_recive-data': 'Recibo datos',
  'clients_sale-point-table_recive-data_green': 'Recibiendo datos de venta del cliente.',
  'clients_sale-point-table_recive-data_orange': 'El cliente no está compartiendo datos.',
  'clients_sale-point-table_recive-data_red': 'No se está recibiendo datos del meta.',
  'clients_sale-point-table_connection': 'Conexión',
  'clients_sale-point-table_connection-popover-share':
    'Conectado con el terminal de punto de venta del cliente.',
  'clients_sale-point-table_connection-popover-no-share':
    'No conectado con el terminal de punto de venta del cliente.',
  'clients_sale-point-table_active': 'Activo',
  'clients_sale-point-table_inactive': 'Inactivo',
  'clients_sale-point-table_connected': 'Conectado',
  'clients_sale-point-table_not-connected': 'No conectado',
  'clients_sale-point-table_total-clients': 'Total de clientes',
  'clients_sale-point-table_total-sale-points': 'Total de puntos de venta',
  // Association
  'clients_association-modal_title': 'Asociar cliente',
  'clients_association-modal_associate-label_associate_txt-1': 'Asociar:',
  'clients_association-modal_associate-label_associate_txt-2':
    'se crea la asociación y se mantienen los datos del punto de venta propio.',
  'clients_association-modal_associate-label_associate-update_txt-1':
    'Asociar y actualizar:',
  'clients_association-modal_associate-label_associate-update_txt-2':
    'se crea la asociación y se copian los datos del punto de venta a asociar.',
  'clients_association-modal_update-label_associate_txt-1': 'Actualizar:',
  'clients_association-modal_update-label_associate_txt-2':
    'se copian los datos del punto de venta con el punto de venta propio.',
  'clients_association-modal_update-label_associate-update_txt-1':
    'Actualizar y asociar:',
  'clients_association-modal_update-label_associate-update_txt-2':
    'se crea la asociación y se copian los datos del punto de venta a asociar.',
  'clients_pending-association-modal_title': 'Asociaciones pendientes',
  'clients_pending-association-modal_no-more': 'No volver a mostrar',
  'clients_pending-association-modal_close': 'Cerrar',
  'clients_association-modal_on-ok': 'Aceptar',
  'clients_association-modal_on-finish': 'Finalizar',
  'clients_association-modal_on-next': 'Siguiente',
  'clients_association-modal_on-cancel': 'Cancelar',
  'clients_association-modal_ps_pdv-code': 'Código PDV',
  'clients_association-modal_ps_associate': 'Asociar',
  'clients_association-modal_ps_update': 'Actualizar',
  'clients_association-modal_ps_empty-ps': 'No hay puntos de venta nuevos',
  'clients_association-table_item_client-code': 'Código Cliente',
  'clients_association-table_item_cif': 'Nº CIF',
  'clients_association-table_item_trade-name': 'Nombre Comercial',
  'clients_association-table_item_business-name': 'Razón Social',
  'clients_association-table_item_address': 'Dirección',
  'clients_association-table_item_postal-code': 'Código Postal',
  'clients_association-table_item_city': 'Ciudad',
  'clients_association-table_item_province': 'Provincia',
  'clients_association-table_item_country': 'País',
  'clients_association-table_item_contact': 'Contacto',
  'clients_association-table_item_email': 'Correo Electrónico',
  'clients_association-table_item_phone-number': 'Teléfono',
  'clients_association-steps_step-1': 'Información General',
  'clients_association-steps_step-2': 'Puntos de Venta',
  // Add client form
  'clients_add-client-form-label_trade-name': 'Nombre comercial',
  'clients_add-client-form-label_business-name': 'Razón social',
  'clients_add-client-form-label_client-code': 'Código de cliente',
  'clients_add-client-form-label_clientCode': 'Código de cliente',
  'clients_add-client-form-label_country': 'País',
  'clients_add-client-form-label_population': 'Ciudad',
  'clients_add-client-form-label_province': 'Provincia',
  'clients_add-client-form-label_address': 'Dirección',
  'clients_add-client-form-label_postal-code': 'Código postal',
  'clients_add-client-form-label_cif': 'Nº CIF',
  'clients_add-client-form-label_phone-number': 'Teléfono',
  'clients_add-client-form-label_email': 'Email',
  'clients_add-client-form-input-validation_trade-name':
    'Por favor, introduce el nombre comercial',
  'clients_add-client-form-input-validation_business-name':
    'Por favor, introduce la razón social',
  'clients_add-client-form-input-validation_country': 'Por favor, introduce el país',
  'clients_add-client-form-input-validation_population':
    'Por favor, introduce la población',
  'clients_add-client-form-input-validation_address': 'Por favor, introduce la dirección',
  'clients_add-client-form-input-validation_postal-code':
    'Por favor, introduce el código postal',
  'clients_add-client-form-input-validation_cif': 'Por favor, introduce el Nº CIF',
  'clients_add-client-form-input-validation_email-required':
    'Por favor, introduce el email',
  'clients_add-client-form-input-validation_email': 'El email no es válido',
  'clients_add-client-form-input-validation_phone-number':
    'Por favor, introduce el teléfono',
  // Add client
  'clients_add-client_add-manually': 'Agregar cliente manualmente',
  'clients_add-client_add-by-csv-1': 'Subir CSV',
  'clients_add-client_add-by-csv-2': 'clientes',
  'clients_add-client_add-by-excel': 'Descargar plantilla excel',
  // Client menu
  'clients_menu-desktop_resume': 'Resumen',
  'clients_menu-desktop_business-info': 'Datos de la empresa',
  'clients_menu-desktop_main-contacts': 'Contactos principales',
  'clients_menu-desktop_sale-points': 'Puntos de venta',
  'clients_menu-desktop_commercial-info': 'Información comercial',
  // Client resume
  client_resume_title: 'Catálogo disponible',
  'client_resume_main-info-panel_title': 'Información general',
  'client_resume_main-info-panel_li-info_client-code': 'Código cliente:',
  'client_resume_main-info-panel_li-info_trade-name': 'Nombre comercial:',
  'client_resume_main-info-panel_li-info_web-page': 'Página Web:',
  'client_resume_main-info-panel_li-info_points-of-sale': 'Puntos de venta:',
  'client_resume_main-info-panel_li-info_email': 'E-mail:',
  'client_resume_main-info-panel_li-info_category': 'Categoría:',
  'client_resume_main-info-panel_li-info_start-date': 'Fecha de alta:',
  'client_resume_main-info-panel_li-info_start-season': 'Temporada de inicio:',
  'client_resume_main-info-panel_li-info_phone-number': 'Teléfono:',
  'client_resume_main-info-panel_li-info_main-contact': 'Contacto principal:',
  'client_resume_main-info-panel_li-info_contact-email': 'E-mail de contacto:',
  // Business Info
  'client_business-info_edit-button': 'Editar',
  'client_business-info_name': 'Nombre comercial',
  'client_business-info_legal_name': 'Razón social',
  'client_business-info_cif': 'CIF',
  'client_business-info_address': 'Dirección',
  'client_business-info_postal_code': 'Código postal',
  'client_business-info_city': 'Ciudad',
  'client_business-info_country': 'País',
  'client_business-info_province': 'Provincia',
  'client_business-info_email': 'Correo electrónico',
  'client_business-info_contact_name': 'Contacto Principal',
  'client_business-info_phoneNumber': 'Teléfono',
  'client_business-info_clientCode': 'Código de cliente',
  'client_business-info_web': 'Web',
  'client_business-info_floor': 'Piso',
  'client_business-info_door': 'Puerta',
  'client_business-info_upgrade_all-popover_title': 'Actualizar nuevos datos',
  'client_business-info_upgrade_popover_update': 'Actualizar',
  'client_business-info_upgrade_popover_update-all': 'Actualizar todo',
  'client_business-info_upgrade_update-field': 'campo por actualizar',
  'client_business-info_upgrade_update-fields': 'campos por actualizar',
  'client_business-info_upgrade_client-updates': 'ha cambiado',
  'client_business-info_upgrade_new-value': 'Nuevo',
  'client_business-info_upgrade_new-actual': 'Actual',
  // Client Contacts
  'client_business-client-contacts_title': 'Contactos principales',
  'client_business-client-contacts_add-contact': '+ Agregar contacto',
  'client_business-client-contacts_modal_title': 'Eliminar contacto',
  'client_business-client-contacts_modal_text':
    '¿Quiere eliminar el contacto de la lista? Esta acción no elimina de tus contactos.',
  'client_business-client-contacts_modal_ok-text': 'Eliminar',
  'client_business-client-contacts_modal_cancel-text': 'Cancelar',
  //////////////////////////

  // CLIENT ///////////////
  // Resume page
  'client_resume-page_history': 'Historial',
  'client_resume-page_contacts': 'Contactos',
  'client_resume-page_accept-association': 'Asociar',
  'client_resume-page_reject-association': 'Rechazar',
  'client_resume-page_accept-association_btn-title': 'Aceptar asociación',
  'client_resume-page_reject-association_btn-title': 'Rechazar asociación',
  'client_resume-page_client-brand-works_title': 'Marcas con las que trabaja',
  'client-page_history-points-of-sale_associated': 'Asociado',
  'client-page_history-points-of-sale_not-associated': 'No asociado',
  'client-page_history-active-contacts_name': 'Nombre',
  'client-page_history-active-contacts_email': 'Email',
  'client-page_history-active-contacts_phone-number': 'Nº Teléfono',
  'client-page_history-active-contacts_department': 'Departamento',
  // Commercial info page
  'client_commercial-info_title': 'Información comercial',
  'client_commercial-info_add-agent': '+ Agregar agente',
  'client_commercial-info_table_agent-name': 'Agente comercial / Distribuidor',
  'client_commercial-info_table_division': 'División',
  'client_commercial-info_table_buy-group': 'Grupo de compra',
  'client_commercial-info_table_buy-group-true': 'Sí',
  'client_commercial-info_table_buy-group-false': 'No',
  // Sale points page
  'client_sale-points-page_title': 'Puntos de venta',
  'client_sale-points-page_add-sale-point': '+ Agregar',
  'client_sale-points-page_associate-sale-point': 'Asociar punto de venta',
  'client_sale-points-page_table_client-code': 'Código',
  'client_sale-points-page_table_address': 'Dirección',
  'client_sale-points-page_table_city': 'Ciudad',
  'client_sale-points-page_table_division': 'División',
  'client_sale-points-page_table_personae': 'Personae',
  'client_sale-points-page_table_tier': 'Tier',
  'client_sale-points-page_table_pdv': 'Fotos de PDV',
  'client_sale-points-page_table_name': 'Nombre',
  'client_sale-points-page_table_area': 'Superficie comercial (M2)',
  'client_sale-points-page_table_category': 'Categoría',
  'client_sale-points-page_table_brands': 'Marcas',
  'client_sale-points-page_table_disassociate-modal_title': 'Desvincular punto de venta',
  'client_sale-points-page_table_disassociate-modal_on-ok': 'Desvincular',
  'client_sale-points-page_table_disassociate-modal_on-cancel': 'Cancelar',
  'client_sale-points-page_table_disassociate-modal_content':
    '¿Está seguro que quiere desvincular este punto de venta?',
  'client_sale-points-page_table_associate-modal_title': 'Vincular puntos de venta',
  'client_sale-points-page_table_associate-modal_on-ok': 'Actualizar',
  'client_sale-points-page_table_associate-modal_on-cancel': 'Cancelar',
  'client_sale-points-page_table_status_associated': 'Asociado',
  'client_sale-points-page_table_status_matching': 'Pendiente',
  'points-of-sale_drawer_exchange_label': 'Tipo de moneda',
  'points-of-sale_drawer_add-edit-sale-point_exchange_input-validation':
    'Por favor, introduce el tipo de moneda',
  'client_sale-points-page_table_status_not-associated': 'No Asociado',
  'client_sale-points-page_upgrade_online': 'Tienda física',
  'client_sale-points-page_upgrade_category_id': 'Categoría',
  'client_sale-points-page_upgrade_name': 'Nombre de la tienda',
  'client_sale-points-page_upgrade_exchange_id': 'Tipo de moneda',
  'client_sale-points-page_upgrade_area': 'Superficie comercial',
  'client_sale-points-page_upgrade_address': 'Dirección',
  'client_sale-points-page_upgrade_floor': 'Piso',
  'client_sale-points-page_upgrade_door': 'Puerta',
  'client_sale-points-page_upgrade_city': 'Ciudad',
  'client_sale-points-page_upgrade_province': 'Provincia',
  'client_sale-points-page_upgrade_postal_code': 'Código postal',
  'client_sale-points-page_upgrade_country_id': 'País',
  'client_sale-points-page_upgrade_lat': 'Latitud',
  'client_sale-points-page_upgrade_lng': 'Longitud',
  'client_sale-points-page_upgrade_tel_prefix': 'Prefijo telefónico',
  'client_sale-points-page_upgrade_tel_sufix': 'Nº teléfono',
  'client_sale-points-page_upgrade_web': 'Web',
  'client_sale-points-page_upgrade_popconfirm-title':
    'Actualizar datos de los puntos de venta',
  'client_sale-points-page_upgrade_popconfirm-description':
    '¿Estás seguro de que deseas actualizar los datos de todos los puntos de venta?',
  'client_sale-points-page_upgrade_upgrade-btn': 'Actualizar puntos de venta',
  // Point of sale info modal
  'points-of-sale_point-of-sale-info_close-btn': 'Cerrar',
  // Info
  'points-of-sale_point-of-sale-info_info_title': 'Información',
  'points-of-sale_point-of-sale-info_info_code': 'Código',
  'points-of-sale_point-of-sale-info_info_store-type': 'Tipo de tienda',
  'points-of-sale_point-of-sale-info_info_store-type_online': 'Online',
  'points-of-sale_point-of-sale-info_info_store-type_physic': 'Física',
  'points-of-sale_point-of-sale-info_info_category': 'Categoría',
  'points-of-sale_point-of-sale-info_info_web': 'Página Web',
  'points-of-sale_point-of-sale-info_info_exchange-type': 'Tipo de moneda',
  'points-of-sale_point-of-sale-info_info_start-season': 'Temporada de inicio',
  'points-of-sale_point-of-sale-info_info_division': 'División',
  'points-of-sale_point-of-sale-info_info_segmentation': 'Personae',
  'points-of-sale_point-of-sale-info_info_tier': 'Tier',
  // Connection
  'points-of-sale_point-of-sale-info_connection_title': 'Conexión',
  'points-of-sale_point-of-sale-info_connection_no-associated':
    'No hay asociación con el punto de venta',
  'points-of-sale_point-of-sale-info_connection_meta-connection': 'Conexión con Meta',
  'points-of-sale_point-of-sale-info_connection_meta-connection_true': 'Conectado',
  'points-of-sale_point-of-sale-info_connection_meta-connection_false': 'No conectado',
  'points-of-sale_point-of-sale-info_connection_meta-receiving':
    'Recibiendo datos de Meta',
  'points-of-sale_point-of-sale-info_connection_meta-receiving_true': 'Recibiendo',
  'points-of-sale_point-of-sale-info_connection_meta-receiving_false':
    '24hrs sin recibir',
  'points-of-sale_point-of-sale-info_connection_data-share': 'El cliente comparte datos',
  'points-of-sale_point-of-sale-info_connection_data-share_true': 'Compartiendo',
  'points-of-sale_point-of-sale-info_connection_data-share_false': 'No comparte',
  // Contact
  'points-of-sale_point-of-sale-info_contact_title': 'Contacto',
  'points-of-sale_point-of-sale-info_contact_contact-name': 'Persona de contacto',
  'points-of-sale_point-of-sale-info_contact_phone-number': 'Teléfono de contacto',
  'points-of-sale_point-of-sale-info_contact_email': 'Email',
  'points-of-sale_point-of-sale-info_contact_address': 'Dirección',
  // Brand works
  'points-of-sale_point-of-sale-info_contact_brand-works_title_0': 'marcas en',
  'points-of-sale_point-of-sale-info_contact_brand-works_title_1': 'marca en',
  'points-of-sale_point-of-sale-info_contact_brand-works_empty':
    'Todavía no hay marcas en el punto de venta',
  // Drawers
  // Sale points
  'client_drawer_add-edit-sale-point_title': 'Datos del punto de venta',
  'client_drawer_add-edit-sale-point_name': 'Nombre de punto de venta',
  'client_drawer_add-edit-sale-point_point-of-sale-type': 'Tipo de punto de venta',
  'client_drawer_add-edit-sale-point_point-of-sale-type_physical': 'Tienda Física',
  'client_drawer_add-edit-sale-point_point-of-sale-type_online': 'Tienda Online',
  'client_drawer_add-edit-sale-point_category': 'Categoría',
  'client_drawer_add-edit-sale-point_client-code': 'Código de punto de venta',
  'client_drawer_add-edit-sale-point_client-area': 'Supeficie comercial (M2)',
  'client_drawer_add-edit-sale-point_address': 'Dirección',
  'client_drawer_add-edit-sale-point_postal-code': 'Código postal',
  'client_drawer_add-edit-sale-point_city': 'Ciudad',
  'client_drawer_add-edit-sale-point_country': 'País / Región',
  'client_drawer_add-edit-sale-point_province': 'Estado / Provincia',
  'client_drawer_add-edit-sale-point_name_input-validation':
    'Por favor, introduce el nombre del punto de venta',
  'client_drawer_add-edit-sale-point_category_input-validation':
    'Por favor, introduce la categoría del punto de venta',
  'client_drawer_add-edit-sale-point_client-code_input-validation':
    'Por favor, introduce el código de cliente',
  'client_drawer_add-edit-sale-point_address_input-validation':
    'Por favor, introduce la dirección',
  'client_drawer_add-edit-sale-point_postal-code_input-validation':
    'Por favor, introduce el código postal',
  'client_drawer_add-edit-sale-point_city_input-validation':
    'Por favor, introduce la ciudad',
  'client_drawer_add-edit-sale-point_country_input-validation':
    'Por favor, introduce el País / Región',
  'client_drawer_add-edit-sale-point_province_input-validation':
    'Por favor, introduce el Estado / Provincia',
  'client_drawer_add-edit-sale-point_division': 'División',
  'client_drawer_add-edit-sale-point_personae': 'Personae',
  'client_drawer_add-edit-sale-point_tier': 'Tier',
  'client_drawer_add-edit-sale-point_share-sale-point-title':
    'Clasificación punto de venta',
  'client_drawer_add-edit-sale-point_share-sale-point-data':
    '¿Deseas compartir datos con este punto de venta?',
  'client_drawer_add-edit-sale-point_share-sale-point-data-true': 'Sí',
  'client_drawer_add-edit-sale-point_share-sale-point-data-false': 'No',
  // Commercial info
  'client_drawer_client-commercial-info_title': 'Asignar agente',
  'client_drawer_client-commercial-info_select': 'Agentes',
  'client_drawer_client-commercial-info_select-placeholder': 'Selecciona agentes',
  'client_drawer_client-commercial-info_add-agent': '+ Agregar agente',
  // Client contacts
  'client_drawer_client-contacts_title': 'Contactos principales',
  // Edit client
  'client_drawer_edit-client_title': 'Datos de la Empresa',

  //********************************************************************** */
  //********************************************************************** */
  // META ///////////////////////////
  meta_connections_title: 'Conexiones',
  'meta_connections_new-connection': '+ Nueva',
  meta_connections_modal_title: 'Nueva conexión',
  'meta_connections_modal_form_user-name': 'Nombre de usuario',
  'meta_connections_modal_form_user-name_required':
    'Por favor ingrese el nombre de usuario',
  meta_connections_modal_form_password: 'Contraseña',
  meta_connections_modal_form_password_required: 'Por favor ingrese la contraseña',
  meta_connections_modal_form_period: 'Frecuencia',
  meta_table_createdAt: 'Fecha de creación',
  'meta_table_user-name': 'Usuario',
  meta_table_password: 'Contraseña',
  meta_table_type: 'Tipo',
  meta_table_period: 'Frecuencia de envío',
  'meta_table_connection-name': 'Nombre de la conexión',
  'meta_table_last-update': 'Última subida',
  'meta_table_last-connection': 'Última conexión',
  meta_table_status: 'Estado',
  'meta_connections_modal_popover-title': 'Información de conexión',
  'meta_connections_edit-info_title': 'Edita la información de la conexión',
  'meta_connections_edit-configuration_title': 'Edita la configuración de la conexión',
  'meta_connections_edit-map_title': 'Edita la información del mapa',
  'meta_connections_edit-info_btn-tooltip': 'Editar conexión',
  'meta_connections_remove-popover_title': 'Eliminar conexión con Shopify',
  'meta_connections_remove-popover_description':
    '¿Estás seguro de que deseas eliminar la conexión con Shopify?',
  'meta_connections_edit-connection-mapping_title':
    'Gestiona los mapas de datos a procesar',
  'meta_connections_edit-connection-mapping_select': 'Selecciona el tipo de mapa:',
  'meta_connections_edit-connection-mapping_select_catalog': 'Catálogo',
  'meta_connections_edit-connection-mapping_select_sale': 'Ventas',
  // Shopify form
  meta_connections_modal_form_shopify_domain: 'Dominio',
  'meta_connections_modal_form_shopify_domain-required':
    'El dominio de Shopify es requerido',
  'meta_connections_modal_form_shopify_access-token': 'Token de acceso',
  'meta_connections_modal_form_shopify_access-token-required':
    'El token de acceso de Shopify es requerido',
  'meta_connections_modal_form_shopify_access-api-secret-key': 'Clave secreta de API',
  'meta_connections_modal_form_shopify_access-api-secret-key-required':
    'La clave secreta de API de Shopify es requerida',
  'meta_connections_modal_form_shopify_steps_create-connection_title': 'Crea la conexión',
  'meta_connections_modal_form_shopify_steps_set-connection_title':
    'Configura la conexión',
  'meta_connections_modal_form_shopify_go-to-connections': ' Volver a conexiones',
  'meta_connections_modal_form_shopify_alert-success':
    ' Conexión con la API de Shopify establecida.',
  'meta_connections_modal_form_shopify_alert-error':
    'No hay conexión con la API de Shopify, comprueba los datos y vuelve a guardar la conexión.',
  // Prestashop form
  'meta_connections_modal_form_prestashop_store-url': 'URL de la tienda',
  'meta_connections_modal_form_prestashop_store-url-required':
    'La URL de la tienda de Shopify es requerida',
  meta_connections_modal_form_prestashop_version: 'Versión de Prestashop',
  'meta_connections_modal_form_prestashop_version-required':
    'La versión de Prestashop es requerida',
  // Odoo form
  'meta_connections_modal_form_odoo_instance-url': 'URL de instancia',
  'meta_connections_modal_form_odoo_instance-url-required':
    'La URL de instancia de Odoo es requerida',
  'meta_connections_modal_form_odoo_user-name': 'Nombre de usuario',
  'meta_connections_modal_form_odoo_user-name-required':
    'El nombre de usuario de Odoo es requerido',
  'meta_connections_modal_form_odoo_database-name': 'Nombre de la base de datos',
  'meta_connections_modal_form_odoo_database-name-required':
    'El nombre de la base de datos de Odoo es requerido',
  // Shared
  'meta_connections_api-connections_comming-soon': 'Próximamente...',
  'meta_connections_create-new-connection_connection-type': 'Tipo de conexión:',
  'meta_connections_form-items_tag-form-item_label': 'Nombre de la conexión',
  'meta_connections_form-items_tag-form-item_label-required':
    'Por favor, introduce un nombre',
  'meta_connections_modal_form_access-api-key': 'Clave de API',
  'meta_connections_modal_form_access-api-key-required':
    'La clave de API de Shopify es requerida',
  'meta-connections_mapping-form_periodicity': 'Periodicidad',
  'meta-connections_mapping-form_periodicity_required': 'La periodicidad es requerida',
  'meta-connections_mapping-form_periodicity_dairy': 'Diario',
  'meta-connections_mapping-form_periodicity_weekly': 'Semanal',
  'meta-connections_mapping-form_periodicity_biweekly': 'Quincenal',
  'meta-connections_modal_input_btn-reset-form': 'Restablecer',
  'meta-connections_mapping-table_column-3_label': 'Datos resultantes',
  'meta-connections_mapping-table_column-2_label': 'Columnas del CSV',
  'meta-connections_mapping-table_column-2_select-label': 'Selecciona una columna',
  'meta-connections_mapping-table_column-2_select-label-multiple':
    'Selecciona una o varias columnas',
  'meta-connections_mapping-table_column-2_select-format_label': 'Selecciona un formato',
  'meta-connections_delete-popconfirm_icon-tooltip': 'Eliminar conexión',
  meta_table_status_success: 'Activo',
  meta_table_status_error: 'Error',
  'meta_connections_shopify.configuration-form_items_template-label': 'Plantillas',
  'meta_connections_shopify.configuration-form_items_template-label-placeholder':
    'Seleccione una o varias plantilla',
  'meta_connections_shopify.configuration-form_items_queries-label': 'Consultas',
  'meta_connections_shopify.configuration-form_items_queries-check-all': 'Marcar todas',
  /////////////////////////////////////////////////////
  // MAPPING KEYS //////////////////////////////////////
  // Sales mapping keys
  'mapping-keys-field__multibrand': 'Multimarca',
  'mapping-keys-field__multibrand_point_of_sale': 'Punto de venta',
  'mapping-keys-field__point_of_sale_id': 'ID de punto de venta',
  'mapping-keys-field__client_id': 'ID de multimarca',
  'mapping-keys-field__date': 'Fecha',
  'mapping-keys-field__hour': 'Hora',
  'mapping-keys-field__floor': 'Planta',
  'mapping-keys-field__caja': 'Caja',
  'mapping-keys-field__order_number': 'Nº ticket',
  'mapping-keys-field__order_line': 'Linea ticket',
  'mapping-keys-field__product_reference': 'Referencia Producto',
  'mapping-keys-field__product_description': 'Descripción Producto',
  'mapping-keys-field__brand_ean': 'Ean Marca',
  'mapping-keys-field__product_ean_code': 'Ean Multimarca',
  'mapping-keys-field__product_brand': 'Marca',
  'mapping-keys-field__color_code': 'Codigo Color',
  'mapping-keys-field__product_color': 'Nombre Color',
  'mapping-keys-field__product_size_eu': 'Talla',
  'mapping-keys-field__product_season': 'Temporada',
  'mapping-keys-field__division': 'División',
  'mapping-keys-field__family': 'Familia',
  'mapping-keys-field__subfamily': 'Sub Familia',
  'mapping-keys-field__gender': 'Género',
  'mapping-keys-field__units': 'Unidades',
  'mapping-keys-field__price': 'Precio Venta',
  'mapping-keys-field__cost': 'Precio Compra',
  'mapping-keys-field__discount': 'Dto Importe',
  'mapping-keys-field__discount_percentaje': 'Dto%',
  'mapping-keys-field__payment_method': 'Forma de Pago',
  'mapping-keys-field__seller': 'Vendedor',
  // Products mapping keys
  'mapping-keys-field__name': 'Nombre del producto',
  'mapping-keys-field__reference': 'Referencia',
  'mapping-keys-field__color': 'Color',
  'mapping-keys-field__season': 'Temporada',
  'mapping-keys-field__ean': 'EAN',
  'mapping-keys-field__material': 'Composición',
  'mapping-keys-field__segmentation': 'Segmentación',
  'mapping-keys-field__tier': 'Segmentación Catálogo (Tier)',
  'mapping-keys-field__color_description': 'Descripción del color',
  'mapping-keys-field__pvi': 'PVI',
  'mapping-keys-field__pvpr': 'PVPR',
  'mapping-keys-field__size': 'Talla',
  'mapping-keys-field__tags': 'Etiquetas',
  //////////////////////////////////////////////////////
  //********************************************************************** */
  //********************************************************************** */

  // ACTIONS ////////////////
  // Clients
  'clients_actions_add-client-success': 'Cliente creado correctamente',
  'clients_actions_update-client-success': 'Cliente actualizado correctamente',
  'clients_actions_update-client-error': 'Error al actualizar el cliente',
  'clients_actions_client-exists': 'El cliente ya existe',
  'clients_actions_add-point-of-sale-success': 'Punto de venta creado correctamente',
  'clients_actions_add-point-of-sale-error': 'Error al crear el punto de venta',
  'clients_actions_update-point-of-sale-success':
    'Punto de venta actualizado correctamente',
  'clients_actions_upgrade-point-of-sale-success':
    'punto de venta actualizado correctamente',
  'clients_actions_upgrade-points-of-sale-success':
    'puntos de venta actualizados correctamente',
  'clients_actions_update-point-of-sale-error': 'Error al actualizar el punto de venta',
  //************************************************************************ */
  //************************************************************************ */
  'clients_actions_remove-point-of-sale-success':
    'Punto de venta eliminado correctamente',
  'clients_actions_remove-point-of-sale-error': 'Error al eliminar el punto de venta',
  //************************************************************************ */
  //************************************************************************ */
  'clients_actions_share-data-point-of-sale-success':
  'Compartiendo datos con el punto de venta',
  'clients_actions_dont-share-data-point-of-sale-success':
    'Has dejado de compartir datos con el punto de venta',
  'clients_actions_association-success': 'Cliente asociado correctamente',
  'clients_actions_association-error': 'Error al asociar el cliente',
  'clients_actions_reject-association-success': 'Se ha desvinculado correctamente',
  'clients_actions_reject-association-error': 'Error al desvincular el cliente',
  'clients_actions_accept-ps-association-success':
    'Se ha vinculado correctamente el punto de venta',
  'clients_actions_accept-ps-association-error': 'Error al vincular el punto de venta',
  // Commercial Profile
  'commercial-profile_actions_init-bio_error': 'Error al obtener la bio',
  'commercial-profile_actions_update-bio_success': 'Bio actualizada correctamente',
  'commercial-profile_actions_update-bio_error': 'Error al actualizar la bio',
  // Catalog Correlation
  'catalog-correlation_assign-generics_success': 'Genéricos asignados correctamente',
  'catalog-correlation_assign-generics_error': 'Error al asignar los genéricos',
  // Catalog CSV upload
  'catalog-csv-upload_publish-upload-success-1': 'Catálogo publicado correctamente',
  'catalog-csv-upload_publish-upload-success-2': 'Catálogo ocultado correctamente',
  'catalog-csv-upload_publish-upload-error-1': 'Error al publicar el catálogo',
  'catalog-csv-upload_publish-upload-error-2': 'Error al ocultar el catálogo',
  'catalog-csv-upload_reject-upload-success': 'El catálogo se está eliminando, en unos minutos se reflejará en la plataforma',
  'catalog-csv-upload_reject-upload-error': 'Error al eliminar el catálogo',
  'catalog-csv-upload_set-start-season-date-success':
    'Fecha de inicio de temporada actualizada correctamente',
  'catalog-csv-upload_set-start-season-date-error':
    'Error al actualizar la fecha de inicio de temporada',
  'catalog-csv-upload_set-end-season-date-success':
    'Fecha de fin de temporada actualizada correctamente',
  'catalog-csv-upload_set-end-season-date-error':
    'Error al actualizar la fecha de fin de temporada',
  'catalog-csv-upload_enable-season-success-1': 'Temporada habilitada correctamente',
  'catalog-csv-upload_enable-season-success-2': 'Temporada deshabilitada correctamente',
  'catalog-csv-upload_enable-season-error-1': 'Error al habilitar la temporada',
  'catalog-csv-upload_enable-season-error-2': 'Error al deshabilitar la temporada',
  'catalog-csv-upload_get-mappings-error-1': 'Error al obtener las claves del mapa',
  'catalog-csv-upload_get-mappings-error-2':
    'Error del servidor al obtener las claves del mapa',
  'catalog-csv-upload_delete-mapping-success': 'Mapa eliminado correctamente',
  'catalog-csv-upload_delete-mapping-error': 'Error al eliminar el mapa',
  'catalog-csv-upload_create-mapping-success': 'Mapa creado correctamente',
  'catalog-csv-upload_create-mapping-error': 'Error al crear el mapa',
  // Commercial profile
  'commercial-proile_actions_set-banner-action-success':
    'Banner actualizado correctamente',
  'commercial-proile_actions_set-banner-action-error': 'Error al actualizar el banner',
  'commercial-proile_actions_set-banner-action-server-error':
    'Error del servidor al actualizar el banner',
  'commercial-proile_actions_set-products-action-success':
    'Productos actualizados correctamente',
  'commercial-proile_actions_set-products-action-error':
    'Error al actualizar los productos',
  'commercial-proile_actions_set-products-action-server-error':
    'Error del servidor al actualizar los productos',
  'commercial-proile_actions_remove-product-action-error':
    'Error al eliminar el producto',
  'commercial-proile_actions_remove-product-action-server-error':
    'Error del servidor al eliminar el producto',
  'commercial-proile_actions_set-videos-action-success':
    'Videos actualizados correctamente',
  'commercial-proile_actions_set-videos-action-error': 'Error al actualizar los videos',
  'commercial-proile_actions_set-videos-action-server-error':
    'Error del servidor al actualizar los videos',
  'commercial-proile_actions_remove-video-action-error': 'Error al eliminar el video',
  'commercial-proile_actions_remove-video-action-server-error':
    'Error del servidor al eliminar el video',
  ///////////////////////////

  // SETTINGS PAGE ////////
  'settings-page_nav_chronos': 'Chronos',
  'settings-page_nav_devices': 'Dispositivos',
  'settings-page_nav_touch-screen': 'Pantallas Táctiles',
  'settings-page_nav_users': 'Usuarios',
  'settings-page_nav_system-settings': 'Ajustes del Sistema',
  'settings-page_nav_commercial-profile': 'Perfil Comercial Marca',
  'settings-page_nav_billing': 'Facturación',
  'settings-page_nav_support': 'Soporte',
  // Users page
  'settings-page_users-page_create-user-btn': 'Crear usuario',
  //****************************************************************************** */
  //****************************************************************************** */
  // Meta
  'action_meta_get-connections_error': 'Error al obtener las conexiones',
  'action_meta_delete-connection_success': 'Conexión eliminada correctamente',
  'action_meta_delete-connection_error': 'Error al eliminar la conexión',
  'action_meta_create-connection_success': 'Conexión creada correctamente',
  'action_meta_get-queries_error': 'Error al obtener las consultas',
  'action_meta_create-sftp-connection_error-403':
    'El nombre de usuario ya existe. Prueba con otro.',
  'action_meta_get-mapping-keys_error': 'Error al obtener las claves del mapa',
  'action_meta_create-shopify-connection_error-409': 'El dominio ya está en uso',
  'action_meta_create-shopify-connection_error': 'Error al crear la conexión',
  'action_meta_update-connection_success': 'Conexión actualizada correctamente',
  'action_meta_update-connection_error': 'Error al actualizar la conexión',
  'action_meta_create-connection_error': 'Error al crear la conexión',
  'settings-page_nav_meta': 'Meta',
  //****************************************************************************** */
  //****************************************************************************** */
  /////////////////////////

  // COMMERCIAL PROFILE ////////////
  'commercial-profile_title': 'Perfil Comercial',
  'commercial-profile_btn-edit': 'Editar',
  'commercial-profile_save-btn': 'Guardar',
  'commercial-profile_banner_title': 'Subir Banner',
  'commercial-profile_banner_p-1':
    'El Banner aparecerá en el Perfil Comercial de tu marca para promocionarla y conectarte con los multimarcas.',
  'commercial-profile_banner_p-2': 'Sube un Banner de 1800x200 px.',
  'commercial-profile_banner_p-3':
    'Procura que sea de buena calidad y atractivo para atraer Multimarcas.',
  'commercial-profile_about-us_title': 'Acerca de nosotros',
  'commercial-profile_about-us_p-1':
    'Cuenta un poco sobre tu marca para que atraiga a las multimarcas. Hasta un máximo de 100 palabras.',
  'commercial-profile_about-us_p-2':
    'La descripción aparecerá en el Perfil Comercial de tu marca para promocionarla y conectarte con los multimarcas.',
  'commercial-profile_our-products_title': 'Nuestros productos',
  'commercial-profile_our-products_p':
    'Selecciona 5-10 productos de tu última temporada.',
  'commercial-profile_our-videos_title': 'Algunos de nuestros vídeos...',
  'commercial-profile_our-videos_p': 'Selecciona 4 productos de tu última temporada.',
  'commercial-profile_banner_modal_title': 'Seleccionar Banner',
  'commercial-profile_modal_onOk': 'Aceptar',
  'commercial-profile_modal_onCancel': 'Cancelar',
  'commercial-profile_banner-title': 'Selecciona un baner para tu perfil comercial',
  'commercial-profile_modal-content_banners-empty-msg': 'Todavía no hay banners.',
  'commercial-profile_modal-content_navigate-banners-btn': 'Ir a multimedia banners',
  'commercial-profile_modal-content_videos-empty-msg': 'Todavía no hay videos.',
  'commercial-profile_modal-content_navigate-videos-btn': 'Ir a multimedia videos',
  'commercial-profile_our-products_modal_title': 'Selecciona productos',
  'commercial-profile_our-products_modal_max-items-msg':
    'Solo puedes seleccionar 10 productos.',
  'commercial-profile_our-products_modal_see-more-btn': 'Ver más',
  'commercial-profile_our-videos_modal_title': 'Selecciona videos',
  'commercial-profile_our-videos_modal_max-items-msg':
    'Solo puedes seleccionar 4 videos.',
  'commercial-profile_search-input-placeholder': 'Buscar por el nombre o código',
  //////////////////////////////////

  // NOTIFICATIONS /////////////////
  // Notifications Page
  'notifications-page_notifications-title': 'Notificaciones',
  'notifications-page_alerts-title': 'Alertas',
  'notifications_btn-name_catalog': 'Ver Catálogo',
  'notifications_btn-name_profile': 'Ver Perfil',
  'notifications_list_show-all': 'Todo',
  'notifications_list_show-unreaded': 'No leído',
  'notifications_list_per-page-select': 'por página',
  // Popover
  notifications_popover_title: 'NOTIFICACIONES',
  'notifications_popover_empty-notifications': 'No hay notificaciones',
  'notifications_popover_btn-name_catalog': 'Ver Catálogo',
  'notifications_popover_btn-name_profile': 'Ver Perfil',
  'notifications_popover_item_mark-as-readed_msg': 'Marcado como leído',
  'notifications_popover_item_mark-as-unreaded_msg': 'Marcado como no leído',
  'notifications_popover_item_mark-as-readed': 'Marcar como leído',
  'notifications_popover_item_mark-as-unreaded': 'Marcar como no leído',
  'notifications_popover_all-notifications-btn': 'Todas las notificaciones',
  notifications_popover_accept: 'Aceptar',
  notifications_popover_reject: 'Rechazar',
  notifications_popover_pending: 'Pending',
  alerts_popover_title: 'ALERTAS',
  'alerts_popover_empty-notifications': 'No hay alertas',
  'alerts_popover_all-notifications-btn': 'Todas las alertas',
  //////////////////////////////////

  // WHOLESALE FEED /////////////////
  'wholesale-feed_info_title': 'Información General',
  'wholesale-feed_info_city': 'Ciudad',
  'wholesale-feed_info_province': 'Provincia',
  'wholesale-feed_main-contact_title': 'Contacto Principal',
  'wholesale-feed_main-contact_name': 'Nombre',
  'wholesale-feed_main-contact_email': 'Email',
  'wholesale-feed_main-contact_phone-number': 'Teléfono',
  'wholesale-feed_main-contact_sector': 'Sector',
  'wholesale-feed_points-of-sale_title': 'Puntos de Venta',
  'wholesale-feed_working-brands_title': 'Marcas con las que trabaja',
  ///////////////////////////////////

  // MESSAGE PAGES //////////
  // Error
  'errors-page_404_subtitle': 'Lo sentimos, la página que estás buscando no existe.',
  'errors-page_404_btn-go-back': 'Volver',
  // Coming soon
  'coming-soon-page_title': '¡Próximamente!',
  'coming-soon-page_go-back': 'Volver',
  /////////////////////////

  // BREADCRUMBS ///////////////
  breadcrumbs_multimedia: 'Multimedia',
  'breadcrumbs_multimedia-videos': 'Videos',
  'breadcrumbs_multimedia-images': 'Imágenes',
  catalog_breadcrumb_item1: 'Catálogo',
  catalog_breadcrumb_item2: 'Ver Catálogo',
  'breadcrumbs_multimedia-banners': 'Banners',
  'catalog-uploads_breadcrumb': 'Subida de catálogos',
  'catalog-uploads_handle-images_breadcrumb': 'Gestión de imágenes',
  'breadcrumb_catalog-download': 'Descargar catálogo',
  'catalog-uploads-preview_breadcrumb': 'Vista previa',
  'add-catalog_breadcrumb': 'Agregar Catálogo',
  catalog_correlation: 'Correlación de referencias',
  catalog_generic: 'Genéricos H4R',
  // Clients
  breadcrumbs_clients: 'Clientes',
  'breadcrumbs_clients-list': 'Listado clientes',
  'breadcrumbs_add-client': 'Añadir cliente',
  breadcrumbs_resume: 'Resumen',
  'breadcrumbs_business-info': 'Datos de la empresa',
  'breadcrumbs_main-contacts': 'Contactos principales',
  'breadcrumbs_sale-points': 'Puntos de venta',
  'breadcrumbs_commercial-info': 'Información comercial',
  // Account
  breadcrumbs_account: 'Mi Cuenta',
  breadcrumbs_account_profile: 'Perfil',
  breadcrumbs_account_company: 'Datos de empresa',
  breadcrumbs_account_contacts: 'Contactos Principales',
  'breadcrumbs_account_commercial-info': 'Información Comercial',
  // Settings
  breadcrumbs_settings: 'Ajustes',
  'breadcrumbs_commercial-profile': 'Perfil Comercial',
  //****************************************************************** */
  //****************************************************************** */
  // Meta
  breadcrumbs_meta_edit_connetion: 'Editar conexión',
  breadcrumbs_meta_map_list: 'Mapas',
  breadcrumbs_meta_edit_map: 'Editar mapa',
  //****************************************************************** */
  //****************************************************************** */
  //////////////////////////////

  // EMAIL VERIFIED MODAL ///////
  'modal_email-verified_title': 'Revisa tu correo electrónico',
  'modal_email-verified_content':
    'Para continuar con el proceso, necesitamos que nos confirmes la dirección de correo electrónico',
  'modal_email-verified_content-1':
    'Verifica la dirección desde el enlace del correo recibido y a continuación pulsa',
  'modal_email-verified_content_btn_resend': 'Reenviar verificación',
  'modal_email-verified_content_btn_reload': 'Recargar',
  'modal_email-verified_content_notif_resend_title': 'Notificación de envío',
  'modal_email-verified_content_notif_resend_content-1':
    'Se ha enviado el email de verificación a esta dirección',
  'modal_email-verified_content_notif_resend_content-2':
    'Revisa el correo para continuar.',
  //////////////////////////////

  // POLICY PRIVACY MODAL //////////////////
  'policy-privacy-modal_title': 'Condiciones del servicio',
  'policy-privacy-modal_btn-accept': 'Aceptar',
  'policy-privacy-modal_cancel-btn': 'Cancelar',
  'policy-privacy-modal_checkbox': 'Acepto las condiciones del servicio',
  'policy-privacy-modal_checkbox-message':
    'Acepta la política de privacidad y condiciones.',
  ////////////////////////////

  // SERVER DOWN MODAL /////
  'server-down-modal_title': 'Error del servidor',
  'server-down-modal_content':
    'Estamos teniendo problemas con nuestros servicios, vuelva a intentarlo más tarde o póngase en contacto con el servicio técnico.',
  //////////////////////////

  // NOTIFICATIONS /////////
  'notification-error_message': 'Código de error',
  'notification-error_description':
    'Vuelve a intentarlo más tarde. Si el problema persiste, comuníquese con Hub4Retail y proporcione el código de error.',
  //////////////////////////

  // GOOGLE ADDRESS FORM //////
  'google-address-form_address': 'Dirección',
  'google-address-form_floor': 'Piso',
  'google-address-form_door': 'Puerta',
  'google-address-form_postal-code': 'Código Postal',
  'google-address-form_city': 'Población',
  'google-address-form_province': 'Provincia',
  'google-address-form_country': 'País',
  'google-address-form_select-country': 'Seleccione un país',
  /////////////////////////////

  // CSV MAPPING FIELDS //////
  'csv-mapping-feld__product_description': 'Descripción del producto',
  'csv-mapping-feld__reference': 'Referencia',
  'csv-mapping-feld__color': 'Color',
  'csv-mapping-feld__season': 'Temporada',
  'csv-mapping-feld__ean': 'EAN',
  'csv-mapping-feld__gender': 'Género',
  'csv-mapping-feld__material': 'Composición',
  'csv-mapping-feld__segmentation': 'Segmentación',
  'csv-mapping-feld__family': 'Familia',
  'csv-mapping-feld__division': 'División',
  'csv-mapping-feld__tier': 'Segmentación Catálogo (Tier)',
  'csv-mapping-feld__color_description': 'Descripción del color',
  'csv-mapping-feld__pvi': 'PVI',
  'csv-mapping-feld__pvpr': 'PVPR',
  'csv-mapping-feld__size': 'Talla',
  'csv-mapping-feld__tags': 'Etiquetas',
  /////////////////////////////

  // IDIOMAS /////////////////
  'spanish-lang': 'Español',
  'english-lang': 'Inglés',
  'french-lang': 'Francés',
  'italian-lang': 'Italiano',
  ////////////////////////////

  // SHARED //////////////////
  settings: 'Ajustes',
  logout: 'Cerrar sesión',
  'admin-panel_link': 'Panel de administrador',
  'see-more': 'Ver más',
  'see-less': 'Ver menos',
  'see-all': 'Ver todo',
  'save-btn': 'Guardar',
  'edit-btn-label': 'Editar',
  'delete-btn-label': 'Eliminar',
  'cancel-btn': 'Cancelar',
  'see-more-content-button': 'Ver más',
  'never-label': 'Nunca',
  'clean-form': 'Limpiar formulario',
  //******************************************************************************************* */
  //******************************************************************************************* */
  'reset-btn': 'Restablecer',
  'reset-next': 'Siguiente',
  'btn-next': 'Siguiente',
  'send-btn': 'Enviar',
  //******************************************************************************************* */
  //******************************************************************************************* */
  ////////////////////////////
};

export default spanishTranslation;

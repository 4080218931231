import * as MetaTypes from '../../meta.types';
import { IInitialMetaState } from 'interfaces/meta.interface';

export function handleShopify(state: IInitialMetaState, action: any): IInitialMetaState {
  const { type, payload } = action;

  switch (type) {
    case MetaTypes.SET_SHOPIFY_CONNECTIONS: {
      const setShopifyConnections: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: payload,
        },
      };
      return setShopifyConnections;
    }

    case MetaTypes.ADD_NEW_SHOPIFY_CONNECTION: {
      const addNewShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: [payload, ...state.shopify.connections],
          connectionSelected: payload,
        },
      };
      return addNewShopifyConnection;
    }

    case MetaTypes.UPDATE_SHOPIFY_CONNECTION: {
      const updateShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: state.shopify.connections.map((item) =>
            item.id === payload.id ? { ...item, ...payload.data } : item
          ),
          connectionSelected: { ...state.shopify.connectionSelected, ...payload.data },
        },
      };
      return updateShopifyConnection;
    }

    case MetaTypes.SET_IS_LOADING_UPDATE_SHOPIFY_CONNECTIONS: {
      const setIsLoadingUpdateShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingUpdateShopifyConnection: payload,
        },
      };
      return setIsLoadingUpdateShopifyConnection;
    }

    case MetaTypes.DELETE_SHOPIFY_CONNECTION: {
      const deleteShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          connections: state.shopify.connections.filter((item) => item.id !== payload),
        },
      };
      return deleteShopifyConnection;
    }

    case MetaTypes.SET_IS_LOADING_DELETE_SHOPIFY_CONNECTION: {
      const setIsLoadingRemoveShopifyConnection: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingRemoveShopifyConnection: {
            loading: payload.loading,
            connection_id: payload.id,
          },
        },
      };
      return setIsLoadingRemoveShopifyConnection;
    }

    // Configuration
    case MetaTypes.SET_CONFIGURATION: {
      const setConfiguration: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          configuration: payload,
        },
      };
      return setConfiguration;
    }

    case MetaTypes.SET_IS_LOADING_CONFIGURATION: {
      const setIsLoadingConfiguration: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingConfiguration: payload,
        },
      };
      return setIsLoadingConfiguration;
    }

    case MetaTypes.CREATE_UPDATE_CONFIGURATION: {
      const createUpdateConfiguration: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          configuration: payload,
        },
      };
      return createUpdateConfiguration;
    }

    case MetaTypes.SET_IS_LOADING_CREATE_UPDATE_CONFIGURATION: {
      const setIsLoadingCreateUpdateConfiguration: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingCreateUpdateConfiguration: payload,
        },
      };
      return setIsLoadingCreateUpdateConfiguration;
    }

    // Queries ///////////////////////////////////////////////////////
    case MetaTypes.SET_QUERIES: {
      const setQueries: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          queries: payload,
        },
      };
      return setQueries;
    }
    case MetaTypes.SET_IS_LOADING_QUERIES: {
      const setIsLoadingQueries: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingQueries: payload,
        },
      };
      return setIsLoadingQueries;
    }
    case MetaTypes.SET_QUERY_SELECTED: {
      const setQuerySelected: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          querySelected: payload,
        },
      };
      return setQuerySelected;
    }
    case MetaTypes.ADD_NEW_QUERY: {
      const addNewQuery: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          queries: [payload, ...state.shopify.queries],
        },
      };
      return addNewQuery;
    }
    case MetaTypes.SET_IS_LOADING_CREATE_QUERY: {
      const setIsLoadingCreateQuery: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingCreateQuery: payload,
        },
      };
      return setIsLoadingCreateQuery;
    }
    case MetaTypes.DELETE_QUERY: {
      const deleteQuery: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          queries: state.shopify.queries.filter((item) => item.id !== payload),
        },
      };
      return deleteQuery;
    }
    case MetaTypes.SET_IS_LOADING_DELETE_QUERY: {
      const setIsLoadingDeleteQuery: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingDeleteQuery: {
            loading: payload.loading,
            queryId: payload.queryId,
          },
        },
      };
      return setIsLoadingDeleteQuery;
    }
    case MetaTypes.UPDATE_QUERY: {
      const updateQuery: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          queries: state.shopify.queries.map((item) =>
            item.id === payload.id ? { ...item, ...payload.data } : item
          ),
        },
      };
      return updateQuery;
    }
    case MetaTypes.SET_IS_LOADING_UPDATE_QUERY: {
      const setIsLoadingUpdateQuery: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingUpdateQuery: payload,
        },
      };
      return setIsLoadingUpdateQuery;
    }

    // Templates /////////////////////////////////////////////////////
    case MetaTypes.SET_TEMPLATES: {
      const setTemplates: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          templates: payload,
        },
      };
      return setTemplates;
    }

    case MetaTypes.SET_IS_LOADING_TEMPLATES: {
      const setIsLoadingTemplates: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingTemplates: payload,
        },
      };
      return setIsLoadingTemplates;
    }

    case MetaTypes.SET_TEMPLATE_SELECTED: {
      const setTemplateSelected: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          templateSelected: payload,
        },
      };
      return setTemplateSelected;
    }

    case MetaTypes.CREATE_UPDATE_TEMPLATE: {
      const createUpdateTemplate: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          templates: !state.shopify.templates.find((item) => item.tag === payload.tag)
            ? [payload.data, ...state.shopify.templates]
            : state.shopify.templates.map((item) =>
                item.tag === payload.tag ? { ...item, ...payload.data } : item
              ),
        },
      };
      return createUpdateTemplate;
    }

    case MetaTypes.SET_IS_LOADING_CREATE_UPDATE_TEMPLATE: {
      const setIsLoadingCreateUpdateTemplate: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingCreateUpdateTemplate: payload,
        },
      };
      return setIsLoadingCreateUpdateTemplate;
    }

    case MetaTypes.DELETE_TEMPLATE: {
      const deleteTemplate: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          templates: state.shopify.templates.filter((item) => item.tag !== payload),
        },
      };
      return deleteTemplate;
    }

    case MetaTypes.SET_IS_LOADING_DELETE_TEMPLATE: {
      const setIsLoadingDeleteTemplate: IInitialMetaState = {
        ...state,
        shopify: {
          ...state.shopify,
          isLoadingDeleteTemplate: {
            loading: payload.loading,
            templateTag: payload.templateTag,
          },
        },
      };
      return setIsLoadingDeleteTemplate;
    }

    default:
      return state;
  }
}

import {
  TcreateMapBodyProps,
  TcreateSftpUserAPIBodyProps,
  TcreateShopifyConnectionAPIBodyProps,
  TupdateMapBodyProps,
} from 'api/responseInterfaces/meta.response.interface';
import {
  IcreateMapFieldProps,
  ICreateQueryProps,
  IcreateUpdateTemplateProps,
  IdeleteTemplateProps,
  IMapField,
  IMapFieldTemplates,
  IselectTemplateProps,
  IShopifyQueryItem,
  IShopifyTemplateItem,
  IupdateMapFieldBodyProps,
  IUpdateQueryProps,
} from './superAdmin.interface';
import { TupdateShopifyConnectionAPIBodyUpdateProps } from 'api/responseInterfaces/superAdmin.response.interface';
import { NavigateFunction } from 'react-router-dom';

export interface IMappingPointOfSale {
  name: string;
  id: string;
}

export type TConnectorTypes = 'sftp' | 'shopify' | 'prestashop' | 'odoo';
export const connectorTypes = {
  sftp: 'sftp',
  shopify: 'shopify',
  prestashop: 'prestashop',
  odoo: 'odoo',
};

export interface IInitialMetaState {
  getConnections: () => Promise<boolean | null>;
  isLoadingConnections: boolean;
  // Mappings
  mappings: Array<IMap>;
  getMaps: ({ brandId }: IgetMapsProps) => Promise<boolean>;
  isLoadingMaps: boolean;
  mapSelected: IMap | null;
  setMapSelected: (mapId: string | null) => void;
  createMap: ({
    connectorType,
    body,
    connection,
  }: ICreateMapProps) => Promise<string | null>;
  isLoadingCreateMap: boolean;
  deleteMap: ({ mapId }: { mapId: string }) => Promise<boolean>;
  isLoadingDeleteMap: {
    loading: boolean;
    mapId: string;
  };
  updateMap: ({ connectorType, body }: IUpdateMapProps) => Promise<boolean>;
  isLoadingUpdateMap: boolean;
  addMapToConnection: ({
    connectorType,
    mapIds,
    connectionId,
  }: IaddMapToConnectionProps) => Promise<boolean>;
  isLoadingAddMapToConnection: boolean;
  deleteMapFromConnection: ({
    connectorType,
    connectionId,
    mapIds,
  }: IdeleteMapFromConnectionProps) => Promise<boolean>;
  isLoadingDeleteMapFromConnection: boolean;
  addMapFieldsToMap: ({ mapId, mapFields }: IaddMapFieldsToMapProps) => Promise<boolean>;
  isLoadingAddMapFieldsToMap: boolean;
  // Map fields
  getMapFields: () => Promise<boolean>;
  isLoadingMapFields: boolean;
  mapFields: Array<IMapField>;
  createMapField: ({ fieldData }: IcreateMapFieldProps) => Promise<boolean>;
  isLoadingCreateMapField: boolean;
  updateMapField: (body: IupdateMapFieldBodyProps) => Promise<boolean>;
  isLoadingUpdateMapField: boolean;
  deleteMapField: ({ mapFieldId }: { mapFieldId: string }) => Promise<boolean>;
  isLoadingDeleteMapField: {
    loading: boolean;
    mapFieldId: string;
  };
  removeMapFieldFromMap: ({
    mapId,
    mapFieldId,
    deleteMapField,
  }: {
    mapId: string;
    mapFieldId: string;
    deleteMapField: boolean;
  }) => Promise<boolean>;
  // Map fields templates
  getMapFieldsTemplates: () => Promise<boolean>;
  isLoadingMapFieldsTemplates: boolean;
  mapFieldsTemplates: Array<IMapFieldTemplates>;
  deleteMapFieldTemplate: ({
    templateName,
  }: {
    templateName: string;
  }) => Promise<boolean>;
  isLoadingDeleteMapFieldTemplate: {
    loading: boolean;
    templateName: string;
  };
  copyMapFieldsTemplate: ({ templateTag }: { templateTag: string }) => Promise<boolean>;
  sftp: {
    connections: TSftpUserItem[];
    createSftpConnection: (body: TcreateSftpUserAPIBodyProps) => Promise<boolean>;
    isLoadingCreateSftpConnection: boolean;
    removeSftpConnection: (user_name: string) => void;
    isLoadingRemoveSftpConnection: {
      loading: boolean;
      user_name: string;
    };
  };
  shopify: {
    // Connections
    connections: Array<TShopifyConnectionItem>;
    connectionSelected: TShopifyConnectionItem;
    createShopifyConnection: ({
      body,
      navigate,
    }: {
      body: TcreateShopifyConnectionAPIBodyProps;
      navigate: NavigateFunction;
    }) => Promise<boolean>;
    isLoadingCreateShopifyConnection: boolean;
    updateShopifyConnection: ({
      body,
      connectionId,
    }: {
      body: TupdateShopifyConnectionAPIBodyUpdateProps;
      connectionId: string;
    }) => Promise<boolean>;
    isLoadingUpdateShopifyConnection: boolean;
    removeShopifyConnection: ({
      connectionId,
    }: {
      connectionId: string;
    }) => Promise<boolean>;
    isLoadingRemoveShopifyConnection: {
      loading: boolean;
      connection_id: string;
    };
    // Queries Shopify
    getShopifyQueries: () => Promise<boolean>;
    isLoadingQueries: boolean;
    queries: Array<IShopifyQueryItem>;
    selectShopifyQuery: (query: IShopifyQueryItem) => void;
    querySelected: IShopifyQueryItem;
    createShopifyQuery: ({
      tag,
      description,
      query,
      scope,
      indexable,
      audience,
    }: ICreateQueryProps) => Promise<boolean>;
    isLoadingCreateQuery: boolean;
    deleteShopifyQuery: ({ queryId }: { queryId: string }) => Promise<boolean>;
    isLoadingDeleteQuery: {
      loading: boolean;
      queryId: string;
    };
    updateShopifyQuery: ({
      queryId,
      query,
      tag,
      description,
      indexable,
      scope,
      audience,
      showSuccessMsg,
    }: IUpdateQueryProps & { showSuccessMsg?: boolean }) => Promise<boolean>;
    isLoadingUpdateQuery: boolean;
    // Templates
    getShopifyTemplates: () => Promise<boolean>;
    templates: Array<IShopifyTemplateItem>;
    isLoadingTemplates: boolean;
    selectShopifyTemplate: ({ template }: IselectTemplateProps) => void;
    templateSelected: IShopifyTemplateItem;
    createUpdateShopifyTemplate: ({
      tag,
      queries,
    }: IcreateUpdateTemplateProps) => Promise<boolean>;
    isLoadingCreateUpdateTemplate: boolean;
    deleteShopifyTemplate: ({ templateTag }: IdeleteTemplateProps) => Promise<boolean>;
    isLoadingDeleteTemplate: {
      loading: boolean;
      templateTag: string;
    };

    // Configuration
    configuration: IShopyfyConfigurationItem[];
    getShopifyConfiguration: ({
      connectorId,
    }: IgetConfigurationProps) => Promise<boolean>;
    isLoadingConfiguration: boolean;
    copyShopifyQueriesTemplate: ({
      template,
    }: IcreateUpdateConfigurationProps) => Promise<boolean>;
    isLoadingCreateUpdateConfiguration: boolean;
    addShopifyQueriesToConfiguration: ({
      connectorId,
      queries,
    }: IaddShopifyQueriesToConfiguration) => Promise<boolean>;
    isLoadingAddShopifyQueriesToConfiguration: boolean;
  };
}

// Props ///////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
export interface IgetMapsProps {
  brandId: string;
}
export interface ICreateMapProps {
  connectorType?: TConnectorTypes;
  body: TcreateMapBodyProps;
  connection?: TSftpUserItem | TShopifyConnectionItem;
}
export interface IUpdateMapProps {
  connectorType?: TConnectorTypes;
  body: TupdateMapBodyProps;
}
export interface IaddMapToConnectionProps {
  connectorType: TConnectorTypes;
  mapIds: string[];
  connectionId: string;
}
export interface IdeleteMapFromConnectionProps {
  connectorType: TConnectorTypes;
  connectionId: string;
  mapIds: string[];
}
export interface IaddShopifyQueriesToConfiguration {
  connectorId: string;
  queries: string[];
}

export interface IaddMapFieldsToMapProps {
  mapId: string;
  mapFields: IMapField[];
}
////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////

// SFTP ////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
export type TSftpUserItem = {
  client_id: string | null;
  brand_id: string;
  id: string;
  type?: TConnectorTypes;
  name: string;
  period: number;
  password: string;
  updatedAt: string;
  createdAt: string;
};

// SHOPIFY /////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////////
export type TShopifyConnectionItem = {
  id: string;
  client_id: null;
  brand_id: string;
  type: TConnectorTypes;
  tag: string;
  period: number;
  base_url: string;
  from_year: number;
  createdAt: string;
  updatedAt: string;
  api_key: string;
  api_secret_key: string;
  access_token: string;
  last_upload: string | null;
  last_connection: string | null;
  error: boolean;
  shopify_connector_maps: IMap[];
  shopify_connector_configurations: [
    {
      id: string;
      shopify_query: IShopifyQueryItem;
    },
  ];
};

// Shopify configuration ///////////////////////
export interface IShopyfyConfigurationItem {
  id: string;
  connector_id: string;
  query_id: string;
  createdAt: string;
  updatedAt: string;
}

export interface IgetConfigurationProps {
  connectorId: string;
}

export interface IcreateUpdateConfigurationProps {
  // connector_id: string;
  template: IShopifyTemplateItem;
}
////////////////////////////////////////////////

// Map //////////////////////////////////////////

export type TMappingTypes = 'catalog' | 'sales' | 'image' | 'orders' | 'budget';
export const mappingTypes = {
  catalog: 'catalog',
  sales: 'sales',
  image: 'image',
  orders: 'orders',
  budget: 'budget',
};

export type IMapFieldsTypes =
  | 'replace_key'
  | 'replace_value'
  | 'search'
  | 'substring'
  | 'pattern'
  | 'date'
  | 'loop'
  | 'delete'
  | 'clean';
export const mapFieldsTypes = {
  replace_key: 'replace_key',
  replace_value: 'replace_value',
  search: 'search',
  substring: 'substring',
  pattern: 'pattern',
  date: 'date',
  loop: 'loop',
  delete: 'delete',
  clean: 'clean',
};

export interface IMapFieldMap {
  id: string;
  map_id: string;
  map_field_id: string;
  createdAt: string;
  updatedAt: string;
  map_field: IMapField;
}

export interface IMap {
  id: string;
  tag: string;
  description: string | null;
  brand_id: string;
  client_id: string | null;
  user_id: string | null;
  period: number;
  origin: string;
  audience: string;
  signature: string | null;
  separator: string | null;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
  map_field_maps: IMapFieldMap[];
}

/* eslint-disable react-hooks/exhaustive-deps */
import { useReducer, useMemo, useCallback, memo } from 'react';
import MetaContext from './MetaContext';
import initialMetaState from './initialMetaState';
import metaReducer from 'contexts/meta/reducers/reducer/meta.reducer';
import * as action from 'contexts/meta/reducers/actions/meta.actions';
import * as mapAction from 'contexts/meta/reducers/actions/meta.map.actions';
import * as mapFieldAction from 'contexts/meta/reducers/actions/meta.mapFields.actions';
import * as sftpAction from 'contexts/meta/reducers/actions/meta.sftp.actions';
import * as shopifyAction from 'contexts/meta/reducers/actions/meta.shopify.actions';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import useContextUser from 'hooks/contexts/useContextUser';
import { ChildrenProps } from 'interfaces/general.interface';
import * as metaResponseInterface from 'api/responseInterfaces/meta.response.interface';
import * as metaInterface from 'interfaces/meta.interface';
import { TupdateShopifyConnectionAPIBodyUpdateProps } from 'api/responseInterfaces/superAdmin.response.interface';
import useContextSuperAdmin from 'hooks/contexts/useContextSuperAdmin';
import {
  IcreateMapFieldProps,
  IShopifyQueryItem,
  IShopifyTemplateItem,
  IupdateMapFieldBodyProps,
  IUpdateQueryProps,
} from 'interfaces/superAdmin.interface';
import { NavigateFunction } from 'react-router-dom';

function MetaProvider(props: ChildrenProps) {
  const [metaState, dispatch] = useReducer(metaReducer, initialMetaState);
  const { getAccessTokenSilently } = useAuth0();
  const { t: translate } = useTranslation();
  const { company } = useContextUser();
  const { shopify } = useContextSuperAdmin();

  const getConnections = useCallback(async () => {
    if (!company.brand.id) return false;
    const body: metaResponseInterface.TgetConnectorsAPIBodyProps = {
      brand_id: company.brand.id,
      search: [],
      index: 0,
      limit: 1000,
    };
    const token = await getAccessTokenSilently();
    return await action.getConnectionsAction({ dispatch, body, token, translate });
  }, [company.brand?.id, getAccessTokenSilently, translate]);

  // MAP //////////////////////////////////////////////////
  const getMaps = useCallback(async ({ brandId }: metaInterface.IgetMapsProps) => {
    const token = await getAccessTokenSilently();
    return await mapAction.getMapsAction({
      dispatch,
      brandId,
      token,
      translate,
    });
  }, []);

  const setMapSelected = useCallback((mapId: string | null) => {
    return mapAction.setMapSelectedAction({ dispatch, mapId });
  }, []);

  const createMap = useCallback(
    async ({ connectorType, body, connection }: metaInterface.ICreateMapProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.createMapAction({
        dispatch,
        connectorType,
        body,
        connection,
        token,
        translate,
      });
    },
    [translate]
  );

  const deleteMap = useCallback(
    async ({ mapId }: { mapId: string }) => {
      const token = await getAccessTokenSilently();
      return await mapAction.deleteMapAction({
        dispatch,
        mapId,
        token,
        translate,
      });
    },
    [getAccessTokenSilently, translate]
  );

  const updateMap = useCallback(
    async ({ connectorType, body }: metaInterface.IUpdateMapProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.updateMapAction({
        dispatch,
        connectorType,
        body,
        token,
        translate,
      });
    },
    [translate]
  );

  const addMapToConnection = useCallback(
    async ({
      connectionId,
      mapIds,
      connectorType,
    }: metaInterface.IaddMapToConnectionProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.addMapToConnectorAction({
        dispatch,
        companyId: company.brand.id,
        connectionId,
        mapIds,
        connectorType,
        token,
        translate,
      });
    },
    [getAccessTokenSilently, translate, company.brand?.id]
  );

  const deleteMapFromConnection = useCallback(
    async ({
      connectionId,
      mapIds,
      connectorType,
    }: metaInterface.IdeleteMapFromConnectionProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.deleteMapFromConnectorAction({
        dispatch,
        connectionId,
        mapIds,
        connectorType,
        token,
        translate,
      });
    },
    [getAccessTokenSilently, translate]
  );

  const addMapFieldsToMap = useCallback(
    async ({ mapId, mapFields }: metaInterface.IaddMapFieldsToMapProps) => {
      const token = await getAccessTokenSilently();
      return await mapAction.addMapFieldsToMapAction({
        dispatch,
        mapId,
        mapFields,
        mappings: metaState.mappings,
        token,
        translate,
      });
    },
    [getAccessTokenSilently, translate, metaState.mappings]
  );
  /////////////////////////////////////////////////////////

  // Map fields //////////////////////////////////////////////////////////////
  const getMapFields = useCallback(async () => {
    if (!company.brand.id) return false;
    const token = await getAccessTokenSilently();
    return await mapFieldAction.getMapFieldsAction({
      dispatch,
      token,
      translate,
     brandId: company.brand.id,
    });
  }, [getAccessTokenSilently, translate, company.brand?.id]);

  const createMapField = useCallback(
    async ({ fieldData, mapId }: IcreateMapFieldProps) => {
      const token = await getAccessTokenSilently();
      return await mapFieldAction.createMapFieldAction({
        dispatch,
        fieldData,
        mapId,
        mappings: metaState.mappings,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate, metaState.mappings]
  );

  const updateMapField = useCallback(
    async ({ filter, update }: IupdateMapFieldBodyProps) => {
      const token = await getAccessTokenSilently();
      return await mapFieldAction.updateMapFieldAction({
        dispatch,
        body: { filter, update },
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate]
  );

  const deleteMapField = useCallback(
    async ({ mapFieldId }: { mapFieldId: string }) => {
      const token = await getAccessTokenSilently();
      return await mapFieldAction.deleteMapFieldAction({
        dispatch,
        mapFieldId,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate]
  );

  const removeMapFieldFromMap = useCallback(
    async ({
      mapId,
      mapFieldId,
      deleteMapField,
    }: {
      mapId: string;
      mapFieldId: string;
      deleteMapField: boolean;
    }) => {
      const token = await getAccessTokenSilently();
      return await mapFieldAction.removeMapFieldFromMapAction({
        dispatch,
        mapId,
        mapFieldId,
        deleteMapField,
        mappings: metaState.mappings,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate, metaState.mappings]
  );

  // Map fields templates
  const getMapFieldsTemplates = useCallback(async () => {
    if (!company.brand.id) return false;
    const token = await getAccessTokenSilently();
    return await mapFieldAction.getMapFieldsTemplatesAction({
      dispatch,
      token,
      translate,
      brandId: company.brand.id,
    });
  }, [getAccessTokenSilently, translate, company.brand?.id]);

  const deleteMapFieldTemplate = useCallback(
    async ({ templateName }: { templateName: string }) => {
      if (!company.brand.id) return false;
      const token = await getAccessTokenSilently();
      return await mapFieldAction.deleteMapFieldTemplateAction({
        dispatch,
        templateName,
        token,
        translate,
        brandId: company.brand.id,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate, company.brand?.id]
  );

  const copyMapFieldsTemplate = useCallback(
    async ({ templateTag }: { templateTag: string }) => {
      if (!company.brand.id) return false;
      const token = await getAccessTokenSilently();
      return await mapFieldAction.copyMapFieldsTemplateAction({
        dispatch,
        templateTag,
        token,
        translate,
        brandId: company.brand.id,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [translate, company.brand?.id]
  );
  ////////////////////////////////////////////////////////////////////////////

  // SFTP /////////////////////////////////////////////////
  const createSftpConnection = useCallback(
    async (body: metaResponseInterface.TcreateSftpUserAPIBodyProps) => {
      const token = await getAccessTokenSilently();
      return (await sftpAction.createSftpConnectionAction(
        dispatch,
        body,
        token,
        translate
      )) as boolean;
    },
    [translate]
  );

  const removeSftpConnection = useCallback(
    async (user_name: string) => {
      const token = await getAccessTokenSilently();
      sftpAction.deleteSftpConnectionAction(dispatch, user_name, token, translate);
    },
    [translate]
  );
  /////////////////////////////////////////////////////////

  // Shopify //////////////////////////////////////////////
  const createShopifyConnection = useCallback(
    async ({
      body,
      navigate,
    }: {
      body: metaResponseInterface.TcreateShopifyConnectionAPIBodyProps;
      navigate: NavigateFunction;
    }) => {
      const token = await getAccessTokenSilently();
      return (await shopifyAction.createShopifyConnectionAction({
        dispatch,
        body,
        queries: metaState.shopify.queries,
        navigate,
        token,
        translate,
      })) as boolean;
    },
    [shopify.queries]
  );

  const updateShopifyConnection = useCallback(
    async ({
      body,
      connectionId,
    }: {
      body: TupdateShopifyConnectionAPIBodyUpdateProps;
      connectionId: string;
    }) => {
      const token = await getAccessTokenSilently();
      return (await shopifyAction.updateShopifyConnectionAction({
        dispatch,
        body,
        connectionId,
        token,
        translate,
      })) as boolean;
    },
    []
  );

  const removeShopifyConnection = useCallback(
    async ({ connectionId }: { connectionId: string }) => {
      const token = await getAccessTokenSilently();
      return (await shopifyAction.deleteShopifyConnectionAction({
        dispatch,
        connectionId,
        token,
        translate,
      })) as boolean;
    },
    []
  );

  // Configuration Shopify
  const getShopifyConfiguration = useCallback(
    async ({ connectorId }: metaInterface.IgetConfigurationProps) => {
      const token = await getAccessTokenSilently();
      return await shopifyAction.getShopifyConfigurationAction({
        dispatch,
        connectorId,
        token,
        translate,
      });
    },
    []
  );

  const copyShopifyQueriesTemplate = useCallback(
    async ({ template }: metaInterface.IcreateUpdateConfigurationProps) => {
      const token = await getAccessTokenSilently();
      return await shopifyAction.copyShopifyQueriesTemplateAction({
        dispatch,
        brandId: company.brand.id,
        template,
        token,
        translate,
      });
    },
    [company.brand?.id]
  );

  const addShopifyQueriesToConfiguration = useCallback(
    async ({ connectorId, queries }: metaInterface.IaddShopifyQueriesToConfiguration) => {
      const token = await getAccessTokenSilently();
      return await shopifyAction.addShopifyQueriesToConfigurationAction({
        dispatch,
        connectorId,
        queries,
        token,
        translate,
      });
    },
    []
  );

  // Queries Shopify /////////////////////////////////////////////////////////
  const getShopifyQueries = useCallback(async () => {
    if (!company.brand.id) return false;
    const token = await getAccessTokenSilently();
    return await shopifyAction.getShopifyQueriesAction(
      dispatch,
      company.brand.id,
      token,
      translate
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.brand?.id]);

  const selectShopifyQuery = useCallback((query: IShopifyQueryItem) => {
    return shopifyAction.selectShopifyQueryAction(dispatch, query);
  }, []);

  const deleteShopifyQuery = useCallback(
    async ({ queryId }: { queryId: string }) => {
      if (!company.brand.id) return false;
      const token = await getAccessTokenSilently();
      return await shopifyAction.deleteShopifyQueryAction({
        dispatch,
        brandId: company.brand.id,
        queryId,
        token,
        translate,
      });
    },
    [company.brand?.id, getAccessTokenSilently, translate]
  );

  const updateShopifyQuery = useCallback(
    async ({
      queryId,
      query,
      tag,
      description,
      indexable,
      scope,
      audience,
      showSuccessMsg,
    }: IUpdateQueryProps & { showSuccessMsg?: boolean }) => {
      const token = await getAccessTokenSilently();
      return await shopifyAction.updateShopifyQueryAction({
        dispatch,
        dataToUpdate: { queryId, query, tag, description, indexable, scope, audience },
        showSuccessMsg,
        token,
        translate,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // Templates Shopify
  const getShopifyTemplates = useCallback(async () => {
    const token = await getAccessTokenSilently();
    if (!company.brand.id) return false;
    return await shopifyAction.getShopifyTemplatesAction({
      dispatch,
      brandId: company.brand.id,
      token,
      translate,
    });
  }, [company.brand?.id, getAccessTokenSilently, translate]);

  const selectShopifyTemplate = useCallback(
    ({ template }: { template: IShopifyTemplateItem }) => {
      return shopifyAction.selectShopifyTemplateAction({ dispatch, template });
    },
    []
  );

  const deleteShopifyTemplate = useCallback(
    async ({ templateTag }: { templateTag: string }) => {
      if (!company.brand.id) return false;
      const token = await getAccessTokenSilently();
      return await shopifyAction.deleteShopifyTemplateAction({
        dispatch,
        brandId: company.brand.id,
        templateTag,
        token,
        translate,
      });
    },
    [company.brand?.id, getAccessTokenSilently, translate]
  );
  /////////////////////////////////////////////////////////

  const memoProvider = useMemo(
    () => ({
      ...metaState,
      getConnections,
      // Maps
      getMaps,
      setMapSelected,
      createMap,
      deleteMap,
      updateMap,
      addMapToConnection,
      deleteMapFromConnection,
      addMapFieldsToMap,
      // Map fields
      getMapFields,
      createMapField,
      updateMapField,
      deleteMapField,
      removeMapFieldFromMap,
      // Map fields templates
      getMapFieldsTemplates,
      deleteMapFieldTemplate,
      copyMapFieldsTemplate,
      sftp: {
        ...metaState.sftp,
        createSftpConnection,
        removeSftpConnection,
      },
      shopify: {
        ...metaState.shopify,
        createShopifyConnection,
        updateShopifyConnection,
        removeShopifyConnection,
        // Configuration
        getShopifyConfiguration,
        copyShopifyQueriesTemplate,
        addShopifyQueriesToConfiguration,
        // Queries
        getShopifyQueries,
        selectShopifyQuery,
        // createShopifyQuery,
        deleteShopifyQuery,
        updateShopifyQuery,
        // Templates
        getShopifyTemplates,
        selectShopifyTemplate,
        // createUpdateShopifyTemplate,
        deleteShopifyTemplate,
      },
    }),
    [
      metaState,
      getConnections,
      // Maps
      getMaps,
      setMapSelected,
      createMap,
      deleteMap,
      updateMap,
      addMapToConnection,
      deleteMapFromConnection,
      addMapFieldsToMap,
      // Map fields
      getMapFields,
      createMapField,
      updateMapField,
      deleteMapField,
      removeMapFieldFromMap,
      // Map fields templates
      getMapFieldsTemplates,
      deleteMapFieldTemplate,
      copyMapFieldsTemplate,
      // SFTP
      createSftpConnection,
      removeSftpConnection,
      // Shopify
      createShopifyConnection,
      updateShopifyConnection,
      removeShopifyConnection,
      // Configuration
      getShopifyConfiguration,
      copyShopifyQueriesTemplate,
      addShopifyQueriesToConfiguration,
      // Queries
      getShopifyQueries,
      selectShopifyQuery,
      // createShopifyQuery,
      deleteShopifyQuery,
      updateShopifyQuery,
      // Templates
      getShopifyTemplates,
      selectShopifyTemplate,
      // createUpdateShopifyTemplate,
      deleteShopifyTemplate,
    ]
  );

  return (
    <MetaContext.Provider value={memoProvider}>{props.children}</MetaContext.Provider>
  );
}

export default memo(MetaProvider);

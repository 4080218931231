export const userCompanyError = {
  login_user_001: 'UCE|LU-001',
  login_user_002: 'UCE|LU-002',
  login_user_003: 'UCE|LU-003',
  login_user_004: 'UCE|LU-004',
  login_user_005: 'UCE|LU-005',
  login_user_006: 'UCE|LU-006',
  update_user_001: 'UCE|UU-001',
  update_user_002: 'UCE|UU-002',
  update_user_avatar_001: 'UCE|UUA-001',
  delete_user_001: 'UCE|DU-001',
  update_company_001: 'UCE|UC-001',
  update_company_002: 'UCE|UC-002',
  update_company_003: 'UCE|UC-003',
  update_company_logo_001: 'UCE|UCL-001',
  update_language_001: 'UCE|UL-001',
  update_language_002: 'UCE|UL-002',
  updateSocialMedia_001: 'UCE|USM-001',
  updateSocialMedia_002: 'UCE|USM-002',
  removeSocialMedia_001: 'UCE|RSM-001',
  removeSocialMedia_002: 'UCE|RSM-002',
};

export const dashboardError = {
  get_dashboard_001: 'DE|GD-001',
};

export const mainContactError = {
  get_contacts_001: 'MCE|GC-001',
  select_contact_001: 'MCE|SC-001',
  create_contact_001: 'MCE|CC-001',
  create_contact_002: 'MCE|CC-002',
  update_contact_001: 'MCE|UC-001',
  update_contact_002: 'MCE|UC-002',
  remove_contact_001: 'MCE|RC-001',
  remove_contact_002: 'MCE|RC-002',
};

export const commercialInfoError = {
  get_commercial_info_001: 'CIE|GCI-001',
  create_commercial_info_001: 'CIE|CCI-001',
  create_commercial_info_002: 'CIE|CCI-002',
  select_commercial_info_001: 'CIE|SCI-001',
  update_commercial_info_001: 'CIE|UCI-001',
  update_commercial_info_002: 'CIE|UCI-002',
  remove_commercial_agent_001: 'CIE|RCA-001',
  remove_commercial_agent_002: 'CIE|RCA-002',
};

export const brandUsersError = {
  get_brand_users_001: 'BUE|GBU-001',
  select_brand_user_001: 'BUE|SBU-001',
  create_brand_user_001: 'BUE|CBU-001',
  create_brand_user_002: 'BUE|CBU-002',
  update_brand_user_001: 'BUE|UBU-001',
  update_brand_user_002: 'BUE|UBU-002',
  update_brand_user_003: 'BUE|UBU-003',
  update_brand_user_004: 'BUE|UBU-004',
  update_brand_user_005: 'BUE|UBU-005',
};

export const multimediaError = {
  get_multimedia_001: 'MME|GM-001',
  get_more_multimedia_001: 'MME|GMM-001',
  create_multimedia_001: 'MME|CIM-001',
  create_multimedia_002: 'MME|CIM-002',
  create_multimedia_003: 'MME|CIM-003',
  create_multimedia_004: 'MME|CIM-004',
  update_multimedia_001: 'MME|UIM-001',
  update_multimedia_002: 'MME|UIM-002',
  remove_multimedia_001: 'MME|RIM-001',
  remove_multimedia_002: 'MME|RIM-002',
  // select_multimedia_001: 'MME|RIM-001',
  search_multimedia_001: 'MME|SM-001',
};

export const catalogError = {
  get_catalog_001: 'CE|GC-001',
  add_more_products_to_catalog_001: 'CE|AMPTC-001',
  search_catalog_001: 'CE|SC-001',
  filter_catalog_001: 'CE|FC-001',
};

export const clientsError = {
  accept_association_001: 'CLE|AA-001',
  accept_association_002: 'CLE|AA-002',
  reject_association_001: 'CLE|RA-001',
  reject_association_002: 'CLE|RA-002',
  accept_ps_association_001: 'CLE|APSA-001',
  reject_ps_association_001: 'CLE|RPSA-001',
  reject_ps_association_002: 'CLE|RPSA-002',
  get_exchanges_001: 'CLE|GE-001',
  get_exchanges_002: 'CLE|GE-002',
  get_segmentations_001: 'CLE|GS-001',
  get_segmentations_002: 'CLE|GS-002',
};

export const commercialProfileError = {
  init_bio_001: 'CPE|IB-001',
  init_bio_002: 'CPE|IB-002',
  update_bio_001: 'CPE|UB-001',
  update_bio_002: 'CPE|UB-002',
};

export const catalogGenericsError = {
  get_generics_001: 'GE|GG-001',
  get_generics_002: 'GE|GG-002',
  add_generics_001: 'GE|AG-001',
  add_generics_002: 'GE|AG-002',
  update_generics_001: 'GE|UG-001',
  update_generics_002: 'GE|UG-002',
};

export const catalogCorrelationsError = {
  get_correlations_001: 'CE|GC-001',
  get_correlations_002: 'CE|AC-002',
  assign_generics_001: 'AG|AG-001',
  assign_generics_002: 'AG|AG-002',
};

export const catalogCSVUpload = {
  get_report_by_upload_001: 'CSV|GRBU-001',
  publish_upload_001: 'CSV|PU-001',
  reject_upload_001: 'CSV|RU-001',
  reject_upload_002: 'CSV|RU-002',
  get_reports_by_season_001: 'CSV|GRBS-001',
  set_start_season_date_001: 'CSV|SSSD-001',
  set_start_season_date_002: 'CSV|SSSD-002',
  set_end_season_date_001: 'CSV|SESD-001',
  set_end_season_date_002: 'CSV|SESD-002',
  enable_season_001: 'CSV|ES-001',
  enable_season_002: 'CSV|ES-002',
  get_mappings_001: 'CSV|GM-001',
  get_mappings_002: 'CSV|GM-002',
  delete_mapping_001: 'CSV|DM-001',
  delete_mapping_002: 'CSV|DM-002',
  create_mapping_001: 'CSV|CM-001',
  create_mapping_002: 'CSV|CM-002',
};

export const metaError = {
  // Connections
  get_connections_001: 'ME|GC-001',
  // Map fields
  get_map_fields_001: 'ME|GMF-001',
  create_map_field_001: 'ME|CMF-001',
  create_map_field_002: 'ME|CMF-002',
  update_map_field_001: 'ME|UMF-001',
  update_map_field_002: 'ME|UMF-002',
  add_field_to_map_001: 'ME|AFM-001',
  // create_map_field_003: 'ME|CMF-003',
  // create_map_field_004: 'ME|CMF-004',
  // create_map_field_005: 'ME|CMF-005',
  delete_map_field_001: 'ME|DMF-001',
  delete_map_field_002: 'ME|DMF-002',
  // // Map fields temp0lates
  get_map_fields_templates_001: 'ME|GMFT-001',
  // get_shopify_queries_001: 'ME|GSQ-001',
  // create_map_fields_template_001: 'ME|CMFT-001',
  // create_map_fields_template_002: 'ME|CMFT-002',
  delete_map_field_template_001: 'ME|DMFT-001',
  delete_map_field_template_002: 'ME|DMFT-002',
  copy_map_fields_template_001: 'ME|CMFT-001',
  copy_map_fields_template_002: 'ME|CMFT-002',

  // SFTP
  delete_sftp_connection_001: 'ME|DSC-001',
  delete_sftp_connection_002: 'ME|DSC-002',
  create_sftp_connection_001: 'ME|CSC-001',
  create_sftp_connection_002: 'ME|CSC-002',
  // Shopify
  create_shopify_connection_001: 'ME|CSHC-001',
  create_shopify_connection_002: 'ME|CSHC-002',
  create_shopify_connection_003: 'ME|CSHC-003',
  create_shopify_connection_004: 'ME|CSHC-004',
  update_shopify_connection_001: 'ME|USC-001',
  update_shopify_connection_002: 'ME|USC-002',
  delete_shopify_connection_001: 'ME|DSC-001',
  delete_shopify_connection_002: 'ME|DSC-002',
  // Configuration
  get_shopify_configuration_001: 'ME|GSC-001',
  create_update_shopify_configuration_001: 'ME|CUSC-001',
  create_update_shopify_configuration_002: 'ME|CUSC-002',
  // Maps
  get_maps_001: 'ME|GM-001',
  create_map_001: 'ME|CM-001',
  create_map_002: 'ME|CM-002',
  create_map_003: 'ME|CM-003',
  create_map_004: 'ME|CM-004',
  delete_map_001: 'ME|DM-001',
  delete_map_002: 'ME|DM-002',
  update_map_001: 'ME|UM-001',
  update_map_002: 'ME|UM-002',
  add_map_to_connector_001: 'ME|AMTC-001',
  add_map_to_connector_002: 'ME|AMTC-002',
  delete_map_from_connector_001: 'ME|DMFC-001',
  delete_map_from_connector_002: 'ME|DMFC-002',
  add_map_fields_to_map_001: 'ME|AMFTM-001',
  add_map_fields_to_map_002: 'ME|AMFTM-002',
  // Queries
  get_shopify_queries_001: 'ME|GSQ-001',
  create_shopify_query_001: 'ME|CSQ-001',
  create_shopify_query_002: 'ME|CSQ-002',
  delete_shopify_query_001: 'ME|DSQ-001',
  delete_shopify_query_002: 'ME|DSQ-002',
  update_shopify_query_001: 'ME|USQ-001',
  update_shopify_query_002: 'ME|USQ-002',
  get_shopify_templates_001: 'ME|GST-001',
  create_update_shopify_template_001: 'ME|CUST-001',
  create_update_shopify_template_002: 'ME|CUST-002',
  delete_shopify_template_001: 'ME|DST-001',
  delete_shopify_template_002: 'ME|DST-002',
  remove_map_field_from_map_001: 'ME|RMFFM-001',
  remove_map_field_from_map_002: 'ME|RMFFM-002',
};

export const superAdminError = {
  get_map_fields_001: 'ME|GMF-001',
  create_map_field_001: 'ME|CMF-001',
  create_map_field_002: 'ME|CMF-002',
  create_map_field_003: 'ME|CMF-003',
  create_map_field_004: 'ME|CMF-004',
  create_map_field_005: 'ME|CMF-005',
  update_map_field_001: 'ME|UMF-001',
  update_map_field_002: 'ME|UMF-002',
  delete_map_field_001: 'ME|DMF-001',
  delete_map_field_002: 'ME|DMF-002',
  // Map fields temp0lates
  get_map_fields_templates_001: 'ME|GMFT-001',
  get_shopify_queries_001: 'ME|GSQ-001',
  create_map_fields_template_001: 'ME|CMFT-001',
  create_map_fields_template_002: 'ME|CMFT-002',
  delete_map_field_template_001: 'ME|DMFT-001',
  delete_map_field_template_002: 'ME|DMFT-002',
  // Queries
  create_shopify_query_001: 'ME|CSQ-001',
  create_shopify_query_002: 'ME|CSQ-002',
  delete_shopify_query_001: 'ME|DSQ-001',
  delete_shopify_query_002: 'ME|DSQ-002',
  update_shopify_query_001: 'ME|USQ-001',
  update_shopify_query_002: 'ME|USQ-002',
  get_shopify_templates_001: 'ME|GST-001',
  create_update_shopify_template_001: 'ME|CUST-001',
  create_update_shopify_template_002: 'ME|CUST-002',
  delete_shopify_template_001: 'ME|DST-001',
  delete_shopify_template_002: 'ME|DST-002',
};

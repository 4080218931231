import { IInitialMetaState } from 'interfaces/meta.interface';
import { handleLoadingStates } from './switchs/handleLoadingStates';
import { handleSftp } from './switchs/handleSftp';
import { handleShopify } from './switchs/handleShopify';
import { handleMapFields } from './switchs/handleMapFields';
import { handleMappings } from './switchs/handleMappings';

export default function metaReducer(state: IInitialMetaState, action: any) {
  state = handleLoadingStates(state, action);
  state = handleSftp(state, action);
  state = handleShopify(state, action);
  state = handleMappings(state, action);
  state = handleMapFields(state, action);

  return state;
}

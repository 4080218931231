export const SET_CLIENTS = 'SET_CLIENTS';
export const SET_IS_LOADING = 'SET_IS_LOADING';
export const SET_CONNECTORS = 'SET_CONNECTORS';
export const SET_IS_LOADING_CONNECTORS = 'SET_IS_LOADING_CONNECTORS';
// Map fields
export const GET_MAP_FIELDS = 'GET_MAP_FIELDS';
export const IS_LOADING_GET_MAP_FIELDS = 'IS_LOADING_GET_MAP_FIELDS';
export const CREATE_MAP_FIELD = 'CREATE_MAP_FIELD';
export const IS_LOADING_CREATE_MAP_FIELD = 'IS_LOADING_CREATE_MAP_FIELD';
export const UPDATE_MAP_FIELD = 'UPDATE_MAP_FIELD';
export const IS_LOADING_UPDATE_MAP_FIELD = 'IS_LOADING_UPDATE_MAP_FIELD';
export const DELETE_MAP_FIELD = 'DELETE_MAP_FIELD';
export const IS_LOADING_DELETE_MAP_FIELD = 'IS_LOADING_DELETE_MAP_FIELD';
// Map fields templates
export const GET_MAP_FIELDS_TEMPLATES = 'GET_MAP_FIELDS_TEMPLATES';
export const IS_LOADING_GET_MAP_FIELDS_TEMPLATES = 'IS_LOADING_GET_MAP_FIELDS_TEMPLATES';
export const CREATE_MAP_FIELDS_TEMPLATE = 'CREATE_MAP_FIELDS_TEMPLATE';
export const IS_LOADING_CREATE_MAP_FIELDS_TEMPLATE =
  'IS_LOADING_CREATE_MAP_FIELDS_TEMPLATE';
export const DELETE_MAP_FIELD_TEMPLATE = 'DELETE_MAP_FIELD_TEMPLATE';
export const IS_LOADING_DELETE_MAP_FIELD_TEMPLATE =
  'IS_LOADING_DELETE_MAP_FIELD_TEMPLATE';
// Shopify queries
export const SET_QUERIES = 'SET_QUERIES';
export const SET_IS_LOADING_QUERIES = 'SET_IS_LOADING_QUERIES';
export const SET_QUERY_SELECTED = 'SET_QUERY_SELECTED';
export const ADD_NEW_QUERY = 'ADD_NEW_QUERY';
export const SET_IS_LOADING_CREATE_QUERY = 'SET_IS_LOADING_CREATE_QUERY';
export const DELETE_QUERY = 'DELETE_QUERY';
export const SET_IS_LOADING_DELETE_QUERY = 'SET_IS_LOADING_DELETE_QUERY';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const SET_IS_LOADING_UPDATE_QUERY = 'SET_IS_LOADING_UPDATE_QUERY';
// Shopify templates
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const SET_IS_LOADING_TEMPLATES = 'SET_IS_LOADING_TEMPLATES';
export const SET_TEMPLATE_SELECTED = 'SET_TEMPLATE_SELECTED';
export const CREATE_UPDATE_TEMPLATE = 'CREATE_UPDATE_TEMPLATE';
export const SET_IS_LOADING_CREATE_UPDATE_TEMPLATE =
  'SET_IS_LOADING_CREATE_UPDATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const SET_IS_LOADING_DELETE_TEMPLATE = 'SET_IS_LOADING_DELETE_TEMPLATE';

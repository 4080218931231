// Home
export const home = '/';

// Dashboard
export const dashboardSale = '/dashboard';
export const dashboardStock = '/dashboard-stock';
export const dashboardPrediction = '/dashboard-prediction';

// Catalog
export const catalog = '/catalog';
export const catalogProduct = `${catalog}/product`;
export const catalogUploads = `/catalog-uploads`;
export const catalogUploadsByUpload = `${catalogUploads}/by-upload`;
export const catalogUploadsBySeason = `${catalogUploads}/by-season`;
export const catalogAddCatalog = `${catalogUploads}/add-catalog`;
export const catalogAddCatalogPreview = `${catalogAddCatalog}/preview`;
export const catalogAddCatalogImagesPreview = `${catalogAddCatalog}/upload-images`;
export const catalogCorrelation = `${catalog}/correlation`;
export const catalogGeneric = `${catalog}/generic`;
export const catalogDownload = `${catalog}/download`;

// Multimedia
export const multimedia = '/multimedia';
export const multimediaImages = `${multimedia}/images`;
export const multimediaVideos = `${multimedia}/videos`;
export const multimediaBanners = `${multimedia}/banners`;

// Clients
export const clients = '/clients';
export const addClient = '/add-client';

// Client
export const client = '/client';
export const resume = 'resume';
export const businessInfo = 'business-info';
export const salePoints = 'sale-points';
export const mainContacts = 'main-contacts';
export const commercialInfo = 'commercial-info';
export const clientResume = `${client}/resume`;
export const clientBusinessInfo = `${client}/business-info`;
export const clientMainContacts = `${client}/main-contacts`;
export const clientSalePoints = `${client}/sale-points`;
export const clientCommercialInfo = `${client}/commercial-info`;

// Account
export const account = '/account';
export const profile = 'profile';
export const company = 'company';
export const contacts = 'contacts';
export const commercial = 'commercial';
export const accountProfile = `${account}/${profile}`;
export const accountCompany = `${account}/${company}`;
export const accountContacts = `${account}/${contacts}`;
export const accountCommercial = `${account}/${commercial}`;

// Config
export const settings = '/settings';
const users = 'users';
const chronos = 'chronos';
const devices = 'devices';
const touchScreen = 'touch-screen';
const system = 'system';
const meta = 'meta';
const commercialProfile = 'commercial-profile';
const billing = 'billing';
const support = 'support';
export const settingsUsers = `${settings}/${users}`;
export const settingsChronos = `${settings}/${chronos}`;
export const settingsDevices = `${settings}/${devices}`;
export const settingsTouchScreen = `${settings}/${touchScreen}`;
export const settingsSystemSettings = `${settings}/${system}`;
export const settingsMeta = `${settings}/${meta}`;
export const settingsMetaEditAPI = `${settingsMeta}/edit-api-connection`;
export const settingsMetaGeneralConfigurationAPI = `${settingsMeta}/general-api-configuration`;
export const settingsMetaMapFieldsConfigurationAPI = `${settingsMeta}/map-fields-configuration`;
export const settingsMetaMaps = `${settingsMeta}/maps`;
export const settingMetaEditMap = `${settingsMeta}/edit-map`;
export const settingsCommercialProfile = `${settings}/${commercialProfile}`;
export const settingsBilling = `${settings}/${billing}`;
export const settingsSupport = `${settings}/${support}`;

// Authentication
export const authentication = '/authentication';
export const authenticationSignup = `${authentication}/signup`;

// Admin
export const adminHome = '/admin/home';
export const adminAPIConfiguration = '/admin/api-configuration';
export const adminMap = '/admin/map';

// Notifications
export const notifications = '/notifications';

// Wholesale Feed
export const wholesaleFeed = '/wholesale-feed';

import * as MetaTypes from '../../meta.types';
import { IInitialMetaState, IMap } from 'interfaces/meta.interface';

export function handleMappings(state: IInitialMetaState, action: any): IInitialMetaState {
  const { type, payload } = action;

  switch (type) {
    case MetaTypes.GET_MAPS: {
      const getMaps: IInitialMetaState = {
        ...state,
        mappings: payload,
      };
      return getMaps;
    }

    case MetaTypes.DELETE_MAP: {
      const deleteMap: IInitialMetaState = {
        ...state,
        mappings: state.mappings.filter((map) => map.id !== payload),
      };
      return deleteMap;
    }

    case MetaTypes.SET_IS_LOADING_DELETE_MAP: {
      const setIsLoadingDeleteMap: IInitialMetaState = {
        ...state,
        isLoadingDeleteMap: {
          loading: payload.loading,
          mapId: payload.mapId,
        },
      };
      return setIsLoadingDeleteMap;
    }

    case MetaTypes.SET_MAP_SELECTED: {
      const setMapSelected: IInitialMetaState = {
        ...state,
        mapSelected: !payload
          ? null
          : (state.mappings.find((map) => map.id === payload) as IMap),
      };
      return setMapSelected;
    }

    case MetaTypes.CREATE_MAP: {
      const createMap: IInitialMetaState = {
        ...state,
        mappings: [payload, ...state.mappings],
      };
      return createMap;
    }

    case MetaTypes.UPDATE_MAP: {
      const updateMap: IInitialMetaState = {
        ...state,
        mappings: state.mappings.map((item) =>
          item.id === payload.id ? { ...item, ...payload.data } : item
        ),
      };
      return updateMap;
    }

    default:
      return state;
  }
}
